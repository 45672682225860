import React, { useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as IconDoc } from 'i/icons/document_2.svg';
import { JsonDataContext } from 'context/jsonData';

const ThemeLinks = ({
	themes,
	offsetMod,
	currentThemeSlug,
}) => {
	const { langApp } = useContext(JsonDataContext);
	const essayClasses = classNames('essay_links', {
		offset_mod: offsetMod,
	});

	return (
		<ul className={essayClasses}>
			{themes.length && themes.map(({
				title,
				slug,
			}) => {
				const activeThemeClass = currentThemeSlug && currentThemeSlug === slug ? 'active_mod' : '';
				return (
					<li className="essay_links_item">
						<Link
							className={`essay_link ${activeThemeClass}`}
							to={`/themes/${slug}`}
						>
							<div className="essay_link_icon">
								<IconDoc className="icon icon-document_2 size_mod" />
							</div>
							<div className="essay_link_content">
								{title && (
									<h3 className="essay_link_title">{title[langApp]}</h3>
								)}
							</div>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default ThemeLinks;
