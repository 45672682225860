import React, { useContext } from 'react';
import classNames from 'classnames';
import APP_DATA from 'utils/jsonAppData';
import { JsonDataContext } from 'context/jsonData';

const FilterResultMessage = ({
	v1Mod,
}) => {
	const {
		langApp,
	} = useContext(JsonDataContext);

	const resultMessage = classNames('filter_result_message', {
		v1_mod: v1Mod,
	});

	return (
		<div className={resultMessage}>{APP_DATA.searchNoResultTitle[langApp]}</div>
	);
};

export default FilterResultMessage;
