import { api } from 'utils/api';

const getMainMenuItems = async () => {
	const menuItems = await api.get('/nav/main-menu');

	return menuItems;
};

const getFooterMenuItems = async () => {
	const menuItems = await api.get('/nav/footer-menu');

	return menuItems;
};

const getAllPages = async () => {
	const allPages = await api.get('/wp/v2/pages?per_page=100');

	return allPages;
};

const getAppBaseData = async () => {
	const appBaseData = await api.get('/acf/v3/options/options');

	return appBaseData;
};

const getAllEssay = async () => {
	let masterResult = [];

	const res = await api.get('/wp/v2/essay?page=1&per_page=100');

	if (res && res.headers && res.headers['x-wp-totalpages']) {
		masterResult = [
			...masterResult,
			...res.data,
		];

		let pagesLeft = parseInt(res.headers['x-wp-totalpages'], 10) - 1;
		/* eslint-disable no-await-in-loop */
		while (pagesLeft > 0) {
			const tempRes = await api.get(`/wp/v2/essay?page=${(pagesLeft + 1)}&per_page=100`);
			masterResult = [
				...masterResult,
				...tempRes.data,
			];
			pagesLeft -= 1;
		}
		/* eslint-enable no-await-in-loop */
	}
	return {
		data: masterResult,
	};
};

const getSingleEssay = async slug => {
	const res = await api.get(`/wp/v2/essay?slug=${slug}`);

	return res;
};

const getTimelinePageData = async () => {
	const res = await api.get('/wp/v2/timeline');

	return res;
};

const getFilters = async () => {
	const res = await api.get('/wp/v2/filters');

	return res;
};

const getMapData = async () => {
	const res = await api.get('/wp/v2/map/');

	return res;
};

const getSingleThemeBySlug = async slug => api.get(`/wp/v2/shp_themes?slug=${slug}`);

export {
	getMainMenuItems,
	getFooterMenuItems,
	getAllPages,
	getAppBaseData,
	getTimelinePageData,
	getAllEssay,
	getSingleEssay,
	getFilters,
	getMapData,
	getSingleThemeBySlug,
};
