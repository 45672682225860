import React, { useContext } from 'react';
import Slider from 'react-slick';

import { JsonDataContext } from 'context/jsonData';

const CreditThanks = ({ data }) => {
	const { langApp } = useContext(JsonDataContext);

	return (
		<div className="credit_thanks_item" key={data.id}>
			<div className="credit_thanks_item_in">
				<div className="credit_thanks_head">
					<h3 className="credit_thanks_head_title">{data.title[langApp]}</h3>
				</div>
				{data.display === 'list' && (
					<ul className="credit_thanks_inner_list">
						{data.list && data.list.map((innerItem, index) => {
							return (
								<li className="credit_thanks_inner_item" key={index}>{innerItem}</li>
							);
						})}
					</ul>
				)}
				{data.display === 'block' && (
					<ul className="credit_thanks_inner_list">
						<li className="credit_thanks_inner_item">{data.list && data.list.join(', ')}</li>
					</ul>
				)}
			</div>
		</div>
	);
};

export default CreditThanks;
