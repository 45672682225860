import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import dateFormat from 'dateformat';
import APP_DATA from 'utils/jsonAppData';
import { AddToCalendarButton } from 'components/Buttons';

const Event = ({
	id,
	date,
	title,
	image,
	descr,
	schedule,
	registerInfo,
	registerBtnTitle,
	registerBtnLink,
	calendarTitle,
	calendarDescription,
	calendarLocation,
	calendarDate,
	lang,
	slug,
	isLastItem,
}) => {
	dateFormat.i18n = APP_DATA.datei18n[lang];
	return (
		<div className="event">
			<div className="section_row">
				<div className="section_col col_3_mod">
					<div className="event_date">
						{dateFormat(date?.day, APP_DATA.dateMask[lang], true)}
					</div>
				</div>
				<div className="section_col col_4_mod">
					<div className={`event_content ${isLastItem ? 'no-border' : ''}`}>
						<div className="event_main">
							<Link className="event_title" to={`/event/${slug}`}>
								{ReactHtmlParser(title)}
							</Link>
							{descr && (
								<div className="event_descr">
									{ReactHtmlParser(descr)}
								</div>
							)}
							<ul className="event_details">
								{schedule
								&& schedule.map((item, index) => {
									return (
										<li className="event_details_item" key={index}>
											{item.short_schedule_item[lang]}
										</li>
									);
								})}
							</ul>
							<div className="event_bottom">
								{ReactHtmlParser(registerInfo)}
								{calendarLocation}
							</div>
						</div>
						<div className="event_sidebar">
							<div className="event_img_wrap">
								<img className="event_img" src={image} alt="" />
							</div>
							<div className="event_button_list">
								{/* <div className="event_button_item">
									<AddToCalendarButton
										eventTitle={title}
										buttonTitle={calendarTitle}
										calendarLocation={calendarLocation}
										calendarDescription={calendarDescription}
										calendarDate={calendarDate}
									/>
								</div> */}
								{registerBtnLink && (
									<div className="event_button_item">
										<a
											className="btn_v5 full_mod"
											target="_blank"
											rel="noreferrer"
											href={registerBtnLink}
										>
											{registerBtnTitle}
										</a>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Event;
