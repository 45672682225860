/* eslint-disable */
import React, { useRef, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getTimelinePageData } from 'api/data';
import { Filters } from 'components/Filters';
import { sortByYear, getPageBySlug } from 'utils';
import { Spinner } from 'components/Spinner';
import { FilterResultMessage } from 'components/Filters';
import {
	TimelineHistoricalItem,
	TimelineEssayItem,
	TimelineObjectItem,
} from 'components/TimelineItems';

import { JsonDataContext } from 'context/jsonData';
import { UiContext } from 'context/ui';
import { AnimationContext } from 'context/animation';

gsap.registerPlugin(ScrollTrigger);

const TimelinePage = () => {
	const {
		langApp,
		showPopupByKey,
		setObjectPopupData,
		pagesData,
		epochsPageData,
		setEpochsPageData,
	} = useContext(JsonDataContext);

	const {
		windowWidth,
		windowHeight,
		footerEl,
		footerHeight,
		setFooterHeight,
		footerTop,
		setFooterTop,
		scrollCheckActive,
		setScrollCheckActive,
		pageScrollTop,
	} = useContext(AnimationContext);

	const {
		openPopup,
	} = useContext(UiContext);

	const [allTimelineData, setTimelineData] = useState(false);
	const [filteredTimelineData, setFilteredTimelineData] = useState(false);
	const [isTimelineLoaded, setTimelineLoaded] = useState(false);
	const [isFetchError, setFetchError] = useState(false);

	const [localScrollOld, setLocalScrollOld] = useState(false);

	const scroller = useRef(null);
	const trigger = useRef(null);
	const timeline = useRef(null);
	const filterEl = useRef(null);

	const routeParams = useParams();
	const [targetHash, setTargetHash] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (routeParams && routeParams.targetId && routeParams.targetId !== '' && isTimelineLoaded) {
			setTargetHash(routeParams.targetId);
			history.replace('/timeline');
		}
	}, [
		routeParams,
		isTimelineLoaded,
	]);

	useEffect(() => {
		if (targetHash && targetHash !== '') {
			const targetObject = document.getElementById(`timeline-item-${targetHash}`);
			if (targetObject) {
				const distance = targetObject.offsetLeft;
				window.scrollBy(0, distance);
				targetObject.click();
			}
		}
	}, [
		targetHash,
	]);

	const getPageData = () => {
		const currentData = getPageBySlug(pagesData, 'epochs');
		const preparedCurrentPageData = currentData[0].page_fields;

		setEpochsPageData(preparedCurrentPageData);
	};

	useEffect(() => {
		if (pagesData && !epochsPageData) {
			getPageData();
		}
	}, [pagesData]);

	const sortTimelineByYear = data => {
		const sortedTimelineData = sortByYear(data);

		setFilteredTimelineData(sortedTimelineData);
		setTimelineData(sortedTimelineData);
	};

	const fetchTimelineData = async () => {
		try {
			const res = await getTimelinePageData();

			sortTimelineByYear(res.data);
			setTimelineLoaded(true);
		} catch (err) {
			setTimelineLoaded(false);
			setFetchError(true);
		}
	};

	const handleOpenPopup = (id, slug, type, status) => {
		setObjectPopupData({
			id,
			slug,
			type,
			status,
		});
		showPopupByKey('object', true);
	};

	useEffect(() => {
		fetchTimelineData();
		openPopup('timelineWelcome');
	}, []);

	useEffect(() => {
		if (isTimelineLoaded && footerEl.current) {
			setScrollCheckActive(true);
			const $width = timeline.current.offsetWidth;
			const $footer_track = footerHeight + footerTop - windowHeight;

			gsap.set(trigger.current, { height: $width + $footer_track });

			// const testfooter = document.querySelectorAll('.footer');
			// const $scrollHeight = document.body.scrollHeight;
			// let scroll_old = false;

			let track = (pageScrollTop - $width + windowHeight) * -1;
			if ($width - windowHeight < pageScrollTop) {
				if (track > -$footer_track && filteredTimelineData.length) {
					gsap.set(timeline.current, { y: track });
					gsap.set(footerEl.current, { y: track });
					gsap.set(filterEl.current, { y: track });
				} else {

				}
			} else {
				gsap.set(timeline.current, { x: - pageScrollTop, y: 0 })
				gsap.set(footerEl.current, { y: 0 })
				gsap.set(filterEl.current, { y: 0 });
			}

			setFooterHeight(footerEl.current.offsetHeight);
			setFooterTop(footerEl.current.offsetTop);
		}
	}, [isTimelineLoaded, scrollCheckActive, pageScrollTop, windowWidth, filteredTimelineData]);

	return (
		<section className="section hero_v1_mod no_bottom_offset_mod timeline_mod">
			{/* <div className="section_in timeline_filter_mod" ref={filterEl}>
				<Filters
					allObjects={allTimelineData}
					allFilteredObjects={filteredTimelineData}
					setAllFilteredObjects={setFilteredTimelineData}
				/>
			</div> */}
			{isTimelineLoaded ? (
				<div className="section_in timeline_mod">
					<div className="spacer_block" ref={trigger} />
					<div className="timeline_list_wrap" ref={timeline}>
						<div className="timeline_list">
							{/*{epochsPageData && epochsPageData.epoch_excerpts[0] ? (*/}
							{/*	<TimelineEssayItem*/}
							{/*		type="essay"*/}
							{/*		title={epochsPageData.epoch_excerpts[0].title[langApp]}*/}
							{/*		text={epochsPageData.epoch_excerpts[0].body[langApp]}*/}
							{/*		lgMod*/}
							{/*	/>*/}
							{/*) : null}*/}
							{filteredTimelineData.length ? (
								filteredTimelineData.map(({
									id,
									type,
									year,
									year_text: yearText,
									title,
									description,
									image,
									status,
									objectNumber,
									publishDate,
									slug,
								}) => {
									const preparedTypeValue = type.toLowerCase();
									const yearTextSafe = {
										en: 'not filled',
										de: 'not filled',
									};

									const preparedYearValue = !yearText ? yearTextSafe[langApp] : yearText[langApp];

									if (year && preparedTypeValue === 'historical' && image) {
										return (
											<TimelineHistoricalItem
												id={id}
												type="historical"
												title={preparedYearValue}
												img={image}
												text={description[langApp]}
												onClick={() => handleOpenPopup(id, slug, preparedTypeValue, status)}
											/>
										);
									}

									if (year && preparedTypeValue === 'object' && image) {
										return (
											<TimelineObjectItem
												id={id}
												type="object"
												title={preparedYearValue}
												img={image}
												text={description[langApp]}
												status={status}
												objectNumber={objectNumber[langApp]}
												onClick={() => handleOpenPopup(id, slug, preparedTypeValue, status)}
												name={title[langApp]}
												date={publishDate}
											/>
										);
									}

									if (year && preparedTypeValue !== 'object' && preparedTypeValue !== 'historical') {
										return (
											<TimelineEssayItem
												id={id}
												type="essay"
												title={title[langApp]}
												text={description[langApp]}
												slug={slug}
											/>
										);
									}
									return null;
								})
							) : (
									<FilterResultMessage v1Mod />
								)}
						</div>
					</div>
				</div>
			) : (
					!isFetchError && (
						<Spinner />
					)
				)}
		</section>
	);
};

export default TimelinePage;
/* eslint-disable */
