import React, { useEffect, useState } from 'react';

import { getAllObjects } from 'api/object';
import { PreviewList } from './PreviewList';

const ThemeRelatedObjects = ({
	mappedObjectIds,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [mappedObjects, setMappedObjects] = useState([]);

	const fetchAllObjects = async () => {
		try {
			const res = await getAllObjects();
			if (res && res.data && res.data.length) {
				setMappedObjects(res.data.filter(object => {
					if (mappedObjectIds.indexOf(object.ID) >= 0) {
						return true;
					}
					return false;
				}));
				setIsLoading(false);
			} else {
				alert('Refresh the page and try again.');
			}
		} catch (err) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAllObjects();
	}, []);

	console.log(mappedObjects);

	if (isLoading) return null;

	return (
		<PreviewList
			listItems={mappedObjects}
		/>
	);
};
export default ThemeRelatedObjects;
