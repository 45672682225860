import React, { useEffect, useContext } from 'react';
import { JsonDataContext } from 'context/jsonData';
import { setItemToLocalStorage } from 'utils';

const SetLangAndReset = ({ match: { params: { lang } } }) => {
	const { setLangApp } = useContext(JsonDataContext);

	useEffect(() => {
		if (lang === 'en' || lang === 'de') {
			setItemToLocalStorage('user-language', lang);
			setLangApp(lang);
			window.location.href = '/';
		}
	}, [
		lang,
	]);

	return <div />;
};

export default SetLangAndReset;
