import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import APP_DATA from 'utils/jsonAppData';
import { ReactComponent as IconArrow } from 'i/icons/arrow_right.svg';
import FooterMenu from './FooterMenu';

const Footer = ({
	isTimelinePage,
}) => {
	const { appBaseData, langApp } = useContext(JsonDataContext);
	const { footerEl } = useContext(AnimationContext);
	const [footerData, setFooterData] = useState(null);

	useEffect(() => {
		if (appBaseData) {
			setFooterData(appBaseData);
		}
	}, [appBaseData]);

	return (
		footerData && (
			<footer className={`footer ${isTimelinePage ? 'timeline_mod' : ''}`} ref={footerEl}>
				<div className="section_in">
					<div className="footer_row">
						<div className="footer_col logo_mod">
							<div className="footer_logo">
								<img className="section_img contain_mod" src={footerData.footer_logo.url} alt={footerData.footer_logo.alt} />
							</div>
						</div>
						<div className="footer_col info_mod">
							<div className="footer_top">
								<FooterMenu />
							</div>
							<div className="footer_sponsors_wrap">
								{/* {footerData.sponsors && footerData.sponsors.map(({
									image,
								}) => {
									return (
										<div className="footer_sponsor_img_w" key={image.id}>
											<div className="footer_sponsor_img">
												<img className="section_img contain_mod" src={image.url} alt={image.alt} />
											</div>
										</div>
									);
								})} */}
								<div className="footer_sponsor_img_w">
									<div className="footer_sponsor_img">
										<a href="https://www.lbi.org/" target="_blank" rel="noreferrer">
											<img className="section_img contain_mod" src={`${window.location.origin}/images/leo.png`} alt="Leo" />
										</a>
									</div>
								</div>
								<div className="footer_sponsor_img_w">
									<div className="footer_sponsor_img">
										<a href="https://www.1700jahre.de/" target="_blank" rel="noreferrer">
											<img className="section_img contain_mod" src={`${window.location.origin}/images/bbp.png`} alt="BBP" />
										</a>
									</div>
								</div>
								<div className="footer_sponsor_img_w">
									<div className="footer_sponsor_img">
										<a href="https://www.bmi.bund.de/DE/startseite/startseite-node.html" target="_blank" rel="noreferrer">
											<img className="section_img contain_mod" src={`${window.location.origin}/images/ff0.png`} alt="FFO" />
										</a>
									</div>
								</div>
							</div>
							<div className="footer_bottom_text">
								<Link className="popup_privacy" to="/privacy-policy">
									{APP_DATA.signupPopup.privacyLink[langApp]}
									<IconArrow className="icon icon-arrow_right link_mod" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)

	);
};

export default Footer;
