export const THEME_ARRAY = {
	allThemesTitle: {
		en: 'Themes',
		de: 'Themen',
	},
	previousButtonLabel: {
		en: 'Previous',
		de: 'zurück',
	},
	nextButtonLabel: {
		en: 'Next',
		de: 'weiter',
	},
	essayKeyword: {
		en: 'Essay',
		de: 'Aufsatz',
	},
	relatedObjectsLine: {
		en: 'Related objects',
		de: 'Verwandte Objekte',
	},
	urlSlugMapping: [
		['migration', 'one', 'ein'],
		['daily-life', 'two', 'zwei'],
		['inclusion', 'three', 'drei'],
		['exclusion', 'four', 'vier'],
		['acculturation', 'five', 'funf'],
		['achievement', 'six', 'sechs'],
		['persecution', 'seven', 'sieben'],
		['exile', 'eight', 'acht'],
		['resilience', 'nine', 'neun'],
	],
};
