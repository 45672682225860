import React from 'react';

import NavBlockItem from './NavBlockItem';

const ThemeNavBlock = ({
	themes,
	currentThemeSlug,
	buttonLabels,
}) => {
	const themeSlugs = themes.map(theme => theme.slug);
	const currentThemeIndex = themeSlugs.indexOf(currentThemeSlug);

	return (
		<section className="section no_top_offset_mod">
			<div className="section_in">
				<div className="nav_block">
					<div className="nav_block_row theme-page">
						{currentThemeIndex > 0 && (
							<NavBlockItem
								title={buttonLabels.prev}
								url={`/themes/${themeSlugs[currentThemeIndex - 1]}`}
								theme_page
							/>
						)}
						{currentThemeIndex < (themeSlugs.length - 1) && (
							<NavBlockItem
								title={buttonLabels.next}
								url={`/themes/${themeSlugs[currentThemeIndex + 1]}`}
								nextArrow
								theme_page
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ThemeNavBlock;
