import React, { useState, useEffect, useContext } from 'react';
import { getRandomChar } from 'utils';
import { Link } from 'react-router-dom';
import { getFooterMenuItems } from 'api/data';
import { JsonDataContext } from '../../context/jsonData';
import { FOOTER_ARRAY } from './constant';

const FooterMenu = () => {
	const { langApp, showPopupByKey } = useContext(JsonDataContext);
	const [menuItems, setMenuItems] = useState(null);

	const fetchAllMenuItems = async () => {
		try {
			const res = await getFooterMenuItems();

			setMenuItems(res.data);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setMenuItems(FOOTER_ARRAY.footerNavCols);
	}, []);

	const handleOnClick = e => {
		e.preventDefault();

		window.location.href = '/virtual-exhibit';
	};

	return (
		menuItems && (
			<nav className="footer_menu">
				{menuItems.map((col, index) => {
					return (
						<ul className="footer_menu_list" key={`title-${index}`}>
							{col.links.map(({
								// ID,
								// item_title: title,
								// item_url: url,
								// popup,
								url,
								title,
								popUp,
								external,
							}) => {
								return (
									<li className="footer_menu_item" key={getRandomChar(5)}>
										{popUp ? (
											<a className="footer_menu_item_link" href="#" onClick={handleOnClick}>{title[langApp]}</a>
										) : (
											(external ? <a className="footer_menu_item_link" href={url[langApp]} target="_blank" rel="noreferrer">{title[langApp]}</a> : <Link className="footer_menu_item_link" to={url[langApp]}>{title[langApp]}</Link>)
										)}
									</li>
								);
							})}
						</ul>
					);
				})}
			</nav>
		)

	);
};

export default FooterMenu;
