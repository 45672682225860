import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import dateFormat from 'dateformat';

import { JsonDataContext } from 'context/jsonData';
import APP_DATA from 'utils/jsonAppData';
import { AuthContext } from 'context/auth';
import { UiContext } from 'context/ui';
import PlaceholderImage from 'i/placeholder-image.png';
import MopedInfoItem from './MopedInfoItem';

const PreviewItem = ({
	title,
	objectImage,
	date,
	id,
	status,
	infoList,
	starred,
	firstEssaySlug,
	slug,
	itemIndex,
	short_description,
	themeDescription,
}) => {
	const { langApp } = useContext(JsonDataContext);

	const { loggedIn } = useContext(AuthContext);
	const { openPopup } = useContext(UiContext);
	const { pathname } = useLocation();
	const [isStarred, setIsStarred] = useState(starred);

	const getObjectUrl = () => {
		if (status === 'future') {
			return `/future-object/${slug}`;
		}

		return `/object/${slug}`;
	};

	return (
		<>
			<div className="preview_item_row theme">
				{
					itemIndex % 2 === 0 ? (
						<>
							<div className="preview_item_left_col padding-mod">
								<div className="preview_img_w theme-page">
									{objectImage && objectImage.url ? (
										<img className="preview_img" src={objectImage.url} alt={objectImage.alt} />
									) : (
										<img className="preview_img" src={PlaceholderImage} alt="not found" />
									)}
								</div>
								{infoList ? (
									<div className="moped_info_table theme-page">
										{infoList.map(({
											type,
											value,
										}, index) => {
											if (type === 'mappedThemes' || type === 'essays') {
												return null;
											}
											return (
												<MopedInfoItem
													objectId={id}
													key={index}
													type={type}
													value={value}
													essaysLink={`/essay/${firstEssaySlug}`}
												/>
											);
										})}
									</div>
								) : (
									<div className="preview_item_text">
										{`${APP_DATA.objectsListPage.toBePublishedTitle[langApp]} ${dateFormat(date, 'mediumDate', true)}`}
									</div>
								)}
							</div>
							<div className="preview_item_right_col">
								<h3 className="preview_item_title no-border theme-page">{ReactHtmlParser(title)}</h3>
								{themeDescription && <>{ReactHtmlParser(themeDescription[langApp])}</>}
								<div className="padding-top-1rem">
									<Link className="btn_v4" to={getObjectUrl()}>{APP_DATA.weekObjectSection.buttonTitle[langApp]}</Link>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="preview_item_left_col">
								<h3 className="preview_item_title no-border theme-page">{ReactHtmlParser(title)}</h3>
								{themeDescription && <>{ReactHtmlParser(themeDescription[langApp])}</>}
								<div className="padding-top-1rem">
									<Link className="btn_v4" to={getObjectUrl()}>{APP_DATA.weekObjectSection.buttonTitle[langApp]}</Link>
								</div>
							</div>
							<div className="preview_item_right_col padding-mod">
								<div className="preview_img_w theme-page">
									{objectImage && objectImage.url ? (
										<img className="preview_img" src={objectImage.url} alt={objectImage.alt} />
									) : (
										<img className="preview_img" src={PlaceholderImage} alt="not found" />
									)}
								</div>
								{infoList ? (
									<div className="moped_info_table theme-page">
										{infoList.map(({
											type,
											value,
										}, index) => {
											if (type === 'mappedThemes' || type === 'essays') {
												return null;
											}
											return (
												<MopedInfoItem
													objectId={id}
													key={index}
													type={type}
													value={value}
													essaysLink={`/essay/${firstEssaySlug}`}
												/>
											);
										})}
									</div>
								) : (
									<div className="preview_item_text">
										{`${APP_DATA.objectsListPage.toBePublishedTitle[langApp]} ${dateFormat(date, 'mediumDate', true)}`}
									</div>
								)}
							</div>
						</>
					)
				}
			</div>
		</>
	);
};

export default PreviewItem;
