import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'context/auth';

const ProtectedRoute = ({
	component,
	path,
	isAdmin = false,
}) => {
	const { loggedIn, currentUser } = useContext(AuthContext);

	if (!loggedIn) {
		return (
			<Redirect to="/" />
		);
	} else {
		if (isAdmin && !currentUser.isAdmin) {
			return (
				<Redirect to="/" />
			);
		}
	}

	return (
		<Route
			exact
			path={path}
			component={component}
		/>
	);
};

export default ProtectedRoute;
