import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import APP_DATA from 'utils/jsonAppData';
import { JsonDataContext } from 'context/jsonData';
import dateFormat from 'dateformat';

import PlaceholderImage from 'i/placeholder-image.png';

const TimelineObjectItem = ({
	id,
	title,
	img,
	text,
	type,
	onClick,
	status,
	objectNumber,
	name,
	date,
}) => {
	const { langApp } = useContext(JsonDataContext);
	return (
		<button id={`timeline-item-${id}`} className="timeline_item object_mod" onClick={onClick} type="button">
			<div className="timeline_item_title">{title}</div>
			<div className="timeline_item_img_block">
				<div className="timeline_item_img_wrap">
					{img ? (
						<img className="section_img contain_mod" src={img} alt={title} />
					) : (
						<img className="section_img contain_mod" src={PlaceholderImage} alt="not found" />
					)}
				</div>
			</div>
			<div className="timeline_item_text">
				<>
					{objectNumber}
					{
						status === 'publish' && <>{ReactHtmlParser(text)}</>
					}
					{
						status === 'future' && (
							<>
								<div>
									{`${name}`}
								</div>
								<div>
									{`${APP_DATA.objectsListPage.toBePublishedTitle[langApp]} ${dateFormat(date, 'mediumDate', true)}`}
								</div>
							</>
						)
					}
				</>
			</div>

		</button>
	);
};

export default TimelineObjectItem;
