import React, { useContext, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { Pannellum, PannellumVideo } from 'pannellum-react';

import { SliderControls } from 'components/SliderControls';
import ReactHtmlParser from 'react-html-parser';
import { JsonDataContext } from 'context/jsonData';

import { ReactComponent as InfoIcon } from 'i/icons/info.svg';
import { ReactComponent as DocumentIcon } from 'i/icons/document_3.svg';
import { ReactComponent as ResizeIcon } from 'i/icons/resize.svg';

const DashboardSlider = ({
	slidesData,
	activeTab,
	setActiveTab,
}) => {
	const { langApp } = useContext(JsonDataContext);
	const { pathname } = useLocation();
	const [iconInfoShown, setIconInfoShown] = useState(false);
	const {
		link_3d,
		main_3d_caption,
		second_3d_caption,
		photos,
		dimensions,
		videos,
	} = slidesData;

	const objectSizeRullerEl = useRef(null);
	const objectSizeRullerBtn = useRef(null);
	const objectResizeBtn = useRef(null);
	const object3dViewEl = useRef(null);
	const sliderEl = useRef(null);
	const dashboardSliderWrapEl = useRef(null);

	const dashboardSliderSettings = {
		prevArrow: <SliderControls prevMod dashboardMod />,
		nextArrow: <SliderControls nextMod dashboardMod nextArrow />,
		infinite: true,
		slidesToShow: 1,
		fade: true,
		slidesToScroll: 1,
		dots: true,
		dotsClass: 'slick-dots dashboard_mod',
		arrows: true,
		touchMove: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					adaptiveHeight: true,
				},
			},
		],
		beforeChange: (current, next) => {
			// console.log(current, next);
			if (next === 0 && link_3d.length) {
				object3dViewEl.current.classList.remove('hidden_mod');
				objectSizeRullerBtn.current.classList.remove('hidden_mod');
				objectResizeBtn.current.classList.remove('hidden_mod');
				// console.log('remove class');
			} else {
				if (next > 0 && link_3d.length) {
					object3dViewEl.current.classList.add('hidden_mod');
					objectSizeRullerBtn.current.classList.add('hidden_mod');
					objectResizeBtn.current.classList.add('hidden_mod');

					if (objectSizeRullerEl.current.classList.contains('visible_mod')) {
						objectSizeRullerEl.current.classList.remove('visible_mod');
					}
					// console.log('remove class');
				}
			}
		},
	};

	// object sizes
	let objectSizesArray;
	function splitString(stringToSplit, stringSeparator) {
		objectSizesArray = stringToSplit.split(stringSeparator);
	}
	const stringSeparator = ' x ';
	splitString(dimensions[langApp], stringSeparator);

	function visibleSizeRuller(event) {
		objectSizeRullerEl.current.classList.toggle('visible_mod');
		event.currentTarget.classList.toggle('active_size_mod');

		if (activeTab !== 'description') {
			setActiveTab('description');
		}
		return null;
	}

	// 3d model fullscreen
	function openFullscreen() {
		document.body.classList.toggle('fullscreen_mod');
		dashboardSliderWrapEl.current.classList.toggle('fullscreen_mod');
		return null;
	}

	return (
		<>
			<div className="dashboard_slider_w" ref={dashboardSliderWrapEl}>
				{/* <PannellumVideo
					video="https://player.vimeo.com/external/505709501.hd.mp4?s=0ff9193a68f5fbd3e1d97f6081eb0edb5aca1942&profile_id=175"
					loop
					width="100%"
					height="600px"
					pitch={10}
					yaw={180}
					hfov={140}
					minHfov={50}
					maxHfov={180}
				/> */}
				<div className="dashboard_slider_control_w">
					<div className="object_size_ruller" ref={objectSizeRullerEl}>
						{objectSizesArray && (
							<>
								{objectSizesArray[0] && (
									<div className="object_size_ruller_col v1_mod">
										<div className="object_size_ruller_title">{objectSizesArray[0]}</div>
									</div>
								)}
								{objectSizesArray[2] && (
									<div className="object_size_ruller_col v2_mod">
										<div className="object_size_ruller_title">{objectSizesArray[2]}</div>
									</div>
								)}
								{objectSizesArray[1] && (
									<div className="object_size_ruller_col v3_mod">
										<div className="object_size_ruller_title">{objectSizesArray[1]}</div>
									</div>
								)}
							</>
						)}
					</div>
					{link_3d && (
						<>
							<button className="dashboard_slider_icon info_mod display-none-mode" type="button" ref={object3dViewEl} onClick={() => setIconInfoShown(!iconInfoShown)}>
								<span className={iconInfoShown ? 'dashboard_slider_icon_title active_mod' : 'dashboard_slider_icon_title'}>Click & Drag to rotate the object</span>
								<InfoIcon className="icon icon-info size_mod" />
							</button>
							<button className="dashboard_slider_icon doc_mod display-none-mode" type="button" onClick={visibleSizeRuller} ref={objectSizeRullerBtn}>
								<span className="dashboard_slider_icon_title">Dimensions</span>
								<DocumentIcon className="icon icon-document_3 size_mod" />
							</button>
							<button className="dashboard_slider_icon resize_mod display-none-mode" type="button" ref={objectResizeBtn} onClick={openFullscreen}>
								<ResizeIcon className="icon icon-resize size_mod" />
							</button>
						</>
					)}
				</div>
				<Slider className="dashboard_slider" {...dashboardSliderSettings} ref={sliderEl}>
					{link_3d && (
						<div className="dashboard_slider_item">
							<div className="dashboard_slider_img v1_mod">
								<iframe className="dashboard_slider_model" src={link_3d} frameBorder="0" title="title" />
							</div>
							<div className="dashboard_slider_bottom">
								<div className="dashboard_slider_text">
									{main_3d_caption && (
										<div className="dashboard_slider_title">{ReactHtmlParser(main_3d_caption[langApp])}</div>
									)}
									{second_3d_caption && (
										<div className="dashboard_slider_descr">{ReactHtmlParser(second_3d_caption[langApp])}</div>
									)}
								</div>
							</div>
						</div>
					)}
					{
						(pathname.indexOf('the-old-synagogue-of-erfurt') >= 0) && (
							<div className="dashboard_slider_item">
								<div className="dashboard_slider_img v1_mod">
									<Pannellum
										width="100%"
										height="100%"
										image="https://i.imgur.com/s7ZKfyc.jpg"
										autoLoad
										onLoad={() => {}}
									/>
								</div>
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										{main_3d_caption && (
											<div className="dashboard_slider_title">{ReactHtmlParser(main_3d_caption[langApp])}</div>
										)}
										{second_3d_caption && (
											<div className="dashboard_slider_descr">{ReactHtmlParser(second_3d_caption[langApp])}</div>
										)}
									</div>
								</div>
							</div>
						)
					}
					{
						(pathname.indexOf('hamburg-during-the-life-of-gluckel-of-hameln') >= 0) && (
							<div className="dashboard_slider_item">
								<div className="dashboard_slider_img v1_mod">
									<Pannellum
										width="100%"
										height="100%"
										image="https://i.imgur.com/IkMtuG8.jpg"
										autoLoad
										onLoad={() => {}}
									/>
								</div>
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										{main_3d_caption && (
											<div className="dashboard_slider_title">{ReactHtmlParser(main_3d_caption[langApp])}</div>
										)}
										{second_3d_caption && (
											<div className="dashboard_slider_descr">{ReactHtmlParser(second_3d_caption[langApp])}</div>
										)}
									</div>
								</div>
							</div>
						)
					}
					{
						(pathname.indexOf('baroque-interior-of-the-hornburg-synagogue') >= 0) && (
							<div className="dashboard_slider_item">
								<div className="dashboard_slider_img v1_mod">
									<Pannellum
										width="100%"
										height="100%"
										image="https://i.imgur.com/IvowtIM.jpg"
										autoLoad
										onLoad={() => {}}
									/>
								</div>
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										{main_3d_caption && (
											<div className="dashboard_slider_title">{ReactHtmlParser(main_3d_caption[langApp])}</div>
										)}
										{second_3d_caption && (
											<div className="dashboard_slider_descr">{ReactHtmlParser(second_3d_caption[langApp])}</div>
										)}
									</div>
								</div>
							</div>
						)
					}
					{
						(pathname.indexOf('the-einstein-tower') >= 0) && (
							<div className="dashboard_slider_item">
								<div className="dashboard_slider_img v1_mod">
									<Pannellum
										width="100%"
										height="100%"
										image="https://i.imgur.com/Nl9KCFU.jpg"
										autoLoad
										onLoad={() => {}}
									/>
								</div>
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										{main_3d_caption && (
											<div className="dashboard_slider_title">{ReactHtmlParser(main_3d_caption[langApp])}</div>
										)}
										{second_3d_caption && (
											<div className="dashboard_slider_descr">{ReactHtmlParser(second_3d_caption[langApp])}</div>
										)}
									</div>
								</div>
							</div>
						)
					}

					{videos && videos.map(({
						video_embed_code,
						video_caption,
						video,
					}, index) => {
						return (
							<div className="dashboard_slider_item" key={index}>
								{
									video !== '' ? ReactHtmlParser(video) : video_embed_code !== '' ? ReactHtmlParser(video_embed_code) : null
								}
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										<div className="dashboard_slider_title">{ReactHtmlParser(video_caption[langApp])}</div>
									</div>
								</div>
							</div>
						);
					})}

					{photos && photos.map(({
						photo,
						photo_caption,
					}, index) => {
						const { url: imageUrl, alt: imageAlt } = photo;
						const { main_caption, second_caption } = photo_caption;
						return (
							<div className="dashboard_slider_item" key={index}>
								<div className="dashboard_slider_img">
									<img className="section_img" src={imageUrl} alt={imageAlt} />
								</div>
								<div className="dashboard_slider_bottom">
									<div className="dashboard_slider_text">
										<div className="dashboard_slider_title">{ReactHtmlParser(main_caption[langApp])}</div>
										<div className="dashboard_slider_descr">{ReactHtmlParser(second_caption[langApp])}</div>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		</>
	);
};

export default DashboardSlider;
