import { JsonDataContext } from 'context/jsonData';
import React, { useContext } from 'react';
import SearchResult from './SearchResult';

const SearchResultList = ({
	result,
}) => {
	const { langApp } = useContext(JsonDataContext);

	return (
		<div className="search_result_w">
			<ul className="search_result_list">
				{result.map(({
					id,
					subtype,
					title,
					excerpt,
					status,
					type,
					url,
					slug,
				}) => {
					return (
						<li className="search_result_list_item" key={id}>
							<SearchResult
								id={id}
								subtype={subtype}
								title={title[langApp]}
								type={type}
								url={url}
								status={status}
								excerpt={excerpt ? excerpt[langApp] : false}
								slug={slug}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default SearchResultList;
