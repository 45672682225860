import UserAvatar from 'i/user_avatar.svg';

export const MY_ACCOUNT_PAGE_ARRAY = {
	accountFormArray: {
		formHeadTitle: {
			en: 'My Account',
			de: 'Mein Benutzerkonto',
		},
		formLeftColTitle: {
			en: 'Personal Data',
			de: 'Persönliche Informationen',
		},
		formLeftFields: [
			{
				title: {
					en: 'First name',
					de: 'Vorname',
				},
				type: 'text',
				id: 'firstName',
			},
			{
				title: {
					en: 'Last name',
					de: 'Nachname',
				},
				type: 'text',
				id: 'lastName',
			},
			{
				title: {
					en: 'Email Address',
					de: 'E-Mail-Adresse',
				},
				type: 'email',
				id: 'email',
			},
		],
		formRighttColTitle: {
			en: 'Account Data',
			de: 'Informationen zum Benutzerkonto',
		},
		forRightFields: [
			{
				title: {
					en: 'New Password',
					de: 'Neues Passwort',
				},
				type: 'password',
				id: 'newPassword',
			},
			{
				title: {
					en: 'Repeat New Password',
					de: 'Wiederholung neues Passwort',
				},
				type: 'password',
				id: 'repeatNewPassword',
			},
			{
				title: {
					en: 'Current Password',
					de: 'Aktuelles Passwort',
				},
				type: 'password',
				id: 'currentPassword',
			},
		],
		notificationsTitle: {
			en: 'Notifications',
			de: 'Benachrichtigungen',
		},
		notificationsFields: [
			{
				title: {
					en: 'I wish to receive news, promotions and invitations from The Shared History Project',
					de: 'Ich möchte Neuigkeiten, Werbeaktionen und Einladungen von The Shared History Project erhalten',
				},
				id: 'subscribeSHP',
				type: 'checkbox',
			},
			{
				title: {
					en: 'I wish to receive news, promotions and invitations from Leo Baeck Institute',
					de: 'Ich möchte Neuigkeiten, Werbeaktionen und Einladungen vom Leo-Baeck-Institut erhalten',
				},
				id: 'subscribeLBI',
				type: 'checkbox',
			},
		],
	},
};
