import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

// // components
import { ThemeNavBlock } from 'components/NavBlock';
import { ThemeSwitcher } from 'components/ThemeSwitcher';
import { ThemeLinks, ThemePageNav, ThemeRelatedObjects } from 'components/Theme';
import { useAlert } from 'components/Alert';
import { getSingleThemeBySlug } from 'api/data';
import { stripTags, setItemToLocalStorage } from 'utils';

import { ReactComponent as IconFacebook } from 'i/icons/facebook.svg';
import { ReactComponent as IconTwitter } from 'i/icons/twitter.svg';
import { ReactComponent as IconMail } from 'i/icons/envelope.svg';

import { JsonDataContext } from 'context/jsonData';
import { THEME_ARRAY } from './constant';

const ThemePage = props => {
	const { langApp, setLangApp } = useContext(JsonDataContext);

	const [isLoading, setIsLoading] = useState(true);
	const [thisPageData, setThisPageData] = useState(null);

	const [alert, showAlert] = useAlert();

	const getPageDataBySlug = async slug => {
		try {
			setIsLoading(true);

			const res = await getSingleThemeBySlug(slug);

			if (res && res.data && res.data.length) {
				setThisPageData(res.data[0]);
				setIsLoading(false);
			} else {
				alert('Refresh the page and try again.');
			}
		} catch (err) {
			setIsLoading(false);
			showAlert(err.message);
		}
	};

	const changeLanguage = language => {
		const preparedLanguageValue = language.toLowerCase();

		setItemToLocalStorage('user-language', preparedLanguageValue);
		setLangApp(preparedLanguageValue);
	};

	useEffect(() => {
		let searchBySlug = props.match.params.slug;
		const enPaths = THEME_ARRAY.urlSlugMapping.map(u => u[1]);
		const dePaths = THEME_ARRAY.urlSlugMapping.map(u => u[2]);

		if (enPaths.indexOf(searchBySlug) >= 0) {
			// Found in english
			changeLanguage('en');
			[searchBySlug] = THEME_ARRAY.urlSlugMapping[enPaths.indexOf(searchBySlug)];
		} else if (dePaths.indexOf(searchBySlug) >= 0) {
			// Found in german
			changeLanguage('de');
			[searchBySlug] = THEME_ARRAY.urlSlugMapping[dePaths.indexOf(searchBySlug)];
		}
		getPageDataBySlug(searchBySlug);
	}, [props.match.params.slug]);

	return (
		!isLoading && thisPageData && (
			<>
				<Helmet>
					<meta property="og:url" content={window.location.href} />
				</Helmet>

				{thisPageData.title && (
					<Helmet>
						<title>{thisPageData.title[langApp]}</title>
						<meta itemProp="name" content={thisPageData.title[langApp]} />
						<meta property="og:title" content={thisPageData.title[langApp]} />
						<meta name="twitter:title" content={thisPageData.title[langApp]} />
					</Helmet>
				)}

				<section className="section">
					<div className="section_in">
						<div className="essay">
							<div className="essay_col sidebar_mod">
								<div className="essay_sidebar_head">
									<div className="essay_sidebar_title">{THEME_ARRAY.allThemesTitle[langApp]}</div>
									{/* <div className="essay_slidebad_like">
										<IconHeart className="icon icon-heart size_mod" />
									</div> */}
								</div>
								{thisPageData.all_themes && (thisPageData.all_themes.length > 0) && (
									<ThemeLinks
										themes={thisPageData.all_themes}
										currentThemeSlug={props.match.params.slug}
										offsetMod
									/>
								)}
								<div className="essay_sidebar_bottom theme-page">
									<ul className="social_list essay_mod">
										<li className="social_item">
											<a href={`https://www.facebook.com/sharer.php?u=${window.location.href}%2F&text=${stripTags(thisPageData.title[langApp])}`} target="_blank" rel="noreferrer" className="social_link essay_mod" data-show-count="false">
												<IconFacebook className="icon icon-twitter size_mod" />
											</a>
										</li>
										<li className="social_item">
											<a href={`https://twitter.com/intent/tweet?url=${window.location.href}%2F&text=${stripTags(thisPageData.title[langApp])}`} target="_blank" rel="noreferrer" className="social_link essay_mod" data-show-count="false">
												<IconTwitter className="icon icon-twitter size_mod" />
											</a>
										</li>
										<li className="social_item">
											<a href={`mailto:?subject=${stripTags(thisPageData.title[langApp])}&body=Visit this site ${window.location.href}`} target="_blank" rel="noreferrer" className="social_link essay_mod" data-show-count="false">
												<IconMail className="icon icon-twitter size_mod" />
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="essay_col content_mod">
								<div className="essay_menu_wrap">
									<ThemePageNav
										themes={thisPageData.all_themes}
										currentThemeSlug={props.match.params.slug}
										buttonLabels={{
											prev: THEME_ARRAY.previousButtonLabel[langApp],
											next: THEME_ARRAY.nextButtonLabel[langApp],
										}}
									/>
									<div className="essay_theme">
										<ThemeSwitcher />
									</div>
								</div>
								<div className="essay_block">
									<div className="essay_head">
										<div className="essay_head_top">
											<h2 className="section_title size_v2_mod limit_mod">{thisPageData.title[langApp]}</h2>
										</div>
										{thisPageData.acf && thisPageData.acf.short_description && (
											<div className="essay_head_author theme-page">
												&mdash;&nbsp;
												{`${thisPageData.acf.short_description[langApp]}`}
												&nbsp;&mdash;
											</div>
										)}
									</div>
									<div className="essay_content_wrap">
										<div className="essay_content">
											<p className=""><b>{THEME_ARRAY.essayKeyword[langApp]}</b></p>
											{ReactHtmlParser(thisPageData.acf.content[langApp])}
										</div>
									</div>
									{thisPageData.acf.mapped_objects.length > 0 && (
										<div className="essay_content_wrap margin-top-5rem">
											<div className="essay_content">
												<p><b>{THEME_ARRAY.relatedObjectsLine[langApp]}</b></p>
												<ThemeRelatedObjects
													mappedObjectIds={thisPageData.acf.mapped_objects.map(a => a.object.ID)}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<ThemeNavBlock
							themes={thisPageData.all_themes}
							currentThemeSlug={props.match.params.slug}
							buttonLabels={{
								prev: THEME_ARRAY.previousButtonLabel[langApp],
								next: THEME_ARRAY.nextButtonLabel[langApp],
							}}
						/>
					</div>
				</section>
			</>
		)
	);
};

export default ThemePage;
