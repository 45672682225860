import WeekObjectImg from '../i/object/moped_2.jpg';
import WeekObjectImg2 from '../i/object/moped.jpg';
import infoBlockImg from '../i/img3.jpg';
import HeroImg from '../i/hero.jpg';
import VisitImg from '../i/virtual_room.jpg';
import Img1 from '../i/essay.jpg';
import Img2 from '../i/panel.jpg';
import SupportImg1 from '../i/support_1.svg';
import SupportImg2 from '../i/support_2.svg';
import SupportImg3 from '../i/support_3.svg';
import SupportImg4 from '../i/support_4.svg';
import SupportImg5 from '../i/support_5.svg';
import SupportImg6 from '../i/support_6.svg';
import SupportImg7 from '../i/support_7.svg';
import SupportImg8 from '../i/support_8.svg';
import SupportImg9 from '../i/support_9.png';
import ImgHero from '../i/landing_hero_img.svg';
import ImgDecor from '../i/landing_hero_decor.png';
// import mopedImg from '../i/object/moped.jpg';

const APP_DATA = {
	homePage: {
		heroSliderArray: [
			{
				title: '1700 Years of Jewish Life in German-speaking Lands',
				descr: '<p>2021 marks the 1700th anniversary of a Constantinian edict, the earliest evidence of a Jewish community in the German-speaking world.</p>',
				btnTitle: 'Learn More',
				image: HeroImg,
			},
			{
				title: '1700 Years of Jewish Life in German-speaking Lands',
				descr: '<p>2021 marks the 1700th anniversary of a Constantinian edict, the earliest evidence of a Jewish community in the German-speaking world.</p>',
				btnTitle: 'Learn More',
				image: HeroImg,
			},
		],
		heroProjectContent: {
			text: '<p>The Shared History Project presents 58 objects pertaining to the German Jewish history in the German-speaking territories across the centuries.</p>',
			title: 'New objects will be revealed throughout 2021, Each Sunday, one new object will appear on the website. ',
		},
		exploreOurObjects: {
			title: 'Explore our Objects',
			items: [
				{
					url: '/objects-list',
					title: {
						en: 'By <br> Object',
						de: 'über <br> die Objekte',
					},
				},
				{
					url: '/timeline',
					title: {
						en: 'By <br> TIMELINE',
						de: 'über <br>die Zeitleiste',
					},
				},
				{
					url: '/map',
					title: {
						en: 'By <br> LOCATION',
						de: 'über <br>die Karte',
					},
				},
			],
		},
		upcomingObjectsSection: {
			title: {
				en: 'Upcoming Objects',
				de: 'Upcoming Objects',
			},
			listObjects: [
				{
					id: 278,
					starred: false,
					status: 'publish',
					publish_date: '2020-11-23',
					title: {
						en: 'Simson “Schwalbe” KR 51 Moped',
						de: 'Simson “Schwalbe” KR 51 Moped De',
					},
					object_number: {
						en: 'Object 001',
						de: 'Objekt 001',
					},
					photos: [
						{
							photo: {
								url: WeekObjectImg2,
								alt: 'moped',
							},
						},
					],
					object_image: false,
					text: 'To be published Feb. 23',
					object: [
						{
							type: 'origin',
							value: {
								en: '',
								de: '',
							},
						},
						{
							type: 'year',
							value: '',
						},
						{
							type: 'essays',
							value: [],
						},
					],
				},
				{
					id: 278,
					starred: false,
					status: 'publish',
					publish_date: '2020-11-23',
					title: {
						en: 'Simson “Schwalbe” KR 51 Moped',
						de: 'Simson “Schwalbe” KR 51 Moped De',
					},
					object_number: {
						en: 'Object 002',
						de: 'Objekt 002',
					},
					photos: [
						{
							photo: {
								url: WeekObjectImg2,
								alt: 'moped',
							},
						},
					],
					object_image: false,
					text: 'To be published Feb. 23',
					object: [
						{
							type: 'origin',
							value: {
								en: '',
								de: '',
							},
						},
						{
							type: 'year',
							value: '',
						},
						{
							type: 'essays',
							value: [],
						},
					],
				},
			],
			button: {
				en: 'Object list',
				de: 'Object list',
			},
		},
		bannerArray: {
			title: {
				en: 'Discover all the features, while staying up-to-date with the latest news',
				de: 'Entdecken Sie alle Funktionen und bleiben Sie stets über die neuesten Nachrichten informiert',
			},
			text: {
				en: '<p>In the world full of information and busy moments, we encourage you to sign up to our website.</p><p>This will give you access to our weekly newsletter and an option to download the materials to read them later or use them as an educational support.</p>',
				de: '<p> In einer Welt voller Informationen und geschäftiger Momente empfehlen wir Ihnen, sich auf unserer Website anzumelden. </ p> <p> Auf diese Weise erhalten Sie Zugang zu unserem wöchentlichen Newsletter und können die Materialien herunterladen, um sie später zu lesen oder sie als pädagogische Unterstützung zu nutzen. </ p> ',
			},
			btnUrl: '#',
			btnTitle: {
				en: 'Sign up',
				de: 'Anmelden',
			},
		},
		upcomingEventsSection: {
			title: {
				en: 'Upcoming Event',
				de: 'Upcoming Event',
			},
			button: {
				en: 'VIEW EVENT',
				de: 'VIEW EVENT',
			},
		},
		weekObjectSection: {
			sectionTitle: {
				en: 'This Week’s Object',
				de: 'Das Objekt dieser Woche',
			},
			title: {
				en: 'Simson "Schwalbe" KR51 Moped',
				de: 'Simson “Schwalbe” KR 51 Moped',
			},
			id: 79,
			image: WeekObjectImg,
			secondImage: WeekObjectImg2,
			objectNumber: {
				en: 'Object 001',
				de: 'Objekt 001',
			},
			description: {
				en: 'The East-German moped that powered the socialist country’s postal service has a cult-following, but few know the story of its Jewish past.',
				de: 'Das ostdeutsche Moped, das den Postdienst des sozialistischen Landes antrieb, hat eine Kult-Anhängerschaft, aber nur wenige kennen die Geschichte seiner jüdischen Vergangenheit.',
			},
			objectInfo: [
				{
					type: 'origin',
					value: {
						en: 'Suhl, Thuringia',
						de: 'Suhl, Thüringia',
					},
				},
				{
					type: 'year',
					value: '1960',
				},
				{
					type: 'essays',
					value: [
						'Joe Black',
						'David Brown',
					],
				},
			],
			btnTitle: {
				en: 'View object',
				de: 'Objekt anzeigen',
			},
		},
		visitVirtualExhibit: {
			title: 'or visit the Virtual Exhibit',
			url: '#',
			btnTitle: {
				en: 'Enter',
				de: 'Eingeben',
			},
			img: VisitImg,
		},
		infoBlockArray: {
			headTitle: {
				en: 'Those behind the<br> Shared History<br> Project',
				de: 'Die Akteure <br> hinter dem <br> Projekt',
			},
			title: {
				en: 'The Shared History Project was initiated by the Leo Baeck Institute – New York | Berlin (LBI)',
				de: 'Das Shared History Projekt wurde vom Leo Baeck Institute – New York | Berlin (LBI) initiiert',
			},
			text: {
				en: ['The Shared History Project was initiated by the Leo Baeck Institute – New York | Berlin (LBI) and supported by #2021JLID – Jüdisches Leben in Deutschland e.V. with funding from the Federal Ministry of the Interior, Building and Community (BMI).'],
				de: ['Das Shared History Projekt wurde vom Leo Baeck Institute – New York | Berlin (LBI) initiiert und wurde gefördert durch #2021JLID – Jüdisches Leben in Deutschland e.V. aus Mitteln des Bundesministerium des Innern, für Bau und Heimat (BMI).'],
			},
			btnUrl: 'about',
			btnTitle: {
				en: 'Learn more',
				de: 'Mehr erfahren',
			},
			img: infoBlockImg,
		},
		infoBlockBottomArray: {
			title: {
				en: 'Meet our collaborators',
				de: 'Treffen Sie unsere Mitarbeiter',
			},
			text: {
				en: ['The Shared History Project wouldn’t be possible without the help of key collaborators and the research of many sources.'],
				de: ['Das Shared History-Projekt wäre ohne die Hilfe wichtiger Mitarbeiter und die Recherche vieler Quellen nicht möglich.'],
			},
			btnUrl: '/credits',
			btnTitle: {
				en: 'Learn more',
				de: 'Mehr erfahren',
			},
		},
	},
	objectPopup: {
		viewInTimelineButton: {
			title: {
				en: 'View in Timeline',
				de: 'In der Zeitleiste anzeigen',
			},
			url: '/timeline',
		},
		viewInMapButton: {
			title: {
				en: 'View in Map',
				de: 'In Karte anzeigen',
			},
			url: '/map',
		},
		viewInVirtualExhibitButton: {
			title: {
				en: 'View in Virtual Exhibit',
				de: 'Ansicht in virtueller Ausstellung',
			},
			url: '/virtual-exhibit',
		},
		viewObjectButton: {
			en: 'View Object',
			de: 'Objekt anzeigen',
		},
		readEssaysButton: {
			en: 'Read Essays',
			de: 'Lesen Sie die Essays',
		},
	},
	loginPopup: {
		title: {
			en: 'Log In to your account',
			de: 'Melden Sie sich in mit Ihrem Benutzerkonto an',
		},
		submitButton: {
			en: 'Log In',
			de: 'Anmelden',
		},
		warningMessage: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque',
			de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque',
		},
		resetPasswordTitle: {
			en: 'Can’t remember your Password?',
			de: 'Sie können sich nicht an Ihr Passwort erinnern?',
		},
		resetPasswordLink: {
			en: 'Reset It',
			de: 'Setzen Sie es zurück',
		},
	},
	timelineWelcomePopup: {
		title: {
			en: 'Welcome to timeline',
			de: 'Welcome to timeline',
		},
		text: {
			en: 'Lorem ipsum..',
			de: 'Lorem ipsum..',
		},
		image: {
			en: '/images/timeline-popup-message-en.png',
			de: '/images/timeline-popup-message-de.png',
		},
	},
	virtualExhibitInfoPopup: {
		image: {
			en: '/images/virtual-exhibit-info-message-en.png',
			de: '/images/virtual-exhibit-info-message-de.png',
		},
	},
	resetPasswordPopup: {
		title: {
			en: 'Reset password',
			de: 'Reset password',
		},
		submitButton: {
			en: 'Reset',
			de: 'Reset',
		},
	},
	forgotPasswordPopup: {
		submitButton: {
			en: 'Reset',
			de: 'Reset',
		},
	},
	signupPopup: {
		title: {
			en: 'Welcome to SHP',
			de: 'Willkommen bei SHP',
		},
		description: {
			en: 'Create your own account to fully experience all features and downloadable materials of SHP. With a personalized account you will be able to save your favorite essays and objects and read them later and/or use them as an educational support.',
			de: 'Richten Sie Ihr eigenes Konto ein, um Zugriff auf alle Features und herunterladbaren Materialien  zu erhalten. Mit einem personalisierten Konto erhalten Sie die Möglichkeit Lieblingsessays und - objekte zu speichern und sie später zu lesen oder im Unterricht zu verwenden.',
		},
		checkboxText: {
			en: 'Subscribe to our newsletter for news on upcoming objects every week, additional essays, early access to events and more.',
			de: 'Abonnieren Sie unseren Newsletter, um jede Woche Neuigkeiten zu kommenden Objekten, weiteren Essays, früheren Zugriff auf anstehende Veranstaltungen und vieles mehr zu erhalten.',
		},
		loginText: {
			en: 'Already have an account?',
			de: 'Sie haben bereits ein Konto?',
		},
		loginLink: {
			en: 'Log In',
			de: 'Einloggen',
		},
		submitButton: {
			en: 'Submit',
			de: 'ANMELDEN',
		},
		warningMessage: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque',
			de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque',
		},
		privacyTitle: {
			en: 'We will not use your personal data for commercial purposes or share it with anyone',
			de: 'Wir werden Ihre persönlichen Angaben nicht für kommerzielle verwenden oder mit Dritten teilen.',
		},
		privacyText: {
			en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque aliquam lobortis cursus. Ut varius lectus libero, quis dapibus ex laoreet non. Sed ut dolor id eros tempor rutrum. Phasellus id lorem euismod, consequat metus ut, pulvinar mi. Quisque feugiat neque non elit luctus, laoreet sagittis ipsum feugiat. Proin id sagittis est. Morbi in auctor orci.',
			de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat suscipit felis sed hendrerit. Etiam condimentum et ipsum vitae consectetur. Etiam volutpat massa vel elit elementum aliquam. Quisque aliquam lobortis cursus. Ut varius lectus libero, quis dapibus ex laoreet non. Sed ut dolor id eros tempor rutrum. Phasellus id lorem euismod, consequat metus ut, pulvinar mi. Quisque feugiat neque non elit luctus, laoreet sagittis ipsum feugiat. Proin id sagittis est. Morbi in auctor orci.',
		},
		privacyLink: {
			en: 'Read our Privacy Policy',
			de: 'Lesen Sie unsere Datenschutzbestimmungen',
		},
	},
	essayPage: {
		essayMenu: [
			{
				title: {
					en: 'Back to Object',
					de: 'Back to Object',
				},
				type: 'backToObject',
				url: '#',
			},
			{
				title: {
					en: 'Previous Essay',
					de: 'Previous Essay',
				},
				type: 'previousEssay',
				url: '#',
			},
			{
				title: {
					en: 'Next Essay',
					de: 'Next Essay',
				},
				type: 'textEssay',
				url: '#',
			},
		],
		essayButtonList: [
			{
				url: '/object/',
				title: {
					en: 'Back to Object',
					de: 'Zurück zu Objekten',
				},
			},
			{
				url: '/essay/',
				title: {
					en: 'Previous Essay',
					de: 'Vorheriger Aufsatz',
				},
			},
			{
				url: '/essay/',
				title: {
					en: 'Next Essay',
					de: 'Nächster Aufsatz',
				},
			},
		],
		essayEpochList: [
			{
				id: '',
				title: '4th Century to Middle Ages',
			},
			{
				id: '',
				title: 'Early Modern (1450 - 17th century)',
			},
			{
				id: '',
				title: 'Absolutism (1648 - 1806)',
			},

		],
	},
	objectDashboardPage: {
		essayMenu: [
			{
				title: {
					en: 'Overview',
					de: 'Überblick',
				},
				type: 'overview',
			},
			{
				title: {
					en: 'Essays',
					de: 'Aufsätze',
				},
				type: 'essays',
			},
			{
				title: {
					en: 'Provenance',
					de: 'Herkunft',
				},
				type: 'provenance',
			},
			{
				title: {
					en: 'Description',
					de: 'Beschreibung',
				},
				type: 'description',
			},
			{
				title: {
					en: 'View in Timeline',
					de: 'In der Zeitleiste anzeigen',
				},
				url: '/timeline',
				type: 'timeline',
			},
			{
				title: {
					en: 'View in Map',
					de: 'In Karte anzeigen',
				},
				url: '/map',
				type: 'map',
			},
			{
				title: {
					en: 'View in Virtual Exhibit',
					de: 'Ansicht in virtueller Ausstellung',
				},
				url: '/virtual-exhibit',
				type: 'virtual',
			},
		],
		navigationList: [
			{
				url: '/objects-list',
				title: {
					en: 'Back to Objects',
					de: 'Zurück zur Objektliste',
				},
			},
			{
				url: '/object/',
				title: {
					en: 'Previous Object',
					de: 'Vorheriges Objekt',
				},
			},
			{
				url: '/object/',
				title: {
					en: 'Next Object',
					de: 'Nächstes Objekt',
				},
			},
		],
		exploreOtherViews: {
			title: {
				en: 'Explore other Views',
				de: 'Erkunden Sie andere Darstellungen',
			},
			items: [
				{
					url: '/timeline',
					title: {
						en: 'By <br> TIMELINE',
						de: 'über <br>die Zeitleiste',
					},
				},
				{
					url: '/map',
					title: {
						en: 'By <br> LOCATION',
						de: 'über <br>die Karte',
					},
				},
			],
		},
		releaseDateTitle: {
			en: 'Release Date',
			de: 'Veröffentlichungsdatum',
		},
	},
	timelinePage: {
		buttonReadEssayTitle: {
			en: 'Read Essay',
			de: 'Essay lesen',
		},
	},
	objectsListPage: {
		collection: {
			title: {
				en: 'The Collection',
				de: 'Die Sammlung',
			},
			subtitle: {
				en: 'Late Roman Empire to the Middle Ages',
				de: 'Spätrömisches Reich bis zum Mittelalter',
			},
			text: {
				en: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'],
				de: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'],
			},
			btnUrl: '#',
			btnTitle: {
				en: 'Read ESSAY',
				de: 'Aufsatz lesen',
			},
		},
		bannerArray: {
			title: {
				en: 'Stay up-to-date every week. New objects will be revealed throughout 2021.',
				de: 'Bleiben Sie jede Woche auf dem Laufenden. Neue Objekte werden im Laufe des Jahres 2021 enthüllt.',
			},
			text: {
				en: '<p>Subscribe to our bulletin for news on upcoming objects every week, additional essays, early access to events, and more.</p>',
				de: '<p> Abonnieren Sie unser Bulletin, um jede Woche Neuigkeiten zu bevorstehenden Objekten, zusätzliche Aufsätze, frühzeitigen Zugriff auf Veranstaltungen und mehr zu erhalten. </ p>',
			},
			btnUrl: '#',
			btnTitle: {
				en: 'Sign up',
				de: 'Anmelden',
			},
			bottomDescr: {
				en: 'Or <a href="#">Sign Up</a> for additional access to all features.',
				de: 'Oder <a href="#"> Anmelden </a> für zusätzlichen Zugriff auf alle Funktionen.',
			},
		},
		toBePublishedTitle: {
			en: 'To be published',
			de: 'wird veröffentlicht am',
		},
	},
	eventsPage: {
		title: {
			en: 'Events',
			de: 'Veranstaltungen',
		},
		bannerArray: {
			title: {
				en: 'Stay up-to-date every week. New objects will be revealed throughout 2021.',
				de: 'Bleiben Sie jede Woche auf dem Laufenden. Neue Objekte werden im Laufe des Jahres 2021 enthüllt.',
			},
			text: {
				en: '<p>Subscribe to our bulletin for news on upcoming objects every week, additional essays, early access to events, and more.</p>',
				de: '<p> Abonnieren Sie unser Bulletin, um jede Woche Neuigkeiten zu bevorstehenden Objekten, zusätzliche Aufsätze, frühzeitigen Zugriff auf Veranstaltungen und mehr zu erhalten. </ p>',
			},
			btnUrl: '#',
			btnTitle: {
				en: 'Sign up',
				de: 'Anmelden',
			},
			bottomDescr: {
				en: 'Or <a href="#">Sign Up</a> for additional access to all features.',
				de: 'Oder <a href="#"> Anmelden </a> für zusätzlichen Zugriff auf alle Funktionen.',
			},
		},
		institute: {
			institute_title: {
				en: 'About Leo Baeck Institute',
				de: 'Über Leo Baeck Institut',
			},
			institute_subtitle: {
				en: '<p>The Leo Baeck Institute New York | Berlin (LBI) is a research collection that has been devoted to the history and culture of German-speaking Jews for 65 years.</p>\n',
				de: '<p>Das Leo-Baeck-Institut New York | Berlin (LBI) ist eine Forschungssammlung, die sich seit 65 Jahren der Geschichte und Kultur deutschsprachiger Juden widmet.</p>\n',
			},
			institute_left_text: {
				en: '<p>The collection was founded in 1955 by German-Jewish academics and intellectuals out of the bitter experience of the Holocaust, so that it would be available as source material primarily to historians and other scholars for the scholarly study “what was once German Jewry. “ The institute was named after Rabbi Leo Baeck, the last leading representative of the Jewish communities under National Socialism.</p>\n',
				de: '<p>Die Sammlung wurde 1955 von deutsch-jüdischen Akademikern und Intellektuellen aus der bitteren Erfahrung des Holocaust heraus gegründet, damit sie vor allem Historikern und anderen Gelehrten als Ausgangsmaterial für die wissenschaftliche Untersuchung „Was war einst deutsches Judentum?“ Zur Verfügung stand. „Das Institut wurde nach Rabbi Leo Baeck benannt, dem letzten führenden Vertreter der jüdischen Gemeinden im Nationalsozialismus.</p>\n',
			},
			institute_right_text: {
				en: '<p>In addition to the Institute in New York, sister institutes were established in London and Jerusalem. What all locations have in common is that the largest German-Jewish immigrant groups were located there after the Second World War.</p>\n<p>The institutes in London and Jerusalem are mainly devoted to publication activities and the organization of scholarly events, although there is now also a smaller collection in Jerusalem, which is completely recorded in the New York catalogue and is currently being digitized.“</p>\n<p>In 2001, the New York LBI founded a branch of the archive in Berlin, located at the Jewish Museum there, and since then has called itself the Leo Baeck Institute New York | Berlin.</p>\n',
				de: '<p>Neben dem Institut in New York wurden Schwesterinstitute in London und Jerusalem gegründet. Allen Orten ist gemeinsam, dass sich dort nach dem Zweiten Weltkrieg die größten deutsch-jüdischen Einwanderergruppen befanden.</p>\n<p>Die Institute in London und Jerusalem widmen sich hauptsächlich Publikationsaktivitäten und der Organisation wissenschaftlicher Veranstaltungen. In Jerusalem gibt es jetzt auch eine kleinere Sammlung, die vollständig im New Yorker Katalog erfasst ist und derzeit digitalisiert wird. “</p>\n<p>Im Jahr 2001 gründete das New Yorker LBI in Berlin eine Zweigstelle des Archivs, die sich im dortigen Jüdischen Museum befindet, und nennt sich seitdem Leo Baeck Institute New York | Berlin.</p>\n',
			},
			institute_button: {
				title: {
					en: 'Visit website',
					de: 'Besuche die Website',
				},
				link: {
					title: '',
					url: ' http://google.com',
					target: '',
				},
			},
		},
		upcomingEvents: {
			title: {
				en: 'Upcoming Events',
				de: 'Kommende Veranstaltungen',
			},
		},
		pastEvents: {
			title: {
				en: 'Past Events',
				de: 'Frühere Veranstaltungen',
			},
		},
		noEvents: {
			title: {
				en: 'No events',
				de: 'Keine Ereignisse',
			},
		},
	},
	filters: {
		buttonShowAllFilterLabels: {
			en: 'Filter your search',
			de: 'Filtern Sie Ihre Suche',
		},
		buttonClearAllFilters: {
			en: 'Clear All Filters',
			de: 'Alle Filter löschen',
		},
	},
	popupObject: {
		head: {
			en: 'Object 001',
			de: 'Objekt 001',
		},
		icon: 'heart',
		menu: [
			{
				url: '#',
				title: {
					en: 'View Object',
					de: 'Objekt anzeigen',
				},
			},
			{
				url: '#',
				title: {
					en: 'View in Map',
					de: 'In Karte anzeigen',
				},
			},
			{
				url: '#',
				title: {
					en: 'View in Virtual Exhibit',
					de: 'Ansicht in virtueller Ausstellung',
				},
			},
		],
		img: Img1,
		title: {
			en: 'Simson “Schwalbe” KR 51 Moped',
			de: 'Simson "Schwalbe" KR 51 Moped',
		},
		text: {
			en: 'The East-German moped that powered the socialist country’s postal service has a cult-following, but few know the story of its Jewish past.',
			de: 'Das ostdeutsche Moped, das den Postdienst des sozialistischen Landes angetrieben hat, hat eine Kult-Anhängerschaft, aber nur wenige kennen die Geschichte seiner jüdischen Vergangenheit.',
		},
		table: [
			{
				id: 1,
				type: 'origin',
				value: {
					en: 'Suhl, Thüringia',
					de: 'Suhl, Thüringia',
				},
			},
			{
				id: 2,
				iconMod: 'date_mod',
				type: 'year',
				value: {
					en: '1964',
					de: '1964',
				},
				url: '#',
			},
			{
				id: 3,
				type: 'essays',
				value: {
					en: 'By David Brown, Leo Back Institute',
					de: 'Von David Brown, Leo Back Institute',
				},
			},
		],
	},
	popupHistory: {
		title: {
			en: 'Constantine The Great reigns as Roman Emperor',
			de: 'Konstantin der Große regiert als römischer Kaiser',
		},
		img: Img2,
		imgMod: 'lg_mod',
		text: {
			en: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.',
			de: 'Lorem ipsum dolor sitzen amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore und dolore magna aliquyam erat, sed diam voluptua. Bei Vero Eos und Akkusam und Justo Duo Dolores und Rebum. Stet clita kasd gubergren, kein Meer takimata sanctus est Lorem ipsum dolor sitzen amet. Lorem ipsum dolor sitzen amet, consetetur sadipscing elitr, sed diam nonumy. ',
		},
	},
	PopupCookieSettings: {
		title: {
			en: 'Cookie Settings',
			de: 'Cookie-Einstellungen',
		},
		list: [
			{
				title: {
					en: 'Strictly necessary cookies',
					de: 'Unbedingt notwendige Cookies',
				},
				id: 'necessaryCookies',
				text: {
					en: 'These cookies are essential so that you can move around the website and use its features. Without these cookies services you have asked for cannot be provided.',
					de: 'Diese Cookies sind unerlässlich, damit Sie sich auf der Website bewegen und deren Funktionen nutzen können. Ohne diese von Ihnen angeforderten Cookies können keine Dienste bereitgestellt werden.',
				},
			},
			{
				title: {
					en: 'Functional cookies',
					de: 'Funktionale Cookies',
				},
				id: 'functionalCookies',
				text: {
					en: 'These cookies allow the website to remember choices you make to give you better functionality and personal features.',
					de: 'Mit diesen Cookies kann sich die Website an Ihre Auswahl erinnern, um Ihnen bessere Funktionen und persönliche Funktionen zu bieten.',
				},
			},
			{
				title: {
					en: 'Performance cookies',
					de: 'Leistungscookies',
				},
				id: 'performanceCookies',
				text: {
					en: 'These cookies help to improve the performance of the website.',
					de: 'Diese Cookies tragen zur Verbesserung der Leistung der Website bei.',
				},
			},
		],
		btnTitle: {
			en: 'ACCEPT',
			de: 'AKZEPTIEREN',
		},
	},
	PopupPlease: {
		text: {
			en: 'Please, stay tuned for the opening of the virtual exhibit February 28',
			de: 'Eröffnung in Kürze - Unsere virtuelle Ausstellung öffnet ihre Pforten für Sie 28. Februar',
		},
		btnUrl: '#',
		btnTitle: {
			en: 'ACCEPT',
			de: 'AKZEPTIEREN',
		},
	},
	PopupWelcome: {
		title: {
			en: 'Thank you for joining us at SHP',
			de: 'Vielen Dank, dass Sie sich für das Shared History Project angemeldet haben',
		},
		subtitle: {
			en: 'Your subscription gives you access to all features on this site:',
			de: 'Mit Ihrem Abonnement erhalten Sie Zugriff auf alle Funktionen dieser Website:',
		},
		list: {
			en: [
				'Create your own list of objects and essays for quick access',
				'Download all reading material',
				'Receive updates for upcoming objects, new reading material, and future events',
			],
			de: [
				'Erstellen Sie Ihre eigene Liste von Objekten und Aufsätzen für den schnellen Zugriff',
				'Laden Sie Lesematerial herunter',
				'Erhalten Sie Updates für bevorstehende Objekte, neues Lesematerial und zukünftige Ereignisse',
			],
		},
		text: {
			en: 'Remember to mark <a href="mailto:news@shp.com">news@shp.com</a> as “safe sender” in your email provider, so you get all the updates we’ll be sending you.',
			de: 'Denken Sie daran, <a href="mailto:news@shp.com"> news@shp.com </a> in Ihrem E-Mail-Anbieter als "sicheren Absender" zu markieren, damit Sie alle Updates erhalten, die wir Ihnen senden.',
		},
		button: {
			en: 'continue',
			de: 'fortsetzen',
		},
	},
	credits: {
		supporters: {
			title: {
				en: 'Supporters',
				de: 'Förderer',
			},
			list: [
				{
					mod: 'v2_mod',
					title: {
						en: 'Website and virtual exhibit of the Shared History project',
						de: 'Webseite und virtuelle Ausstellung des Shared History Projekts',
					},
					content: [
						{
							title: {
								en: 'Supported by: #2021JLID – Jüdisches Leben in Deutschland e.V. with funds of the Federal Ministry of the Interior, Building and Community (BMI)',
								de: 'Gefördert durch #2021JLID – Jüdisches Leben in Deutschland e.V. aus Mitteln des Bundesministerium des Innern, für Bau und Heimat (BMI)',
							},
							logos: [SupportImg1, SupportImg2],
						},
					],
				},
				{
					mod: 'v2_mod',
					title: {
						en: 'Physical exhibit',
						de: 'Physische Ausstellung',
					},
					content: [
						{
							title: 'Deutscher Bundestag',
							logos: [SupportImg3],
						},
					],
				},
				{
					title: {
						en: 'Conference',
						de: 'Konferenz',
					},
					content: [
						{
							title: 'Bundeszentrale für politische Bildung (bpb)',
							logos: [SupportImg4],
						},
						{
							title: 'Auswärtiges Amt',
							logos: [SupportImg7],
						},
					],
				},
				{
					title: {
						en: 'Additional Support',
						de: 'Weitere Unterstützung',
					},
					content: [
						{
							title: 'Further Forward Foundation',
							logos: [SupportImg8],
						},
						{
							title: 'Wumderbar Together',
							logos: [SupportImg9],
						},
					],
				},
				{
					title: {
						en: 'Cooperation Partners',
						de: 'Kooperationspartner',
					},
					content: [
						{
							title: {
								en: 'Federal Agency for Civic Education (bpb)',
								de: 'Bundeszentrale für politische Bildung (bpb)',
							},
							logos: [SupportImg4],
						},
						{
							title: 'Jüdisches Museum Berlin',
							logos: [SupportImg5],
						},
						{
							title: 'MiQua. LVR-Jüdisches Museum im Archäologischen Quartier Köln',
							logos: [SupportImg6],
						},
					],
				},
			],
		},
		partners: {
			title: {
				en: 'Partner Organizations',
				de: 'Partnerorganisationen',
			},
			list: [
				'Arnold Schönberg Center, Wien',
				'Biblioteca Apostolica Vaticana',
				'Braunschweigisches Landesmuseum',
				'Deutsches Historisches Museum',
				'Die Geschichtsmuseen der Landeshauptstadt Erfurt',
				'Filmarchive Austria ',
				'Fritz Ascher Society',
				'Fritz Bauer Institut',
				'Georg-Speyer-Haus, Frankfurt a.M.',
				'Heinrich Heine Institut in Düsseldorf',
				'Hungarian Jewish Archives',
				'Institut für die Geschichte der deutschen Juden',
				'Israelitische Kultusgemeinde Amberg',
				'Jewish Museum Bratislava',
				'Jewish Museum Prague ',
				'Joods Historisch Museum (JHM) Amsterdam',
				'Jüdisches Museum Frankfurt',
				'Jüdisches Museum Hohenems',
				'Jüdisches Museum der Schweiz ',
				'Jüdisches Museum Wien',
				'Landesarchiv Baden-Wurttemberg',
				'Leibniz-Institut für Astrophysik Potsdam (AIP)',
				'Leibniz-Institut für jüdische Geschichte und Kultur - Simon Dubnow',
				'Le Musée Historique de la Ville de Strasbourg ',
				'Moses Mendelssohn Stiftung',
				'Musée de Cluny, Paris',
				'Museum Augusta Raurica, Augst',
				'Museum für Hamburgische Geschichte',
				'Museum für Verhütung und Schwangerschaftsabbruch (MUVS) in Wien',
				'Neue Gallery New York ',
				'Private Collectors ',
				'Rheinisches Landesmuseum Trier',
				'Staatsbibliothek zu Berlin, Musikabteilung mit Mendelssohn-Archiv',
				'State Museum at Majdanek ',
				'Stiftung Neue Synagoge Berlin – Centrum Judaicum',
				'Terezín Memorial',
				'The Imperial War Museum',
				'Transkulturelle Geschichte des Judentums Institut für Kulturwissenschaft, Humboldt-Universität zu Berlin',
				'United States Holocaust Memorial Museum (USHMM)',
				'Universitätsbibliothek Humboldt-Universität zu Berlin,',
				'Wetterau Museum',
				'Yad Vashem - The World Holocaust Remembrance Center',
				'Yeshiva University Museum',
				'Zentralrat der Juden in Deutschland',
			],
		},
		thanks: {
			title: {
				en: 'Special thanks to:',
				de: 'Ein besonderer Dank gilt',
			},
			list: [
				{
					title: {
						en: 'Authors of the Epoch Introduction Essays:',
						de: 'Den Autoren der Epochenüberblicke:',
					},
					list: [
						'Prof. Dr. Michael Brenner',
						'Prof. Debórah Dwork',
						'Prof. Atina Grossmann',
						'Dr. Thomas Otten',
						'Prof. Marsha Rozenblit',
						'Prof. Dr. Miriam Ruerup',
						'Prof. David Sorkin',
						'Prof. Magda Teter',
						'Dr. Christiane Twiehaus',
					],
					display: 'list',
				},
				{
					title: {
						en: 'Authors of Objects’ Essays:',
						de: 'Den Autoren der Objektessays:',
					},
					list: [
						'Dr. Irene Aue-Ben-David',
						'Dr. Susan Bachrach',
						'Mr. Johannes Beermann-Schön',
						'Sabine Bergler',
						'Dr. Ulrich Bollert',
						'Prof. Dr. Michael Brenner',
						'Dr. Tobias Brinkmann',
						'David Brown',
						'Dr. Verena Buser',
						'Dr. Ludmila Chládková',
						'Dr. Klaus Cussler',
						'Dr. Korana Deppmeyer',
						'Dr. Hans-Jürgen Derda',
						'Prof. Debórah Dwork',
						'Renate Evers',
						'Jo Frank',
						'Stefanie Fuchs',
						'Prof. Michah Gottlieb',
						'Dr. Rachel Greenblatt',
						'Dr. Ingeborg Grolle',
						'Prof. Atina Grossmann',
						'Dr. Felicitas Heimann- Jelinek',
						'Dr. Deborah Hertz',
						'Stefan Hofmann',
						'Dr. Melanie Holcomb',
						'Dr. Joanne Intrator',
						'Dr. Noah Isenberg',
						'Dr. David Jünger',
						'Dr. Debra Kaplan',
						'Prof. Marion Kaplan',
						'Prof. Sam Kassow',
						'Dr. Alexandra Klei',
						'Dr. Karen Körber',
						'Dr. Sabine Kößling',
						'Dr. Hanno Loewy',
						'Dr. Martina Luedicke',
						'Anette Mazur',
						'Dr. Michael Miller',
						'Dr. Therese Muxeneder',
						'Dr. Thomas Otten',
						'Dr. Derek Penslar',
						'Dr. Aubrey Pomerance',
						'George Prochnik',
						'Lilian Raselli',
						'Dr. Anika Reichwald',
						'Dr. Rebecca Rovit',
						'Prof. Dr. Miriam Ruerup',
						'Dr. Ismar Schorsch',
						'Dr. Ephraim Shoham-Steiner',
						'Dr. Anja Siegemund',
						'Dr. Lisa Silverman',
						'Dr. Hermann Simon',
						'Dr. Sybille Steinbacher',
						'Rachel Stern',
						'Dr. Maria Stürzebecher',
						'Dr. Aleksandra Szymula',
						'Prof. Magda Teter',
						'Dr. Wolfgang Treue',
						'Dr. Christiane Twiehaus',
						'Dr. William H. Weitzer',
						'Barbara Welker',
						'Peter Wortsman',
						'Dr. Moshe Zimmermann',
					],
					display: 'block',
				},
				{
					title: {
						en: 'The Shared History Project Team:',
						de: 'Das Shared History Projekt-Team:',
					},
					list: [
						'Dr. Miriam Bistrovic',
						'David Brown',
						'Sally Carroll',
						'Dr. Frank Mecklenburg',
						'Milena Rinck',
						'Sophie Rupp',
						'Dr. William H. Weitzer',
						'Dr. Magdalena Wrobel',
					],
					display: 'list',
				},
				{
					title: {
						en: 'Thanks to the entire LBI staff for their contributions, including:',
						de: 'Unser Dank gilt dem gesamten LBI Team:',
					},
					list: [
						'Chris Bentley',
						'Miriam Clayton',
						'Renate Evers',
						'Elizabeth Fedden',
						'Tracey Felder',
						'Karen Franklin',
						'Lauren Paustian',
						'Horst Pfahlert',
						'Michael Simonson',
						'Agata Sobczak',
						'Hermann Teifer',
						'Dianne Ritchey',
					],
					display: 'list',
				},
				{
					title: {
						en: 'Website',
						de: 'Website',
					},
					list: [
						'360 Design',
						'Samson Übersetzungen GmbH',
						'Barbara Ann Schmutzler – Translation',
					],
					display: 'list',
				},
			],
		},
	},
	searchPage: {
		searchButton: {
			en: 'Search',
			de: 'Suche',
		},
		searchInputPlaceholder: {
			en: 'Search',
			de: 'Suche',
		},
	},
	loadMoreButton: {
		en: 'Load More',
		de: 'Mehr laden',
	},
	virtualRoomSection: {
		title: {
			en: 'or visit the Virtual Room',
			de: 'oder besuchen Sie unsere virtuelle Ausstellung',
		},
		buttonTitle: {
			en: 'Enter',
			de: 'Zugang',
		},
		buttonUrl: '#',
		img: VisitImg,
	},
	weekObjectSection: {
		title: {
			en: 'This Week’s Object',
			de: 'Das Objekt dieser Woche',
		},
		buttonTitle: {
			en: 'View object',
			de: 'Objekt anzeigen',
		},
	},
	privacyPolicyPage: {
		en: {
			privacyPageHead: {
				title: 'Leo Baeck Institute Privacy Policy',
				descr: '<p>The Leo Baeck Institute (“LBI”) is committed to protecting your privacy, whether you are a user, visitor, and/or donor. This Privacy Policy explains what information we collect from you and why. By using our websites (collectively, “Site”), visiting an LBI location, or donating to us, you agree to this policy. You also agree to let us use your email and postal address to communicate with you about our programs, services, fundraising efforts, and more. In pursuit of our commitment to protecting your privacy, we have drawn upon industry best practices and national standards for privacy.</p>',
				date: 'Last updated: December 21, 2020',
				info: '<p>This Privacy Policy may change over time as we deem necessary. Such changes will be announced by indicating on the policy the date it was last updated and/or by placing a notice on our Site.</p>',
			},
			typesInformation: [
				{
					title: 'Types of Information We Collect',
					content: '<p>We collect information about you in three ways: directly from you, from automatically-collected network logs, and through cookies. We typically keep information only for so long as it is needed for the proper operation of LBI and in order to better deliver our services to you. We may retain some information in backup storage systems, hard copy form, or as required by law. We collect different types of information from you depending on your chosen level of engagement with our services and the information needed in order to provide you with access to those services.</p><p>Information that is collected about you may be de-identified and aggregated with information collected about other users, visitors or donors. This de-identified and aggregated information cannot be used to reasonably identify you. This information helps us to administer services, analyze usage, provide security and identify new users of our services. In addition, it helps us to improve your user experience.</p>',
				},
				{
					title: '1. Information You Provide to Us',
					content: '<p>When you send us an email or submit a web form on our Site, you might be sharing certain personally identifying information or sensitive information with us. Web forms are used for many purposes, such as submitting a reference question or making a financial donation. Depending on the context, the information we request in these cases might include your name, physical address, email address, phone number, payment information, and other similar information.</p><p>We are committed to keeping such information only as long as needed in order to provide our services. We offer you the opportunity to review and, when practical, to update, change or delete information you have provided us. You can do this by emailing us at lbaeck@lbi.cjh.org or by asking our staff to assist you by phone at 1-212-744-6400. Our information storage systems are configured in a way that helps us to protect information from accidental or malicious destruction. To that purpose, the information we collect is also saved in backup storage systems. Therefore, any update, change or deletion you make to your information or preferences may not immediately be reflected in all copies of the information we have and may not be removed from our backup storage systems until overwritten.</p>',
				},
				{
					title: '2. Information We Collect Automatically',
					content: '<p>When you use our Site, our computer servers automatically capture and save information electronically about your usage of our services. Examples of information that we may collect include:</p> <ul> <li>Your Internet Protocol (IP) address</li> <li>Your location</li> <li>Kind of web browser or electronic device that you use</li> <li>Date and time of your visit</li> <li>Website that you visited immediately before arriving at our Site</li> <li>Pages that you viewed on our Site/li> <li>Certain searches/queries that you conducted</li> </ul> <p>We use Google Analytics to gain a better understanding of how the Site is being used. To learn more about how Google uses data, visit https://policies.google.com/privacy/partners.</p> </div>',
				},
				{
					title: 'Cookies',
					content: '<p>This section explains how cookies are used when you visit our Site.</p> <p>A cookie is a small text file (often including a unique identifier), that is sent to a user’s browser from a website\'s computers and stored on a user’s computer\'s hard drive or on a tablet or mobile device. A cookie stores a small amount of data on your computer about your visit to the website.</p> <p>We place and access cookies on your computer; these cookies are known as “first-party cookies”. Cookies may also be placed and accessed by some of our third-party vendors, which are known as “third-party cookies” and are described below. Some cookies are "session cookies," which means that they are temporary cookies that are only stored on your device while you are visiting our Site. Other cookies are "persistent cookies", which means that they are stored on your device for a period of time after you leave our Site.</p> <p>TTo the extent any personal information is collected through first-party cookies, our Privacy Policy applies. Personal information collected through a third-party cookie is subject to the privacy policy of that third party, and not our Privacy Policy.</p> <p>You can choose whether to accept cookies by changing the settings in your browser. However, if you disable this function (or keep this function disabled, as set by default by certain browsers), your experience on the Site may be diminished and some features may not work as intended.</p>',
				},
				{
					title: 'Types of Cookies We Use',
					content: '<p><b>Essential cookies</b> enable you to navigate the Site and to use its services and features. Without these necessary cookies, the Site will not perform as smoothly for you as we would like it to, and we may not be able to provide certain services or features. This applies most significantly to the web forms on the Site, many of which require cookies in order to function.</p> <p><b>Analytics cookies</b> collect information about your use of the Site, and enable us to improve the way it works. For example, analytics cookies show us which are the most frequently visited pages on the Site and help us record any difficulties you have with the Site. Analytics cookies allow us to see the overall patterns of usage on the Site, rather than the usage of a single person. We use information from analytics cookies to analyze the Site traffic, but we do not examine this information for individually identifying information.</p>',
				},
				{
					title: 'Managing Cookies',
					content: '<p>You may refuse or accept cookies from the Site at any time by activating settings in your browser. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to use interactive features of our Site that depend on cookies. Information about the procedure to follow in order to enable or disable cookies can be found at:</p> <ul> <li>Chrome</li> <li>Firefox</li> <li>Microsoft Edge</li> <li>Safari</li> <li>Safari Mobile (iPhone and iPad)</li> </ul> <p> For more information about other commonly used browsers, please refer to <a href="#">http://www.allaboutcookies.org/manage-cookies/.</a> </p> <p>Please be aware that if cookies are disabled, not all features of the Site may operate as intended.</p> <p> To opt-out of participating in Google Analytics data follow the instructions at <a href="#">https://tools.google.com/dlpage/gaoptout</a>.</p>',
				},
			],
			informationBlock: [
				{
					title: 'Information Shared with Third Parties',
					content: '<p>We may share personal information with third parties who provide services to LBI, such as payment processors and email delivery services. When LBI shares your personal information and other collected information with third party service providers, we require that they use your information only for the purpose of providing services to us and that their terms are consistent with this Privacy Policy.</p>',
				},
				{
					title: 'Complying with Regulatory and Legal Obligations',
					content: '<p>LBI has a legitimate interest in complying with certain legal obligations and interests which, from time to time, may require the disclosure of your personal information. LBI will not disclose your personal information to legal authorities except where such disclosure is by lawful request, including to meet legitimate national security or law enforcement demands (including a subpoena, court order, or other lawful legal demand by a legal authority with lawful jurisdiction).</p>',
				},
				{
					title: 'Our Ongoing Commitment to Data Security',
					content: '<p>Security is important to us and we know it is important to you. LBI follows generally accepted standards to protect your personal information when processing, transferring, and storing your personal information by restricting your personal data when we do not need to access it and by keeping your personal data only as necessary to perform our legitimate work.</p>',
				},
				{
					title: 'Our Partner Organization',
					content: '<p>LBI is a partner organization of the Center for Jewish History (“CJH”). Given the interrelationship between the two organizations, it is possible that in the course of using LBI services, the Privacy Policy of CJH may also come into effect. For more information, please refer to the CJH Privacy Policy.</p>',
				},
				{
					title: 'Contact Us',
					content: '<p>If you have questions or concerns about our Privacy Policy, please contact us by any of the following methods:</p> <ul> <li>Email us at lbaeck@lbi.cjh.org</li> <li>Visit our Contact page</li> <li>Write to us at: Leo Baeck Institute, 15 West 16th Street, New York, NY 10011</li> <li>Call us at 1-212-744-6400</li> </ul>',
				},
			],
		},
		de: {
			privacyPageHead: {
				title: 'Leo Baeck Institute Datenschutzerklärung',
				descr: '<p>Das Leo Baeck Institut (“LBI”) verpflichtet sich, Ihre Privatsphäre zu schützen, egal, ob Sie ein Nutzer, ein Besucher und/oder ein Förderer sind. In dieser Datenschutzerklärung wird ausgeführt, welche Informationen wir von Ihnen erheben und zu welchem Zweck wir dies tun. Indem Sie unsere Webseite benutzen, einen der Standorte des LBI besuchen oder uns eine Spende zukommen lassen, willigen Sie in diese Datenschutzregelung ein. Außerdem gestatten Sie uns, Ihre E-Mail und Postadresse zu benutzen, um mit Ihnen über unsere Programme, Dienstleistungen, Fundraising-Bemühungen und anderes zu kommunizieren. Im Bestreben um Ihre Sicherheit stützen wir uns auf die optimalen Vorgehensweisen in der Industrie und nationale Standards für Datenschutz.</p>',
				date: 'Zuletzt aktualisiert: Dezember 21, 2020',
				info: '<p>Diese Datenschutzerklärung kann im Lauf der Zeit geändert werden, wenn wir es für nötig befinden. Änderungen dieser Art werden bekannt gemacht, indem wir auf dieser Seite das Datum der letzten Aktualisierung angeben und/oder indem wir eine entsprechende Nachricht auf unsere Webseite stellen.</p>',
			},
			typesInformation: [
				{
					title: 'Arten der von uns gesammelten Informationen',
					content: '<p>Wir erheben auf drei Arten Informationen über sie: direkt von Ihnen, durch automatisch erstellte Netzprotokolle und durch Cookies. Normalerweise bewahren wir die Informationen nur so lange auf, wie sie für den einwandfreien Betrieb des LBI und zur Verbesserung unserer Dienstleistungen benötigt werden. Ein Teil Ihrer Daten kann  in Datensicherungssystemen, in Papierform oder gemäß gesetzlicher Vorschriften von uns aufbewahrt werden. Wir erheben unterschiedliche Arten von Informationen über Sie, je nachdem, in welchem Maß Sie unsere Dienstleistungen in Anspruch nehmen und welche Informationen benötigt werden, um Ihnen Zugriff auf diese Dienstleistungen zu ermöglichen.</p><p>Über Sie erhobene Daten können anonymisiert und mit Daten anderer Nutzer, Besucher und Förderer kombiniert werden. Diese anonymisierten und kombinierten Informationen können nicht verwendet werden, um Sie zu identifizieren. Sie helfen uns, Dienstleistungen zu erbringen, die Nutzung der Webseite zu analysieren, für Sicherheit zu sorgen und neue Nutzer unserer Dienstleistungen zu identifizieren. </p>',
				},
				{
					title: '1. Daten, die Sie uns zur Verfügung stellen',
					content: '<p>Wenn Sie uns eine E-mail schicken oder durch unsere Webseite ein Webformular einreichen, kann es sein, dass Sie gewisse persönliche Daten oder sensible personenbezogene Informationen mit uns teilen. Webformulare werden zu vielen Zwecken benutzt, wie z.B. zum Einreichen einer Anfrage oder um eine Geldspende zu machen. Je nach Kontext können zu den von uns in solchen Fällen erbetenen Informationen Ihr Name, Ihre Wohnadresse, Ihre E-Mail-Adresse, Telefonnummer, Zahlungsinformationen u .ä. gehören.</p><p>Wir verpflichten uns, Informationen dieser Art nur so lange aufzubewahren, wie zur Ausführung der Dienstleistung erforderlich. Auch bieten wir Ihnen die Möglichkeit, Informationen, die Sie uns überlassen haben, zu überprüfen und falls möglich, zu aktualisieren, zu ändern oder zu löschen. Sie können dies tun, indem Sie uns eine E-Mail schicken (lbaeck@lbi.cjh.org) oder unsere Mitarbeiter telefonisch um Hilfe bitten (1-212-744-6400). Unsere Datenspeicherungssysteme sind so konfiguriert, dass sie uns helfen, Daten vor versehentlicher oder böswilliger Vernichtung zu schützen. Zu diesem Zweck werden die von uns erhobenen Daten auch in Datensicherungssystemen gespeichert. Daher ist es möglich, dass Änderungen, die Sie eventuell an Ihren Daten oder Einstellungen vornehmen, nicht sofort in allen Kopien sichtbar sind, und sie dürfen erst nach der Überschreibung aus unseren Datensicherungssystemen entfernt werden. </p>',
				},
				{
					title: '2. Automatisch erhobene Daten',
					content: '<p>Wenn Sie unsere Webseite benutzen, erfassen und speichern unsere Server automatisch elektronisch Informationen darüber, wie Sie unsere Dienstleistungen nutzen. Zu den Daten, die wir erheben können, gehören</p> <ul> <li>Ihre IP-Adresse</li> <li>Ihr Standort</li> <li>die Art des von Ihnen benutzten Webbrowsers oder Geräts</li> <li>Datum und Uhrzeit Ihres Besuchs</li> <li>die Webseite, die Sie unmittelbar vor Ihrer Ankunft auf unserer Seite besucht haben</li> <li>Seiten, die Sie auf unserer Webseite aufgerufen haben</li> <li>gewisse von Ihnen getätigte Suchen/Anfragen</li> </ul> <p>Wir benutzen Google Analytics, um besser zu verstehen, wie die Webseite genutzt wird. Um mehr darüber zu erfahren, wie Google Daten verwertet, besuchen Sie https://policies.google.com/privacy/partners.</p> </div>',
				},
				{
					title: 'Cookies',
					content: '<p>In diesem Abschnitt wird erklärt, wie Cookies eingesetzt werden, wenn Sie unsere Webseite besuchen.</p> <p>Ein Cookie ist eine kleine Textdatei (oft mit spezifischem Kennung), die von den Rechnern einer Webseite an den Browser des Nutzers übermittelt und auf der Festplatte des Rechners, des Tablets oder des mobilen Geräts des Nutzers gespeichert wird. Ein Cookie speichert auf Ihrem Rechner eine geringe Menge von Information über Ihren Besuch auf der Webseite.</p> <p>Wir platzieren Cookies auf Ihrem Rechner und greifen auf sie zu. Diese Art von Cookies nennt man „First-Party-Cookies“. Auch ein Teil unserer Drittanbieter kann Cookies ablegen und auf diese zugreifen. Diese Art von Cookies nennt man „Third-Party-Cookies“. Eine Beschreibung finden Sie weiter unten im Text. Bei manchen Cookies handelt es sich um „Sitzungscookies“, d.h., sie sind kurzzeitig aktiv und werden nur für die Dauer Ihres Besuches auf unserer Webseite auf ihrem Gerät gespeichert. Andere Cookies sind „Dauercookies“, d.h., Sie bleiben für eine gewisse Zeit nach Verlassen der Webseite auf Ihrem Gerät gespeichert.</p> <p>Bei persönlichen Daten, die durch First-Party-Cookies erhoben werden, gilt unsere Datenschutzerklärung. Persönliche Daten, die durch ein Third-Party-Cookie erhoben wurden, unterliegen der Datenschutzerklärung dieses Drittanbieters, nicht der unseren.</p> <p>Durch Änderung der Browsereinstellungen können Sie entscheiden, ob Sie Cookies akzeptieren möchten. Wenn Sie diese Funktion deaktivieren oder Sie deaktiviert lassen (entsprechend der standardmäßigen Einstellung mancher Browser), kann Ihre Erfahrung mit der Webseite beeinträchtigt werden und es ist möglich, dass manche Funktionen nicht bestimmungsgemäß abgerufen werden können.</p>',
				},
				{
					title: 'Von uns genutzte Arten von Cookies',
					content: '<p><b>Essenzielle Cookies</b> ermöglichen es Ihnen, auf der Webseite zu navigieren und ihre Dienste und Funktionen zu nutzen. Ohne diese essenziellen Cookies funktioniert die Webseite nicht so reibungslos für Sie, wie wir es gerne hätten, und gewisse Dienstleistungen und Funktionen können eventuell nicht ausgeführt werden. Dies gilt vor allem für Webformulare, deren Funktionieren in vielen Fällen von Cookies abhängig ist.</p> <p><b>Analytische Cookies</b> erheben Daten über Ihr Nutzerverhalten und ermöglichen es uns, die Funktionsweise der Webseite zu verbessern. Sie zeigen uns zum Beispiel, welche die am häufigsten besuchten Seiten der Webseite sind und helfen uns, eventuelle Schwierigkeiten bei der Benutzung der Seite zu erfassen. Analytische Cookies erlauben uns, nicht nur die Nutzung durch Einzelpersonen, sondern die allgemeinen Nutzungsmuster der Webseite zu beobachten. Wir verwenden Daten aus analytischen Cookies, um den Datenverkehr auf der Webseite zu analysieren, untersuchen diese Daten aber nicht auf persönlich identifizierbare Informationen.</p> ',
				},
				{
					title: 'Verwaltung von Cookies',
					content: '<p>Sie können jederzeit Cookies der Webseite annehmen oder ablehnen, indem Sie Ihre Browsereinstellungen entsprechend aktivieren. Die meisten Browser akzeptieren Cookies automatisch, aber in der Regel ist es möglich, die Browsereinstellungen zu modifizieren, um Cookies abzulehnen, wenn Sie dies bevorzugen. Wenn Sie sich entschließen, Cookies abzulehnen, ist es möglich, dass Sie die interaktiven Funktionen der Webseite, die von Cookies abhängig sind, nicht nutzen können. Informationen über das Aktivieren oder Deaktivieren von Cookies finden Sie hier:</p> <ul> <li>Chrome</li> <li>Firefox</li> <li>Microsoft Edge</li> <li>Safari</li> <li>Safari Mobile (iPhone and iPad)</li> </ul> <p> Für Auskünfte zu anderen häufig verwendeten Browsern sehen Sie bitte hier nach: http://allaboutcookies.org/manage-cookies/.</p> <p>Bitte nehmen Sie zur Kenntnis, dass die Deaktivierung von Cookies gewisse Funktionen der Webseite beeinträchtigen kann.</p> <p> Um sich von Google Analytics abzumelden, folgen Sie bitte den Anweisungen auf https://tools.google.com/dlpage/gaoptout.</p>',
				},
			],
			informationBlock: [
				{
					title: 'Weitergabe von Daten an Drittanbieter',
					content: '<p>Wir behalten uns vor, personenbezogene Daten an Drittanbieter weiterzugeben, die im Namen des LBI Dienstleistungen erbringen, wie z.B. Zahlungsabwickler und E-Mail-Versanddienste. Wenn das LBI Ihre personenbezogenen Daten und andere erhobene Informationen an Drittanbieter wie Servicedienstleister weitergibt, verlangen wir von diesen, Ihre Daten ausschließlich für Dienstleistungen an uns zu verwenden und nur, sofern ihre Bestimmungen mit dieser Datenschutzerklärung übereinstimmen.</p>',
				},
				{
					title: 'Erfüllung behördlicher und rechtlicher Verpflichtungen',
					content: '<p>Das LBI hat legitimes Interesse daran, gewissen Rechtsverpflichtungen und -ansprüchen Folge zu leisten, die mitunter die Preisgabe Ihrer personenbezogenen Daten erfordern können. Das LBI wird Ihre personenbezogenen Daten nicht an Rechtsbehörden weitergeben, es sei denn, es handelt sich um eine gesetzmäßige Aufforderung, einschließlich, um legitimen Erfordernissen der nationalen Sicherheit oder des Gesetzesvollzugs zu entsprechen (z.B. einer Vorlage unter Strafandrohung, einer gerichtlichen Verfügung oder einer anderen rechtmäßigen rechtlichen Anforderung durch eine Behörde innerhalb deren rechtmäßigen Zuständigkeitsbereichs).</p>',
				},
				{
					title: 'Unser kontinuierliches Bemühen um Datensicherheit',
					content: '<p>Sicherheit ist uns wichtig, und wir wissen, dass sie auch Ihnen wichtig ist. Das LBI folgt allgemein akzeptierten Standards, um Ihre personenbezogenen Informationen zu schützen, wenn wir sie bearbeiten, übertragen und speichern, indem wir Ihre personenbezogenen Daten einschränken, wenn wir nicht auf sie zurückgreifen müssen und sie nur aufbewahren, soweit sie zur Ausführung unserer legitimen Tätigkeiten notwendig sind.</p>',
				},
				{
					title: 'Unsere Partnerorganisation',
					content: '<p>Das LBI ist eine Partnerorganisation des Center for Jewish History (CJH). Aufgrund der gegenseitigen Beziehungen zwischen den beiden Organisationen ist es möglich, dass bei Nutzung der Dienste des LBI die Datenschutzerklärungen des CJH ebenfalls in Effekt treten. Weitere Informationen entnehmen Sie bitte der CJH Datenschutzerklärung.</p>',
				},
				{
					title: 'Treten Sie mit uns in Verbindung',
					content: '<p>Falls Sie Fragen oder Bedenken hinsichtlich unserer Datenschutzerklärung haben, setzen Sie sich bitte auf einem der folgenden Wege mit uns in Verbindung:</p> <ul> <li>Schicken Sie uns eine E-Mail an lbaeck@lbi.cjh.org</li> <li>Besuchen Sie unsere Kontaktseite</li> <li>Schreiben Sie uns: Leo Baeck Institute, 15 West 16th Street, New York, NY 10010</li> <li>Rufen Sie uns an: 1-212-744-6400</li> </ul>',
				},
			],
		},
	},
	themeSwitch: {
		en: 'Reading Mode',
		de: 'Lesemodus',
	},
	readEssaysButton: {
		en: 'Read Essays',
		de: 'Lesen Sie die Essays',
	},
	landingPage: {
		img1: ImgHero,
		img2: ImgDecor,
		buttonTitle: {
			en: 'Enter',
			de: 'Enter',
		},
	},
	signUpBannerSection: {
		signUpButton: {
			en: 'Sign Up',
			de: 'Anmelden',
		},
		signUpNote: {
			en: ' for additional access to all features.',
			de: ' für zusätzlichen Zugriff auf alle Funktionen.',
		},
		signUpOrTitle: {
			en: 'Or ',
			de: 'Oder ',
		},
	},
	byAuthorTitle: {
		en: 'By',
		de: 'von',
	},
	searchNoResultTitle: {
		en: 'No result',
		de: 'Kein Ergebnis',
	},
	dateMask: {
		en: 'mmm d, yyyy',
		de: 'd. mmmm yyyy',
	},
	datei18n: {
		en: {
			dayNames: [
				'Sun',
				'Mon',
				'Tue',
				'Wed',
				'Thu',
				'Fri',
				'Sat',
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
			],
			monthNames: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
		},
		de: {
			dayNames: [
				'Sonntag',
				'Montag',
				'Dienstag',
				'Mittwoch',
				'Donnerstag',
				'Freitag',
				'Samstag',
				'Sonntag',
				'Montag',
				'Dienstag',
				'Mittwoch',
				'Donnerstag',
				'Freitag',
				'Samstag',
			],
			monthNames: [
				'Jan.',
				'Feb.',
				'März',
				'Apr.',
				'Mai',
				'Juni',
				'July',
				'Aug.',
				'Sept.',
				'Okt.',
				'Nov.',
				'Dez.',
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'July',
				'August',
				'September',
				'Oktober',
				'November',
				'Dezember',
			],
			timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
		},
	},
};

export default APP_DATA;
