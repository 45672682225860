import { api } from 'utils/api';

const getAllEvents = async () => {
	const events = await api.get('/wp/v2/future-events');

	return events;
};

const getUpcomingEvents = async (page, load) => {
	const events = await api.get(
		`/wp/v2/upcoming-events?page=${page}&load=${load}`,
	);

	return events;
};

const getRecentEvents = async (page, load) => {
	const events = await api.get(
		`/wp/v2/recent-events?page=${page}&load=${load}`,
	);

	return events;
};

const getSingleEvent = async (id) => {
	const events = await api.get(`/wp/v2/future-events?${id}`);

	return events;
};

export {
	getAllEvents,
	getSingleEvent,
	getUpcomingEvents,
	getRecentEvents,
};
