export const EPOCHS = {
	en: [
		'4th Century to the Middle Ages',
		'Early Modern',
		'Absolutism',
		'19th Century to 1914',
		'1914 — 1938',
		'Holocaust',
		'Post-war (1945–Present)',
	],
	de: [
		'Römerzeit und Mittelalter',
		'Frühe Neuzeit',
		'Absolutismus',
		'Vom 19. Jahrhundert bis zum Ersten Weltkrieg',
		'Zwischenkriegszeit',
		'Der Holocaust',
		'Nachkriegszeit',
	],
};
