import React, { useContext, useState } from 'react';
import { setCookie, getCookie } from 'utils';
import { JsonDataContext } from 'context/jsonData';

const Cookies = () => {
	const COOKIE_EXPIRES_TIME = 86400;
	const fetchConsentCookies = () => {
		const consentCookie = getCookie('cookie_consent');
		return !(typeof consentCookie === 'undefined');
	};

	const [cookieConsent, setCookieConsent] = useState(fetchConsentCookies());
	const {
		showPopupByKey,
		langApp,
	} = useContext(JsonDataContext);

	const setCookies = (e) => {
		e.preventDefault();

		setCookie('cookie_consent', true, { expires: COOKIE_EXPIRES_TIME });
		setCookieConsent(true);
	};

	const handleOpenPopup = () => {
		showPopupByKey('cookies');
	};

	if (cookieConsent) {
		return null;
	}

	return (
		<div className="cookies cookiesBar">
			<div className="section_in flex_mod">
				<div className="cookies_text">
					<>
						{
							langApp === 'en' ? 'This website uses cookies to ensure you get the best experience on our site. Please accept cookies for optimal performance.' : 'Diese Webseite verwendet Cookies, um Ihnen das bestmögliche Surferlebnis zu gewährleisten. Bitte akzeptieren Sie die Cookies für ein optimales Ergebnis.'
						}
						&nbsp;
						<a href="/privacy-policy">{langApp === 'en' ? 'More info' : 'Weitere Informationen'}</a>
					</>
				</div>
				<div className="btn_wrap offset_side_mod">
					<a className="btn_v7 acceptCookies" href="#" onClick={setCookies}>{langApp === 'en' ? 'Yes, I accept' : 'Akzeptieren'}</a>
				</div>
				<a className="cookies_link" href="#" onClick={handleOpenPopup}>{langApp === 'en' ? 'Change settings' : 'Cookie-Einstellungen ändern'}</a>
			</div>
		</div>
	);
};

export default Cookies;
