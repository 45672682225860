import React, {
	useState,
	useContext,
	useRef,
	useEffect,
} from 'react';

import { Filters } from 'components/Filters';

import { Alert, useAlert } from 'components/Alert';

import { JsonDataContext } from 'context/jsonData';
import { Spinner } from 'components/Spinner';
import { SearchForm, SearchResultList } from 'components/Search';
import { LoadMoreButton } from 'components/Buttons';

const SearchPage = () => {
	const { langApp } = useContext(JsonDataContext);
	const [allSearchResult, setAllSearchResult] = useState(false);
	const [backupFilteredSearchResult, setBackupFilteredSearchResult] = useState(false);
	const [filteredSearchResult, setFilteredSearchResult] = useState(false);
	const [alert, hideAlert] = useAlert();
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [isSearchLoading, setSearchLoading] = useState(false);
	const [loadMoreBtn, showLoadMoreBtn] = useState(false);
	const [isLoadingMore, setLoadingMore] = useState(false);
	const [isSearchFilterApplied, setSearchFilterApplied] = useState(false);
	const [searchResultTotalPages, setSearchResultTotalPages] = useState(3);

	const numberOfPagesToShow = 3;
	const pageShownRef = useRef(1);

	const filterSearchResultByQuery = (resultData, searchQuery) => {
		const preparedSearchQuery = searchQuery.toLowerCase();

		const res = resultData.filter(({
			excerpt,
			title,
		}) => {
			const preparedTitleValue = title && title[langApp].toLowerCase();
			const preparedExcerptValue = excerpt && excerpt[langApp].toLowerCase();

			if (preparedTitleValue.includes(preparedSearchQuery) || preparedExcerptValue.includes(preparedSearchQuery)) {
				return true;
			}

			return false;
		});

		return res;
	};

	const showMoreGrid = () => {
		const preparedGrids = [];
		if (pageShownRef.current <= searchResultTotalPages) {
			for (let i = 0; i < allSearchResult.length; i += 1) {
				if (i < pageShownRef.current * numberOfPagesToShow) {
					preparedGrids.push(allSearchResult[i]);
				}
			}

			pageShownRef.current += 1;

			if (searchResultTotalPages > 2 && pageShownRef.current > searchResultTotalPages) {
				showLoadMoreBtn(false);
			}
		}

		return preparedGrids;
	};

	const handleLoadMore = () => {
		if (pageShownRef.current <= searchResultTotalPages) {
			const res = showMoreGrid();

			setFilteredSearchResult(res);
			setBackupFilteredSearchResult(res);
		}
	};

	useEffect(() => {
		if (allSearchResult) {
			const firstItems = showMoreGrid();

			setFilteredSearchResult(firstItems);
			setBackupFilteredSearchResult(firstItems);
		}
	}, [allSearchResult]);

	const handleSearchResult = (results, searchQuery) => {
		const filterResult = filterSearchResultByQuery(results, searchQuery);
		const totalAmountPage = filterResult.length >= 2 ? Math.round(filterResult.length / numberOfPagesToShow) : 1;

		if (totalAmountPage > 1) {
			showLoadMoreBtn(true);
		} else {
			showLoadMoreBtn(false);
		}

		if (pageShownRef.current > 1) {
			pageShownRef.current = 1;
		}

		setAllSearchResult(filterResult);
		setSearchResultTotalPages(totalAmountPage);
	};

	return (
		<>
			<section className="section">
				<div className="section_in">
					<div className="section_side width_v5_mod">
						<SearchForm
							isFormSubmitted={isFormSubmitted}
							isSearchLoading={isSearchLoading}
							handleSearchResult={handleSearchResult}
							setFilteredSearchResult={setFilteredSearchResult}
							setSearchLoading={setSearchLoading}
							setFormSubmitted={setFormSubmitted}
							setSearchResultTotalPages={setSearchResultTotalPages}
						/>
						{filteredSearchResult && (
							<div className="search_filters">
								<Filters
									allObjects={backupFilteredSearchResult}
									allFilteredObjects={filteredSearchResult}
									setAllFilteredObjects={setFilteredSearchResult}
									setPageFilterApplied={setSearchFilterApplied}
								/>
							</div>
						)}
						{alert.visible ? (
							<Alert
								alert={alert}
								hide={hideAlert}
							/>
						) : null}
						{!isSearchLoading ? (
							filteredSearchResult && filteredSearchResult.length ? (
								<SearchResultList
									result={filteredSearchResult}
								/>
							) : (
								filteredSearchResult && !filteredSearchResult.length && (
									<h2 className="section_title size_v2_mod center_lvl_mod">No result</h2>
								)
							)
						) : (
							isFormSubmitted && (
								<Spinner />
							)
						)}
						{!isSearchFilterApplied && filteredSearchResult.length && loadMoreBtn ? (
							<div className="search_result_btn">
								<LoadMoreButton
									isDisabled={isSearchLoading}
									isLoading={isLoadingMore}
									onClick={handleLoadMore}
								/>
							</div>
						) : null}
					</div>
				</div>
			</section>
		</>
	);
};

export default SearchPage;
