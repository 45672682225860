import React, {
	useContext,
	useState,
	useEffect,
	useRef,
} from 'react';
import Slider from 'react-slick';
import { CSSTransition } from 'react-transition-group';
import { JsonDataContext } from 'context/jsonData';
import { useAlert } from 'components/Alert';
import { Pannellum } from 'pannellum-react';

// components
import { ReactComponent as IconClose } from 'i/icons/close_2.svg';
import { SliderControls } from 'components/SliderControls';
import {
	getSingleObject,
	getSingleHistoricalObject,
	getAllFutureObjects,
} from 'api/object';
import ReactHtmlParser from 'react-html-parser';

import { ReactComponent as InfoIcon } from 'i/icons/info.svg';
import { ReactComponent as DocumentIcon } from 'i/icons/document_3.svg';
import { ReactComponent as ResizeIcon } from 'i/icons/resize.svg';

const PopupVirtual = ({ isOpen }) => {
	const {
		langApp,
		showPopupByKey,
		objectPopupData,
		appPopups,
	} = useContext(JsonDataContext);

	const objectSizeRullerEl = useRef(null);
	const object3dViewEl = useRef(null);
	const objectSizeRullerBtn = useRef(null);
	const objectResizeBtn = useRef(null);
	const dashboardSliderWrapEl = useRef(null);

	const [objectData, setObjectData] = useState(false);
	const [, setObjectsLoading] = useState(false);
	const [, setFetchError] = useState(false);
	const [iconInfoShown, setIconInfoShown] = useState(false);

	const [objectSizesArray, setObjectSizesArray] = useState([]);
	const [popupSliderSettings, setPopupSliderSettings] = useState({
		prevArrow: <SliderControls prevMod virtualMod />,
		nextArrow: <SliderControls nextMod virtualMod nextArrow />,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		adaptiveHeight: true,
		dots: true,
		dotsClass: 'slick-dots v2_mod',
		touchMove: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					adaptiveHeight: true,
				},
			},
		],
	});

	const [alert, showAlert, hideAlert] = useAlert();

	const handleClosePopup = () => {
		showPopupByKey();
	};

	const fetchObjectById = async () => {
		setFetchError(false);
		if (alert.visible) {
			hideAlert();
		}

		try {
			setObjectsLoading(true);
			let fetchRes;

			const {
				type,
				status,
				slug,
			} = objectPopupData;

			if (type === 'historical') {
				fetchRes = await getSingleHistoricalObject(slug);
				setObjectData(fetchRes && fetchRes.data && fetchRes.data.length ? fetchRes.data[0] : false);
			} else {
				if (status === 'future') {
					const futureObjectRes = await getAllFutureObjects();

					const futureObject = futureObjectRes.data.filter(({
						slug: objectSlug,
					}) => {
						if (objectSlug === slug) return true;
						return false;
					});

					// eslint-disable-next-line prefer-destructuring
					fetchRes = futureObject[0];
					setObjectData(fetchRes);
				} else {
					fetchRes = await getSingleObject(slug);
					setObjectData(fetchRes && fetchRes.data && fetchRes.data.length ? fetchRes.data[0] : false);
				}
			}

			setObjectsLoading(false);
		} catch (err) {
			setObjectsLoading(false);
			showAlert(err.message, 'danger');
			setFetchError(true);
		}
	};

	useEffect(() => {
		if (objectPopupData && appPopups.virtual.isOpen) {
			fetchObjectById();
		}
	}, [objectPopupData, appPopups]);

	useEffect(() => {
		if (objectData && objectData.acf && objectData.acf.link_3d && objectData.acf.dimensions) {
			setPopupSliderSettings({
				...popupSliderSettings,
				beforeChange: (current, next) => {
					if (next === 0 && objectData.acf.link_3d.length) {
						object3dViewEl.current.classList.remove('hidden_mod');
						objectSizeRullerBtn.current.classList.remove('hidden_mod');
						objectResizeBtn.current.classList.remove('hidden_mod');
					} else {
						if (next > 0 && objectData.acf.link_3d.length) {
							object3dViewEl.current.classList.add('hidden_mod');
							objectSizeRullerBtn.current.classList.add('hidden_mod');
							objectResizeBtn.current.classList.add('hidden_mod');

							if (objectSizeRullerEl.current.classList.contains('visible_mod')) {
								objectSizeRullerEl.current.classList.remove('visible_mod');
							}
						}
					}
				},
			});

			setObjectSizesArray(objectData.acf.dimensions[langApp].split(' x '));
		}
	}, [objectData, langApp]);

	function visibleSizeRuller(event) {
		objectSizeRullerEl.current.classList.toggle('visible_mod');
		event.currentTarget.classList.toggle('active_size_mod');
		return null;
	}

	function openFullscreen() {
		document.body.classList.toggle('fullscreen_mod');
		dashboardSliderWrapEl.current.classList.toggle('fullscreen_mod');
		return null;
	}

	const pathname = '';

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup popupVirtual">
				<div className="overlay v2_mod" />
				<div className="popup_in v4_mod bg_mod">
					<button className="popup_close v3_mod" type="button" onClick={() => handleClosePopup()}>
						{/* <div className="popup_close_text">Close</div> */}
						<IconClose className="icon icon-close_2 size_mod" />
					</button>
					<div className="popup_content full_mod dashboard_slider_w" ref={dashboardSliderWrapEl}>
						<div className="dashboard_slider_control_w">
							<div className="object_size_ruller" ref={objectSizeRullerEl}>
								{objectSizesArray && (
									<>
										{objectSizesArray[0] && (
											<div className="object_size_ruller_col v1_mod">
												<div className="object_size_ruller_title">{objectSizesArray[0]}</div>
											</div>
										)}
										{objectSizesArray[2] && (
											<div className="object_size_ruller_col v2_mod">
												<div className="object_size_ruller_title">{objectSizesArray[2]}</div>
											</div>
										)}
										{objectSizesArray[1] && (
											<div className="object_size_ruller_col v3_mod">
												<div className="object_size_ruller_title">{objectSizesArray[1]}</div>
											</div>
										)}
									</>
								)}
							</div>
							{objectData && objectData.acf && objectData.acf.link_3d && (
								<>
									<button className="dashboard_slider_icon info_mod" type="button" ref={object3dViewEl} onClick={() => setIconInfoShown(!iconInfoShown)}>
										<span className={iconInfoShown ? 'dashboard_slider_icon_title active_mod' : 'dashboard_slider_icon_title'}>Click & Drag to rotate the object</span>
										<InfoIcon className="icon icon-info size_mod" />
									</button>
									<button className="dashboard_slider_icon doc_mod" type="button" onClick={visibleSizeRuller} ref={objectSizeRullerBtn}>
										<span className="dashboard_slider_icon_title">Dimensions</span>
										<DocumentIcon className="icon icon-document_3 size_mod" />
									</button>
									<button className="dashboard_slider_icon resize_mod" type="button" ref={objectResizeBtn} onClick={openFullscreen}>
										<ResizeIcon className="icon icon-resize size_mod" />
									</button>
								</>
							)}
						</div>
						<Slider {...popupSliderSettings} className="virtual_slider">
							{objectData && objectData.acf && objectData.acf.link_3d && (
								<div className="dashboard_slider_item">
									<div className="dashboard_slider_img v1_mod">
										<iframe className="dashboard_slider_model" src={objectData.acf.link_3d} frameBorder="0" title="title" />
									</div>
									<div className="dashboard_slider_bottom">
										<div className="dashboard_slider_text">
											{objectData.acf.main_3d_caption && (
												<div className="dashboard_slider_title">{ReactHtmlParser(objectData.acf.main_3d_caption[langApp])}</div>
											)}
											{objectData.acf.second_3d_caption && (
												<div className="dashboard_slider_descr">{ReactHtmlParser(objectData.acf.second_3d_caption[langApp])}</div>
											)}
										</div>
									</div>
								</div>
							)}
							{
								objectData && objectData.slug && (objectData.slug.indexOf('the-old-synagogue-of-erfurt') >= 0) && (
									<div className="dashboard_slider_item">
										<div className="dashboard_slider_img v1_mod">
											<Pannellum
												width="100%"
												height="100%"
												image="https://i.imgur.com/s7ZKfyc.jpg"
												autoLoad
												onLoad={() => {}}
											/>
										</div>
										<div className="dashboard_slider_bottom">
											<div className="dashboard_slider_text">
												{objectData.acf.main_3d_caption && (
													<div className="dashboard_slider_title">{ReactHtmlParser(objectData.acf.main_3d_caption[langApp])}</div>
												)}
												{objectData.acf.second_3d_caption && (
													<div className="dashboard_slider_descr">{ReactHtmlParser(objectData.acf.second_3d_caption[langApp])}</div>
												)}
											</div>
										</div>
									</div>
								)
							}
							{
								objectData && objectData.slug && (objectData.slug.indexOf('hamburg-during-the-life-of-gluckel-of-hameln') >= 0) && (
									<div className="dashboard_slider_item">
										<div className="dashboard_slider_img v1_mod">
											<Pannellum
												width="100%"
												height="100%"
												image="https://i.imgur.com/IkMtuG8.jpg"
												autoLoad
												onLoad={() => {}}
											/>
										</div>
										<div className="dashboard_slider_bottom">
											<div className="dashboard_slider_text">
												{objectData.acf.main_3d_caption && (
													<div className="dashboard_slider_title">{ReactHtmlParser(objectData.acf.main_3d_caption[langApp])}</div>
												)}
												{objectData.acf.second_3d_caption && (
													<div className="dashboard_slider_descr">{ReactHtmlParser(objectData.acf.second_3d_caption[langApp])}</div>
												)}
											</div>
										</div>
									</div>
								)
							}
							{
								objectData && objectData.slug && (objectData.slug.indexOf('baroque-interior-of-the-hornburg-synagogue') >= 0) && (
									<div className="dashboard_slider_item">
										<div className="dashboard_slider_img v1_mod">
											<Pannellum
												width="100%"
												height="100%"
												image="https://i.imgur.com/IvowtIM.jpg"
												autoLoad
												onLoad={() => {}}
											/>
										</div>
										<div className="dashboard_slider_bottom">
											<div className="dashboard_slider_text">
												{objectData.acf.main_3d_caption && (
													<div className="dashboard_slider_title">{ReactHtmlParser(objectData.acf.main_3d_caption[langApp])}</div>
												)}
												{objectData.acf.second_3d_caption && (
													<div className="dashboard_slider_descr">{ReactHtmlParser(objectData.acf.second_3d_caption[langApp])}</div>
												)}
											</div>
										</div>
									</div>
								)
							}
							{
								objectData && objectData.slug && (objectData.slug.indexOf('the-einstein-tower') >= 0) && (
									<div className="dashboard_slider_item">
										<div className="dashboard_slider_img v1_mod">
											<Pannellum
												width="100%"
												height="100%"
												image="https://i.imgur.com/Nl9KCFU.jpg"
												autoLoad
												onLoad={() => {}}
											/>
										</div>
										<div className="dashboard_slider_bottom">
											<div className="dashboard_slider_text">
												{objectData.acf.main_3d_caption && (
													<div className="dashboard_slider_title">{ReactHtmlParser(objectData.acf.main_3d_caption[langApp])}</div>
												)}
												{objectData.acf.second_3d_caption && (
													<div className="dashboard_slider_descr">{ReactHtmlParser(objectData.acf.second_3d_caption[langApp])}</div>
												)}
											</div>
										</div>
									</div>
								)
							}
							{objectData && objectData.acf && objectData.acf.videos && objectData.acf.videos.map(({
								video_embed_code,
								video_caption,
							}, index) => {
								return (
									<div className="virtual_slider_item">
										{ReactHtmlParser(video_embed_code)}
										<div className="virtual_slider_bottom">
											<div
												className="virtual_slider_title"
												style={{
													marginRight: '10px',
												}}
											>
												{ReactHtmlParser(video_caption[langApp])}
											</div>
										</div>
									</div>
								);
							})}
							{objectData && objectData.acf && objectData.acf.photos && objectData.acf.photos.map(({
								photo,
								photo_caption,
							}) => {
								const { url: imageUrl, alt: imageAlt } = photo;
								const { main_caption, second_caption } = photo_caption;
								return (
									<div className="virtual_slider_item">
										<div className="virtual_slider_img_wrap">
											<img className="virtual_slider_img" src={imageUrl} alt={imageAlt} />
										</div>
										<div className="virtual_slider_bottom">
											<div
												className="virtual_slider_title"
												style={{
													marginRight: '10px',
												}}
											>
												{ReactHtmlParser(main_caption[langApp])}
											</div>
											<div className="virtual_slider_text">{ReactHtmlParser(second_caption[langApp])}</div>
										</div>
									</div>
								);
							})}
						</Slider>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default PopupVirtual;
