export const THEMES_ARRAY = {
	themesHeroArray: {
		buttonTitle: {
			en: 'Learn More',
			de: 'mehr erfahren',
		},
	},
	theThemesSection: {
		title: {
			en: 'The themes',
			de: 'Die Themen',
		},
		buttonTitle: {
			en: 'Learn More',
			de: 'mehr erfahren',
		},
	},
	acknowledgementSection: {
		title: {
			en: 'Acknowledgement:',
			de: 'Danksagungen:',
		},
	},
};
