import React, { useContext } from 'react';

import { JsonDataContext } from 'context/jsonData';

const LogoImg = () => {
	const { langApp } = useContext(JsonDataContext);

	return (
		<>
			{(langApp === 'en') ? (
				<svg className="header_logo_img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.58 63.28">
					<path fill="currentColor" d="M12.31,17a1.75,1.75,0,0,0,.57,1.28,2.33,2.33,0,0,0,1.48.4,2.57,2.57,0,0,0,.79-.1,1.41,1.41,0,0,0,.52-.26,1,1,0,0,0,.29-.4,1.35,1.35,0,0,0,.09-.5,1.12,1.12,0,0,0-.28-.7,1.84,1.84,0,0,0-1-.47L13.49,16a7.34,7.34,0,0,1-1.3-.35,3,3,0,0,1-.94-.55,2.12,2.12,0,0,1-.58-.84,3,3,0,0,1-.2-1.18,2.59,2.59,0,0,1,.27-1.2A2.45,2.45,0,0,1,11.5,11a3.87,3.87,0,0,1,1.17-.53,5.93,5.93,0,0,1,1.5-.18,5.37,5.37,0,0,1,1.74.24,3.17,3.17,0,0,1,1.12.64,2.48,2.48,0,0,1,.64.91,4.05,4.05,0,0,1,.27,1.09l-1.88.24a1.87,1.87,0,0,0-.57-1.14,2,2,0,0,0-1.28-.35,3.13,3.13,0,0,0-.83.09,1.67,1.67,0,0,0-.53.23.75.75,0,0,0-.28.33.84.84,0,0,0-.08.38,1,1,0,0,0,.3.81,2.35,2.35,0,0,0,1,.41l1.31.26a4.44,4.44,0,0,1,2.27,1,2.59,2.59,0,0,1,.73,2,2.72,2.72,0,0,1-.24,1.15,2.41,2.41,0,0,1-.69.93A3.28,3.28,0,0,1,16,20.1a5.31,5.31,0,0,1-1.63.22,4.71,4.71,0,0,1-3-.83A3.29,3.29,0,0,1,10.29,17Z" />
					<path fill="currentColor" d="M19.11,10.53H21V14a2.58,2.58,0,0,1,1-.83,2.46,2.46,0,0,1,1.15-.27,2.31,2.31,0,0,1,1.77.62,2.85,2.85,0,0,1,.57,2v4.61H23.56V15.72a1.36,1.36,0,0,0-.28-1,1,1,0,0,0-.75-.27,1.64,1.64,0,0,0-.62.11,1.69,1.69,0,0,0-.49.31,1.67,1.67,0,0,0-.31.44,1.28,1.28,0,0,0-.13.57v4.2H19.11Z" />
					<path fill="currentColor" d="M30.86,17.33a1.41,1.41,0,0,1-.16.71,1.46,1.46,0,0,1-.41.48,2,2,0,0,1-1.18.33,1,1,0,0,1-.66-.2.65.65,0,0,1-.25-.55,1.3,1.3,0,0,1,.07-.41.7.7,0,0,1,.26-.33,1.75,1.75,0,0,1,.56-.23A4.34,4.34,0,0,1,30,17l.83,0Zm.05,2.75h1.76V15.67a4.18,4.18,0,0,0-.15-1.16,2.23,2.23,0,0,0-.5-.87,2.2,2.2,0,0,0-.9-.56,4.17,4.17,0,0,0-1.36-.19,3.85,3.85,0,0,0-2.09.49,2.15,2.15,0,0,0-1,1.58l1.71.16c.1-.56.53-.84,1.27-.84a1.42,1.42,0,0,1,.83.24,1,1,0,0,1,.36.88v.35l-.9,0a11.79,11.79,0,0,0-1.32.13,3.91,3.91,0,0,0-1.17.37,2.29,2.29,0,0,0-.84.7,2.05,2.05,0,0,0-.32,1.18,2.07,2.07,0,0,0,.19.94,1.72,1.72,0,0,0,.5.65,2,2,0,0,0,.74.38,3.19,3.19,0,0,0,.89.12A2.64,2.64,0,0,0,30,20a3.78,3.78,0,0,0,.93-.76Z" />
					<path fill="currentColor" d="M34.08,13.1h1.81v1a2.49,2.49,0,0,1,.85-.83,2.15,2.15,0,0,1,1-.27l.44,0,.31.05-.23,1.7a3,3,0,0,0-.79-.08,2.32,2.32,0,0,0-.59.07,1.34,1.34,0,0,0-1,1.31v4H34.07Z" />
					<path fill="currentColor" d="M40.62,15.82a1.53,1.53,0,0,1,.12-.6,1.5,1.5,0,0,1,.3-.48,1.33,1.33,0,0,1,.47-.33,1.69,1.69,0,0,1,.66-.12,1.42,1.42,0,0,1,1.1.4,1.63,1.63,0,0,1,.35,1.13Zm2.91,2.08a1.16,1.16,0,0,1-1.31.94,1.47,1.47,0,0,1-1.2-.48,2.05,2.05,0,0,1-.43-1.31h4.84V16.3a3.64,3.64,0,0,0-.81-2.49,3,3,0,0,0-2.41-.92,4,4,0,0,0-1.42.24,2.93,2.93,0,0,0-1.1.74A3.28,3.28,0,0,0,39,15a4.46,4.46,0,0,0-.25,1.57,3.92,3.92,0,0,0,.87,2.72,3.35,3.35,0,0,0,2.62,1,3.43,3.43,0,0,0,2.2-.61,2.74,2.74,0,0,0,.94-1.79Z" />
					<path fill="currentColor" d="M51.37,16.75a3,3,0,0,1-.13,1,1.6,1.6,0,0,1-.39.65,1.62,1.62,0,0,1-1.1.38,1.56,1.56,0,0,1-.57-.11,1.09,1.09,0,0,1-.47-.38,1.69,1.69,0,0,1-.31-.68,3.68,3.68,0,0,1-.12-1.05,3.63,3.63,0,0,1,.12-1,1.91,1.91,0,0,1,.33-.67,1.3,1.3,0,0,1,.48-.37,1.43,1.43,0,0,1,.58-.12,2.22,2.22,0,0,1,.57.07,1.18,1.18,0,0,1,.53.32,1.48,1.48,0,0,1,.37.65,4.39,4.39,0,0,1,.11,1.07Zm0,3.33h1.82V10.53H51.36V13.8a2.34,2.34,0,0,0-1-.72,3,3,0,0,0-2.31.08,2.66,2.66,0,0,0-.95.75,3.66,3.66,0,0,0-.6,1.16,5.44,5.44,0,0,0-.2,1.5,4.6,4.6,0,0,0,.72,2.74,2.77,2.77,0,0,0,4.36-.15Z" />
					<path fill="currentColor" d="M10.9,25.75h2v3.69H48V25.75h2V35.3H48v-4H12.89v4h-2Z" />
					<path fill="currentColor" d="M51.5,28.31h1.88v7H51.5Zm0-2.56H53.4v1.68H51.47Z" />
					<path fill="currentColor" d="M56.13,33.08a1,1,0,0,0,.49.8,2.36,2.36,0,0,0,1,.19,2,2,0,0,0,.95-.17.56.56,0,0,0,.29-.54.67.67,0,0,0-.18-.47,1.17,1.17,0,0,0-.69-.28l-1-.16a4.21,4.21,0,0,1-1.89-.69,1.76,1.76,0,0,1-.63-1.48,1.94,1.94,0,0,1,.22-.91,2,2,0,0,1,.63-.68,2.86,2.86,0,0,1,1-.43,4.31,4.31,0,0,1,1.23-.16,5,5,0,0,1,1.39.17,2.58,2.58,0,0,1,.91.45,1.75,1.75,0,0,1,.54.67,3.1,3.1,0,0,1,.26.83l-1.67.21a1.22,1.22,0,0,0-.42-.71,1.65,1.65,0,0,0-1-.23,3.18,3.18,0,0,0-.62.06,1,1,0,0,0-.39.16.45.45,0,0,0-.2.21.46.46,0,0,0-.06.23.6.6,0,0,0,.2.49,2.16,2.16,0,0,0,.81.26l.89.14a7.25,7.25,0,0,1,1.21.31,2.46,2.46,0,0,1,.77.46,1.48,1.48,0,0,1,.4.65,2.86,2.86,0,0,1,.12.87,1.93,1.93,0,0,1-.75,1.59,3.74,3.74,0,0,1-2.31.59,5.82,5.82,0,0,1-1.2-.11,2.9,2.9,0,0,1-1-.4,2.1,2.1,0,0,1-.73-.75,2.3,2.3,0,0,1-.33-1.17Z" />
					<path fill="currentColor" d="M62.34,29.77h-1.1V28.31h1.1V26.46h1.87v1.85h1.6v1.46h-1.6V33a1,1,0,0,0,.23.74.85.85,0,0,0,.58.19,3,3,0,0,0,.77-.09l.26,1.38a5.36,5.36,0,0,1-.75.17,5.8,5.8,0,0,1-.7,0,2.33,2.33,0,0,1-1.71-.55A2.51,2.51,0,0,1,62.34,33Z" />
					<path fill="currentColor" d="M68.53,33.45a3,3,0,0,1-.39-1.66,2.87,2.87,0,0,1,.39-1.63,1.39,1.39,0,0,1,1.2-.56,1.37,1.37,0,0,1,1.2.56,2.87,2.87,0,0,1,.39,1.63,2.92,2.92,0,0,1-.39,1.65,1.56,1.56,0,0,1-2.4,0Zm1.2,2.06a4,4,0,0,0,1.46-.25,3.09,3.09,0,0,0,1.12-.74A3.43,3.43,0,0,0,73,33.35a4.73,4.73,0,0,0,.24-1.56A4.65,4.65,0,0,0,73,30.25a2.94,2.94,0,0,0-.7-1.16,2.86,2.86,0,0,0-1.12-.73,3.86,3.86,0,0,0-1.47-.26,4,4,0,0,0-1.46.26,2.94,2.94,0,0,0-1.11.73,3.08,3.08,0,0,0-.71,1.16,4.52,4.52,0,0,0-.25,1.54,3.85,3.85,0,0,0,.92,2.76A3.47,3.47,0,0,0,69.73,35.51Z" />
					<path fill="currentColor" d="M74.28,28.31H76.1v1a2.35,2.35,0,0,1,.84-.82,2.16,2.16,0,0,1,1-.27h.44l.3.05L78.51,30a3,3,0,0,0-.79-.08,2.27,2.27,0,0,0-.59.07,1.28,1.28,0,0,0-.51.28,1.35,1.35,0,0,0-.47,1v4H74.28Z" />
					<path fill="currentColor" d="M80.25,36.29a3,3,0,0,0,.79.11,1,1,0,0,0,.6-.18,1.53,1.53,0,0,0,.39-.64l.21-.59-2.9-6.68H81.5l1.63,4.59,1.53-4.59h2L83.88,35.5q-.24.6-.45,1a3.34,3.34,0,0,1-.49.75,1.73,1.73,0,0,1-.65.45,2.89,2.89,0,0,1-1,.14,5.32,5.32,0,0,1-.69,0,5.65,5.65,0,0,1-.71-.17Z" />
					<path fill="currentColor" d="M36.54,45.44V42.61H38a3.38,3.38,0,0,1,.86.09,1.35,1.35,0,0,1,.55.27.9.9,0,0,1,.28.43,2,2,0,0,1,.08.58,2.23,2.23,0,0,1-.08.6.9.9,0,0,1-.27.46,1.44,1.44,0,0,1-.54.3,3,3,0,0,1-.86.1Zm-2,5.07h2V47.1H38a5.19,5.19,0,0,0,1.77-.25,2.93,2.93,0,0,0,1.13-.69,2.45,2.45,0,0,0,.59-1,4.37,4.37,0,0,0,.16-1.21,3.91,3.91,0,0,0-.16-1.13,2.42,2.42,0,0,0-.59-1,3,3,0,0,0-1.14-.65A5.48,5.48,0,0,0,38,41H34.58Z" />
					<path fill="currentColor" d="M42.32,43.53h1.82v1a2.46,2.46,0,0,1,.85-.82,2.12,2.12,0,0,1,1-.27,3.18,3.18,0,0,1,.75.07l-.23,1.69a4.15,4.15,0,0,0-.79-.08,2.33,2.33,0,0,0-.59.08,1.35,1.35,0,0,0-1,1.3v4H42.32Z" />
					<path fill="currentColor" d="M50.5,49.23a1.36,1.36,0,0,1-1.21-.57A2.92,2.92,0,0,1,48.9,47a2.74,2.74,0,0,1,.4-1.63,1.54,1.54,0,0,1,2.39,0,3.54,3.54,0,0,1,0,3.27A1.33,1.33,0,0,1,50.5,49.23Zm0,1.5A3.94,3.94,0,0,0,52,50.47a3.07,3.07,0,0,0,1.12-.73,3.24,3.24,0,0,0,.71-1.18A4.62,4.62,0,0,0,54,47a4.71,4.71,0,0,0-.24-1.55,3.16,3.16,0,0,0-.71-1.16A3.07,3.07,0,0,0,52,43.57a3.89,3.89,0,0,0-1.47-.25,4,4,0,0,0-1.46.25,3.06,3.06,0,0,0-1.83,1.89A4.79,4.79,0,0,0,47,47a3.8,3.8,0,0,0,.93,2.75,3.44,3.44,0,0,0,2.61,1Z" />
					<path fill="currentColor" d="M54.9,42.65h1.92V41H54.89ZM53.06,52.76a4.45,4.45,0,0,0,.75.17,4,4,0,0,0,.68.06,2.31,2.31,0,0,0,1.72-.59,2.54,2.54,0,0,0,.58-1.86v-7H54.92v7c0,.36-.07.6-.22.71a.89.89,0,0,1-.58.17,3.4,3.4,0,0,1-.79-.11Z" />
					<path fill="currentColor" d="M59.86,46.24a1.88,1.88,0,0,1,.12-.59,1.37,1.37,0,0,1,.77-.81,1.69,1.69,0,0,1,.66-.12,1.37,1.37,0,0,1,1.1.4,1.62,1.62,0,0,1,.36,1.12Zm2.92,2.09a1.18,1.18,0,0,1-1.32.94,1.48,1.48,0,0,1-1.2-.48,2.05,2.05,0,0,1-.43-1.31h4.84v-.75a3.62,3.62,0,0,0-.81-2.49,3,3,0,0,0-2.41-.93,4,4,0,0,0-1.42.25,3,3,0,0,0-1.1.73,3.3,3.3,0,0,0-.72,1.17A4.56,4.56,0,0,0,58,47a3.87,3.87,0,0,0,.88,2.72,3.35,3.35,0,0,0,2.61,1,3.47,3.47,0,0,0,2.2-.61,2.74,2.74,0,0,0,.94-1.79Z" />
					<path fill="currentColor" d="M72.08,48a3.05,3.05,0,0,1-.86,2,3.09,3.09,0,0,1-2.27.75,3.93,3.93,0,0,1-1.52-.27,2.63,2.63,0,0,1-1.05-.75,3.23,3.23,0,0,1-.61-1.16,5.14,5.14,0,0,1-.2-1.51,4.91,4.91,0,0,1,.23-1.53,3.31,3.31,0,0,1,.68-1.17,3,3,0,0,1,1.08-.76A3.78,3.78,0,0,1,69,43.31a4.11,4.11,0,0,1,1.24.18,2.84,2.84,0,0,1,.89.51,2.16,2.16,0,0,1,.56.77,3.29,3.29,0,0,1,.27,1L70.1,46a1.79,1.79,0,0,0-.3-.86,1,1,0,0,0-.83-.33,1.29,1.29,0,0,0-.71.18,1.33,1.33,0,0,0-.45.49,2.51,2.51,0,0,0-.24.7,5.79,5.79,0,0,0-.06.83,5.73,5.73,0,0,0,.06.84,2.82,2.82,0,0,0,.23.71,1.39,1.39,0,0,0,.44.49,1.31,1.31,0,0,0,.71.18,1.15,1.15,0,0,0,.87-.32,1.46,1.46,0,0,0,.36-.93Z" />
					<path fill="currentColor" d="M73.58,45h-1.1V43.53h1.1V41.67h1.87v1.86h1.61V45H75.45v3.22a.93.93,0,0,0,.23.74.88.88,0,0,0,.59.2l.38,0a2.33,2.33,0,0,0,.38-.08l.27,1.38a4.34,4.34,0,0,1-.76.17,5.62,5.62,0,0,1-.7,0,2.35,2.35,0,0,1-1.7-.55,2.54,2.54,0,0,1-.56-1.85Z" />
					<path fill="currentColor" d="M55.26,17.52V15.67h18v1.85Z" />
					<path fill="currentColor" d="M78.94,47.94V46.1H96.59v1.84Z" />
					<path fill="currentColor" d="M1.62,41.55V1.61h82L103.7,21.73V61.67h-82ZM105.31,21.06,84.25,0H0V42.22L21.06,63.28h84.25Z" />
					<path fill="currentColor" d="M119.6,10.7l-1.74,1-.41-.7,2.24-1.4h.89v7.53h-1Z" />
					<path fill="currentColor" d="M124.23,17.17c.35-1.18.74-2.32,1.19-3.43a30.68,30.68,0,0,1,1.51-3.24h-4.07V9.64H128v.81a32.08,32.08,0,0,0-2.71,6.72Z" />
					<path fill="currentColor" d="M130.8,16.31a1.58,1.58,0,0,1-.54-.61,3.37,3.37,0,0,1-.3-1,9.5,9.5,0,0,1-.09-1.31A8.7,8.7,0,0,1,130,12.1a3.54,3.54,0,0,1,.3-1,1.46,1.46,0,0,1,.54-.61,1.73,1.73,0,0,1,1.65,0,1.51,1.51,0,0,1,.53.61,3.23,3.23,0,0,1,.3,1,8.7,8.7,0,0,1,.09,1.31,8.51,8.51,0,0,1-.09,1.31,3.23,3.23,0,0,1-.3,1,1.58,1.58,0,0,1-.53.61,1.73,1.73,0,0,1-1.65,0Zm.8,1.06a2.8,2.8,0,0,0,1.18-.24,2.14,2.14,0,0,0,.87-.72,3.5,3.5,0,0,0,.55-1.23,7.54,7.54,0,0,0,.18-1.77,7.66,7.66,0,0,0-.18-1.77,3.69,3.69,0,0,0-.54-1.25,2.22,2.22,0,0,0-.86-.72,3,3,0,0,0-2.31,0,2.18,2.18,0,0,0-.87.72,3.71,3.71,0,0,0-.57,1.25,7.11,7.11,0,0,0-.19,1.77,5,5,0,0,0,.72,3A2.4,2.4,0,0,0,131.6,17.37Z" />
					<path fill="currentColor" d="M136.81,16.31a1.58,1.58,0,0,1-.54-.61,3.69,3.69,0,0,1-.29-1,7.15,7.15,0,0,1-.09-1.31A8.7,8.7,0,0,1,136,12.1a3.55,3.55,0,0,1,.29-1,1.61,1.61,0,0,1,.54-.61,1.55,1.55,0,0,1,.83-.21,1.49,1.49,0,0,1,.82.21,1.53,1.53,0,0,1,.54.61,3.55,3.55,0,0,1,.29,1,8.7,8.7,0,0,1,.09,1.31,8.51,8.51,0,0,1-.09,1.31,3.55,3.55,0,0,1-.29,1,1.6,1.6,0,0,1-.54.61,1.49,1.49,0,0,1-.82.21,1.55,1.55,0,0,1-.83-.21Zm.81,1.06a2.72,2.72,0,0,0,1.17-.24,2.1,2.1,0,0,0,.88-.72,3.32,3.32,0,0,0,.54-1.23,7,7,0,0,0,.19-1.77,7.11,7.11,0,0,0-.19-1.77,3.5,3.5,0,0,0-.54-1.25,2.08,2.08,0,0,0-.86-.72,3,3,0,0,0-2.3,0,2.28,2.28,0,0,0-.88.72,3.7,3.7,0,0,0-.56,1.25,7.07,7.07,0,0,0-.2,1.77,5,5,0,0,0,.73,3,2.39,2.39,0,0,0,2,1Z" />
					<path fill="currentColor" d="M145.28,13.87l-2.71-4.48h1.15l2.06,3.6,2.12-3.6h1l-2.66,4.48v3.3h-1Z" />
					<path fill="currentColor" d="M148.83,13.88a2.21,2.21,0,0,1,.5-1.33,1.53,1.53,0,0,1,1.17-.46,1.7,1.7,0,0,1,.71.14,1.15,1.15,0,0,1,.48.36,1.62,1.62,0,0,1,.27.56,2.75,2.75,0,0,1,.09.73ZM152,15.59a1.39,1.39,0,0,1-.44.73,1.64,1.64,0,0,1-1,.26,1.7,1.7,0,0,1-.73-.15,1.54,1.54,0,0,1-.53-.42,1.71,1.71,0,0,1-.32-.63,2.66,2.66,0,0,1-.12-.79H153v-.48a3.8,3.8,0,0,0-.15-1.1,2.41,2.41,0,0,0-.45-.87,2.07,2.07,0,0,0-.77-.57,3,3,0,0,0-2.19,0,2.35,2.35,0,0,0-.84.58,2.52,2.52,0,0,0-.55.95,3.75,3.75,0,0,0-.2,1.29,3.19,3.19,0,0,0,.69,2.18,2.48,2.48,0,0,0,2,.78,2.62,2.62,0,0,0,1.67-.47,2,2,0,0,0,.73-1.29Z" />
					<path fill="currentColor" d="M157.3,15a1.46,1.46,0,0,1-.47,1.14,1.94,1.94,0,0,1-1.31.41,1.16,1.16,0,0,1-.84-.26.93.93,0,0,1-.26-.68,1.15,1.15,0,0,1,.1-.5.73.73,0,0,1,.31-.36,1.84,1.84,0,0,1,.61-.24,6,6,0,0,1,.95-.11l.91,0Zm0,2.19h.92V13.51a3.5,3.5,0,0,0-.12-.91,1.58,1.58,0,0,0-.37-.67,1.73,1.73,0,0,0-.68-.42,3.11,3.11,0,0,0-1-.15,2.94,2.94,0,0,0-1.57.36,1.58,1.58,0,0,0-.73,1.13l.86.09a1,1,0,0,1,.41-.61,1.72,1.72,0,0,1,1-.23,1.36,1.36,0,0,1,1,.32,1.47,1.47,0,0,1,.3,1v.22l-1,0a9.07,9.07,0,0,0-1.06.1,3.21,3.21,0,0,0-.92.3,1.62,1.62,0,0,0-.65.57,1.65,1.65,0,0,0-.25.93,1.84,1.84,0,0,0,.14.74,1.43,1.43,0,0,0,.4.53,1.78,1.78,0,0,0,.61.32,2.78,2.78,0,0,0,.78.1,2.3,2.3,0,0,0,1.17-.27,2.69,2.69,0,0,0,.77-.63Z" />
					<path fill="currentColor" d="M159.24,11.54h.94v.87a2.3,2.3,0,0,1,.74-.72,1.7,1.7,0,0,1,.9-.25,2.35,2.35,0,0,1,.5,0l-.12.88-.21,0a1.72,1.72,0,0,0-.78.07,1.52,1.52,0,0,0-.51.31,1.75,1.75,0,0,0-.38.48,1.47,1.47,0,0,0-.13.64v3.34h-.95Z" />
					<path fill="currentColor" d="M163.29,15.53a1.14,1.14,0,0,0,.5.82,2.1,2.1,0,0,0,1.09.23,1.65,1.65,0,0,0,1-.24.81.81,0,0,0,.3-.65,1.06,1.06,0,0,0,0-.31.51.51,0,0,0-.14-.26.79.79,0,0,0-.31-.2,2.15,2.15,0,0,0-.51-.15l-.72-.11a5.26,5.26,0,0,1-.84-.19,2.07,2.07,0,0,1-.61-.31,1.14,1.14,0,0,1-.37-.47,1.54,1.54,0,0,1-.13-.67,1.48,1.48,0,0,1,.17-.7,1.65,1.65,0,0,1,.47-.52,2.11,2.11,0,0,1,.71-.32,3.14,3.14,0,0,1,.89-.12,3.63,3.63,0,0,1,1,.12,1.83,1.83,0,0,1,.67.33,1.47,1.47,0,0,1,.41.49,3.89,3.89,0,0,1,.22.6l-.87.14a2,2,0,0,0-.17-.4.73.73,0,0,0-.26-.29,1.18,1.18,0,0,0-.4-.17,1.91,1.91,0,0,0-.58-.06,2.52,2.52,0,0,0-.66.07,1,1,0,0,0-.41.19.64.64,0,0,0-.23.26.69.69,0,0,0-.06.31,1,1,0,0,0,0,.31.45.45,0,0,0,.16.24,1,1,0,0,0,.33.18,2.68,2.68,0,0,0,.53.14l.82.13a2.69,2.69,0,0,1,1.42.56,1.53,1.53,0,0,1,.43,1.18,1.45,1.45,0,0,1-.14.66,1.51,1.51,0,0,1-.42.52,2.05,2.05,0,0,1-.71.35,3.74,3.74,0,0,1-1,.13,4.87,4.87,0,0,1-1-.1,2.5,2.5,0,0,1-.78-.31,1.61,1.61,0,0,1-.54-.56,1.81,1.81,0,0,1-.24-.85Z" />
					<path fill="currentColor" d="M171.68,16.4a1.46,1.46,0,0,1-.53-.46,2,2,0,0,1-.3-.7,3.33,3.33,0,0,1-.1-.89,3.51,3.51,0,0,1,.1-.89,1.68,1.68,0,0,1,.31-.69,1.46,1.46,0,0,1,.53-.46,1.67,1.67,0,0,1,.78-.16,1.7,1.7,0,0,1,.78.16,1.42,1.42,0,0,1,.54.46,1.82,1.82,0,0,1,.31.69,3.49,3.49,0,0,1,.1.88,3.59,3.59,0,0,1-.1.9,1.84,1.84,0,0,1-.31.7,1.42,1.42,0,0,1-.54.46,1.73,1.73,0,0,1-.78.16,1.67,1.67,0,0,1-.78-.16Zm.78,1a2.79,2.79,0,0,0,1.14-.22,2.34,2.34,0,0,0,.87-.6,2.54,2.54,0,0,0,.54-1,3.77,3.77,0,0,0,.19-1.25A3.88,3.88,0,0,0,175,13.1a2.6,2.6,0,0,0-.54-.94,2.5,2.5,0,0,0-.85-.59,3.2,3.2,0,0,0-1.14-.21,2.78,2.78,0,0,0-1.13.22,2.35,2.35,0,0,0-.86.6,2.78,2.78,0,0,0-.56,1,3.71,3.71,0,0,0-.2,1.24,4,4,0,0,0,.19,1.24,2.37,2.37,0,0,0,1.38,1.53,3,3,0,0,0,1.15.21Z" />
					<path fill="currentColor" d="M176.38,12.29h-.88v-.75h.88v-.49a2.07,2.07,0,0,1,.14-.79,1.58,1.58,0,0,1,.37-.54,1.53,1.53,0,0,1,.54-.31,2.07,2.07,0,0,1,.66-.1,3.7,3.7,0,0,1,.48,0,2.34,2.34,0,0,1,.48.11l-.15.74a1.3,1.3,0,0,0-.33-.07,1.67,1.67,0,0,0-.31,0,1,1,0,0,0-.66.2.88.88,0,0,0-.27.74v.51h1.43v.75h-1.43v4.88h-1Z" />
					<path fill="currentColor" d="M117.89,27.12a2.66,2.66,0,0,0,.16.66,1.4,1.4,0,0,0,.31.53,1.34,1.34,0,0,0,.51.34,2.13,2.13,0,0,0,.77.13,1.48,1.48,0,0,0,1.29-.52,2.41,2.41,0,0,0,.37-1.45V21.67h1v5.22A2.78,2.78,0,0,1,121.6,29a2.66,2.66,0,0,1-1.94.69,2.94,2.94,0,0,1-1.94-.61,2.74,2.74,0,0,1-.84-1.92Z" />
					<path fill="currentColor" d="M124.18,26.16a2.21,2.21,0,0,1,.5-1.33,1.49,1.49,0,0,1,1.17-.46,1.87,1.87,0,0,1,.71.13,1.35,1.35,0,0,1,.48.37,1.62,1.62,0,0,1,.27.56,2.75,2.75,0,0,1,.09.73Zm3.16,1.71a1.39,1.39,0,0,1-.44.73,1.71,1.71,0,0,1-1,.26,1.67,1.67,0,0,1-.73-.15,1.46,1.46,0,0,1-.53-.42,1.9,1.9,0,0,1-.33-.63,3,3,0,0,1-.11-.79h4.19v-.48a3.8,3.8,0,0,0-.15-1.1,2.41,2.41,0,0,0-.45-.87,2.07,2.07,0,0,0-.77-.57,2.7,2.7,0,0,0-1.12-.21,2.87,2.87,0,0,0-1.07.2,2.25,2.25,0,0,0-.84.59,2.53,2.53,0,0,0-.55.95,3.93,3.93,0,0,0-.2,1.29,3.19,3.19,0,0,0,.69,2.18,2.48,2.48,0,0,0,2,.78,2.62,2.62,0,0,0,1.67-.47,2.07,2.07,0,0,0,.73-1.29Z" />
					<path fill="currentColor" d="M128.41,23.82h1l1.13,4.49,1.3-4.46h1l1.27,4.46,1.12-4.49h1l-1.64,5.63h-1L132.31,25,131,29.45h-1Z" />
					<path fill="currentColor" d="M136.63,23.82h1v5.63h-1Zm0-2.15h1v1.1h-1Z" />
					<path fill="currentColor" d="M139.23,27.81a1.11,1.11,0,0,0,.5.82,2.17,2.17,0,0,0,1.08.23,1.66,1.66,0,0,0,1-.24.81.81,0,0,0,.3-.65,1.06,1.06,0,0,0,0-.31.46.46,0,0,0-.15-.26.75.75,0,0,0-.3-.2,2.29,2.29,0,0,0-.51-.15l-.72-.11a4.66,4.66,0,0,1-.84-.19,2.19,2.19,0,0,1-.61-.31,1.34,1.34,0,0,1-.38-.47,1.53,1.53,0,0,1-.12-.67,1.35,1.35,0,0,1,.17-.7,1.55,1.55,0,0,1,.47-.52,2.14,2.14,0,0,1,.7-.32,3.19,3.19,0,0,1,.89-.12,3.66,3.66,0,0,1,1,.12,1.91,1.91,0,0,1,.67.33,1.33,1.33,0,0,1,.4.49,2.54,2.54,0,0,1,.22.6l-.86.14a2,2,0,0,0-.17-.4.92.92,0,0,0-.26-.29,1.59,1.59,0,0,0-.4-.18,2.88,2.88,0,0,0-.59-.06,2.4,2.4,0,0,0-.65.08,1.16,1.16,0,0,0-.42.19.7.7,0,0,0-.22.26.87.87,0,0,0-.07.31,1.07,1.07,0,0,0,0,.32.41.41,0,0,0,.16.23,1,1,0,0,0,.33.18,2.41,2.41,0,0,0,.53.13l.82.13a2.73,2.73,0,0,1,1.42.57A1.53,1.53,0,0,1,143.1,28a1.59,1.59,0,0,1-.14.66,1.54,1.54,0,0,1-.43.52,2,2,0,0,1-.71.35,3.64,3.64,0,0,1-1,.13,4.69,4.69,0,0,1-1-.1,2.65,2.65,0,0,1-.78-.31,1.64,1.64,0,0,1-.54-.57,1.81,1.81,0,0,1-.24-.85Z" />
					<path fill="currentColor" d="M143.87,21.67h.95v3a2.62,2.62,0,0,1,.91-.78,2.38,2.38,0,0,1,1.05-.24,1.82,1.82,0,0,1,1.43.53,2.29,2.29,0,0,1,.46,1.53v3.75h-1V25.89a1.7,1.7,0,0,0-.28-1.11,1.08,1.08,0,0,0-.86-.32,1.94,1.94,0,0,0-.63.11,1.43,1.43,0,0,0-.56.35,1.83,1.83,0,0,0-.42.5,1.55,1.55,0,0,0-.14.7v3.33h-.95Z" />
					<path fill="currentColor" d="M151.77,21.67h1v6.89h4l-.15.89h-4.89Z" />
					<path fill="currentColor" d="M157.35,23.82h1v5.63h-1Zm0-2.15h1v1.1h-1Z" />
					<path fill="currentColor" d="M159.69,24.57h-.89v-.75h.89v-.49a2.08,2.08,0,0,1,.13-.79,1.52,1.52,0,0,1,.37-.54,1.43,1.43,0,0,1,.55-.31,2,2,0,0,1,.66-.11,3.87,3.87,0,0,1,.48,0,3.17,3.17,0,0,1,.48.1l-.16.75a2,2,0,0,0-.33-.08h-.31a1.14,1.14,0,0,0-.66.2.86.86,0,0,0-.27.74v.51h1.43v.75h-1.43v4.88h-.94Z" />
					<path fill="currentColor" d="M163.13,26.16a2.21,2.21,0,0,1,.5-1.33,1.47,1.47,0,0,1,1.17-.46,1.82,1.82,0,0,1,.7.13,1.19,1.19,0,0,1,.48.37,1.48,1.48,0,0,1,.28.56,2.77,2.77,0,0,1,.08.73Zm3.16,1.71a1.34,1.34,0,0,1-.45.73,1.65,1.65,0,0,1-1,.26,1.55,1.55,0,0,1-.73-.15,1.46,1.46,0,0,1-.53-.42,1.9,1.9,0,0,1-.33-.63,2.36,2.36,0,0,1-.11-.79h4.18v-.48a4.19,4.19,0,0,0-.14-1.1,2.58,2.58,0,0,0-.45-.87,2.07,2.07,0,0,0-.77-.57,2.73,2.73,0,0,0-1.12-.21,2.87,2.87,0,0,0-1.07.2,2.25,2.25,0,0,0-.84.59,2.53,2.53,0,0,0-.55.95,3.93,3.93,0,0,0-.2,1.29,3.19,3.19,0,0,0,.69,2.18,2.47,2.47,0,0,0,2,.78,2.63,2.63,0,0,0,1.67-.47,2.07,2.07,0,0,0,.73-1.29Z" />
					<path fill="currentColor" d="M170.13,23.82h.94v5.63h-.94Zm0-2.15h1v1.1h-1Z" />
					<path fill="currentColor" d="M172.12,23.82h.94v.86a2.51,2.51,0,0,1,.91-.8,2.45,2.45,0,0,1,1.06-.24,1.8,1.8,0,0,1,1.42.53,2.24,2.24,0,0,1,.46,1.53v3.75H176V25.89a1.7,1.7,0,0,0-.29-1.11,1.12,1.12,0,0,0-.87-.32,2,2,0,0,0-.63.11,1.4,1.4,0,0,0-.55.35,1.72,1.72,0,0,0-.42.5,1.55,1.55,0,0,0-.14.7v3.33h-.95Z" />
					<path fill="currentColor" d="M120.86,37.73h2.91v3.12a3.37,3.37,0,0,1-.65.47,4.49,4.49,0,0,1-1.54.54,5.52,5.52,0,0,1-.8.07,4.09,4.09,0,0,1-1.53-.28,3.11,3.11,0,0,1-1.13-.8,3.48,3.48,0,0,1-.7-1.28,5.59,5.59,0,0,1-.24-1.72,5.22,5.22,0,0,1,.26-1.74,3.67,3.67,0,0,1,.72-1.29,3,3,0,0,1,1.11-.79,3.6,3.6,0,0,1,1.4-.27,4.08,4.08,0,0,1,1.16.15,2.64,2.64,0,0,1,.85.45,2.54,2.54,0,0,1,.59.71,3.52,3.52,0,0,1,.35.91l-1,.16a3.32,3.32,0,0,0-.24-.62,1.59,1.59,0,0,0-.94-.8,3.09,3.09,0,0,0-.8-.11,2.31,2.31,0,0,0-1.07.24,2.09,2.09,0,0,0-.76.66,3.14,3.14,0,0,0-.46,1,4.85,4.85,0,0,0-.16,1.31,5.3,5.3,0,0,0,.15,1.31,2.84,2.84,0,0,0,.48,1,2.14,2.14,0,0,0,.8.66,2.65,2.65,0,0,0,1.13.23,4,4,0,0,0,1.11-.15,2.84,2.84,0,0,0,.93-.44V38.6h-2Z" />
					<path fill="currentColor" d="M125.48,38.44a2.18,2.18,0,0,1,.49-1.33,1.5,1.5,0,0,1,1.18-.46,1.82,1.82,0,0,1,.7.13,1.19,1.19,0,0,1,.48.37,1.41,1.41,0,0,1,.28.56,3.26,3.26,0,0,1,.08.73Zm3.16,1.71a1.28,1.28,0,0,1-.45.72,1.61,1.61,0,0,1-1,.27,1.67,1.67,0,0,1-.73-.15,1.46,1.46,0,0,1-.53-.42,1.9,1.9,0,0,1-.33-.63,2.36,2.36,0,0,1-.11-.79h4.18v-.48a3.8,3.8,0,0,0-.15-1.1,2.39,2.39,0,0,0-.44-.87,2.07,2.07,0,0,0-.77-.57,2.73,2.73,0,0,0-1.12-.21,2.87,2.87,0,0,0-1.07.2,2.41,2.41,0,0,0-.85.59,2.77,2.77,0,0,0-.55.94,4,4,0,0,0-.19,1.3,3.23,3.23,0,0,0,.68,2.18,2.5,2.5,0,0,0,2,.78,2.63,2.63,0,0,0,1.67-.47,2.07,2.07,0,0,0,.73-1.29Z" />
					<path fill="currentColor" d="M130.46,36.1h.94V37a2.41,2.41,0,0,1,.74-.72A1.7,1.7,0,0,1,133,36a2.35,2.35,0,0,1,.5,0l-.12.88-.21,0a1.52,1.52,0,0,0-1.29.38,1.75,1.75,0,0,0-.38.48,1.44,1.44,0,0,0-.13.64v3.34h-.95Z" />
					<path fill="currentColor" d="M134,36.1H135V37a2.56,2.56,0,0,1,.84-.78,1.91,1.91,0,0,1,.94-.26,1.64,1.64,0,0,1,1,.28,1.61,1.61,0,0,1,.58.78,2.59,2.59,0,0,1,.87-.79,2.12,2.12,0,0,1,1-.27,1.73,1.73,0,0,1,1.29.46,2,2,0,0,1,.45,1.45v3.9H141V38.05a2.31,2.31,0,0,0-.07-.64.83.83,0,0,0-.19-.4.66.66,0,0,0-.31-.21,1.52,1.52,0,0,0-.42-.06,1.63,1.63,0,0,0-.52.09,1.52,1.52,0,0,0-.9.75,1.13,1.13,0,0,0-.14.6v3.55h-.95V38.06a1.58,1.58,0,0,0-.25-1,1,1,0,0,0-.74-.29,1.51,1.51,0,0,0-1.05.46,2.25,2.25,0,0,0-.35.45,1.37,1.37,0,0,0-.17.72v3.36h-1Z" />
					<path fill="currentColor" d="M146.45,39.54a1.43,1.43,0,0,1-.47,1.13,1.89,1.89,0,0,1-1.31.42,1.18,1.18,0,0,1-.85-.26.92.92,0,0,1-.25-.68,1.31,1.31,0,0,1,.09-.5.88.88,0,0,1,.32-.37,2.15,2.15,0,0,1,.6-.23,4.89,4.89,0,0,1,1-.11l.91,0Zm0,2.19h.91V38.07a3.24,3.24,0,0,0-.11-.91,1.6,1.6,0,0,0-.38-.67,1.66,1.66,0,0,0-.68-.42,3.07,3.07,0,0,0-1-.15,3,3,0,0,0-1.57.36,1.61,1.61,0,0,0-.73,1.13l.86.09a1.06,1.06,0,0,1,.42-.61,1.72,1.72,0,0,1,1-.23,1.32,1.32,0,0,1,1,.32,1.48,1.48,0,0,1,.31,1v.21l-1,0a8.87,8.87,0,0,0-1.06.1,3.21,3.21,0,0,0-.92.3,1.72,1.72,0,0,0-.66.57,1.65,1.65,0,0,0-.24.93,1.84,1.84,0,0,0,.14.74,1.52,1.52,0,0,0,.4.53,1.69,1.69,0,0,0,.61.32,2.73,2.73,0,0,0,.78.1,2.29,2.29,0,0,0,1.16-.27,2.73,2.73,0,0,0,.78-.63Z" />
					<path fill="currentColor" d="M148.39,36.1h.93V37a2.53,2.53,0,0,1,.92-.8,2.41,2.41,0,0,1,1-.24,1.82,1.82,0,0,1,1.43.53,2.27,2.27,0,0,1,.46,1.53v3.75h-1V38.17a1.7,1.7,0,0,0-.28-1.11,1.12,1.12,0,0,0-.87-.32,2,2,0,0,0-.63.11,1.43,1.43,0,0,0-.56.35,1.84,1.84,0,0,0-.41.5,1.43,1.43,0,0,0-.15.7v3.33h-.94Z" />
					<path fill="currentColor" d="M154,38.07h3.21v1H154Z" />
					<path fill="currentColor" d="M158.59,40.08a1.1,1.1,0,0,0,.49.83,2.24,2.24,0,0,0,1.09.23,1.66,1.66,0,0,0,1-.24.8.8,0,0,0,.29-.65,1.55,1.55,0,0,0,0-.31.61.61,0,0,0-.15-.26.79.79,0,0,0-.31-.2,2.15,2.15,0,0,0-.51-.15l-.72-.11a5.26,5.26,0,0,1-.84-.19,2.34,2.34,0,0,1-.61-.31,1.21,1.21,0,0,1-.37-.47,1.7,1.7,0,0,1-.13-.67,1.45,1.45,0,0,1,.17-.7,1.67,1.67,0,0,1,.47-.52,2.2,2.2,0,0,1,.71-.32,3.14,3.14,0,0,1,.89-.12,3.76,3.76,0,0,1,1,.12,2,2,0,0,1,.67.33,1.57,1.57,0,0,1,.4.49,3.07,3.07,0,0,1,.22.6l-.86.14a2.87,2.87,0,0,0-.18-.4.73.73,0,0,0-.26-.29,1.44,1.44,0,0,0-.4-.18,2.78,2.78,0,0,0-.58-.06,2.81,2.81,0,0,0-.65.07,1.17,1.17,0,0,0-.42.2.64.64,0,0,0-.23.26.85.85,0,0,0-.06.31,1,1,0,0,0,0,.31.54.54,0,0,0,.16.24,1,1,0,0,0,.33.18,2.74,2.74,0,0,0,.53.13l.82.14a2.61,2.61,0,0,1,1.42.56,1.53,1.53,0,0,1,.43,1.18,1.45,1.45,0,0,1-.14.66,1.38,1.38,0,0,1-.42.52,2.05,2.05,0,0,1-.71.35,3.69,3.69,0,0,1-1,.13,4.87,4.87,0,0,1-1-.1,2.7,2.7,0,0,1-.77-.31,1.64,1.64,0,0,1-.55-.56,1.88,1.88,0,0,1-.24-.85Z" />
					<path fill="currentColor" d="M164.16,38.69a2.56,2.56,0,0,1,.14-.87,1.59,1.59,0,0,1,.36-.62,1.72,1.72,0,0,1,.55-.37,1.92,1.92,0,0,1,.69-.12,1.44,1.44,0,0,1,.57.11,1.23,1.23,0,0,1,.5.36,2,2,0,0,1,.35.66,3.46,3.46,0,0,1,.14,1,3.8,3.8,0,0,1-.13,1,1.94,1.94,0,0,1-.34.69,1.24,1.24,0,0,1-.52.39,1.75,1.75,0,0,1-.65.12,2,2,0,0,1-.66-.11,1.34,1.34,0,0,1-.53-.37,1.88,1.88,0,0,1-.34-.64,3.22,3.22,0,0,1-.13-.93Zm-.93,5.15h1V41a1.89,1.89,0,0,0,.83.68,2.39,2.39,0,0,0,.94.19,2.48,2.48,0,0,0,1-.21,2.13,2.13,0,0,0,.79-.6,2.58,2.58,0,0,0,.5-1,4.64,4.64,0,0,0,0-2.45,2.69,2.69,0,0,0-.45-.93,2.13,2.13,0,0,0-.75-.61,2.39,2.39,0,0,0-1.05-.22,2.33,2.33,0,0,0-1.08.25,2.5,2.5,0,0,0-.82.73v-.8h-.93Z" />
					<path fill="currentColor" d="M170.09,38.44a2.18,2.18,0,0,1,.5-1.33,1.49,1.49,0,0,1,1.17-.46,1.87,1.87,0,0,1,.71.13,1.19,1.19,0,0,1,.48.37,1.54,1.54,0,0,1,.27.56,2.75,2.75,0,0,1,.09.73Zm3.16,1.71a1.27,1.27,0,0,1-.44.72,1.64,1.64,0,0,1-1,.27A1.55,1.55,0,0,1,171,41a1.64,1.64,0,0,1-.53-.42,1.87,1.87,0,0,1-.32-.63,2.66,2.66,0,0,1-.12-.79h4.19v-.48a3.8,3.8,0,0,0-.15-1.1,2.41,2.41,0,0,0-.45-.87,2,2,0,0,0-.77-.57,2.7,2.7,0,0,0-1.12-.21,2.91,2.91,0,0,0-1.07.2,2.25,2.25,0,0,0-.84.59,2.57,2.57,0,0,0-.55.94,4,4,0,0,0-.2,1.3,3.19,3.19,0,0,0,.69,2.18,2.48,2.48,0,0,0,2,.78,2.65,2.65,0,0,0,1.68-.47,2.06,2.06,0,0,0,.72-1.29Z" />
					<path fill="currentColor" d="M178.56,39.54a1.43,1.43,0,0,1-.47,1.13,1.89,1.89,0,0,1-1.31.42,1.16,1.16,0,0,1-.84-.26.93.93,0,0,1-.26-.68,1.15,1.15,0,0,1,.1-.5.8.8,0,0,1,.31-.37,2.11,2.11,0,0,1,.61-.23,4.71,4.71,0,0,1,1-.11l.9,0Zm0,2.19h.92V38.07a3.23,3.23,0,0,0-.12-.91,1.48,1.48,0,0,0-.37-.67,1.73,1.73,0,0,0-.68-.42,3.11,3.11,0,0,0-1-.15,2.94,2.94,0,0,0-1.57.36,1.58,1.58,0,0,0-.73,1.13l.86.09a1,1,0,0,1,.41-.61,1.72,1.72,0,0,1,1-.23,1.32,1.32,0,0,1,1,.32,1.44,1.44,0,0,1,.31,1v.21l-1,0a9.5,9.5,0,0,0-1.06.1,3.31,3.31,0,0,0-.92.3,1.71,1.71,0,0,0-.65.57,1.57,1.57,0,0,0-.25.93,1.84,1.84,0,0,0,.14.74,1.52,1.52,0,0,0,.4.53,1.78,1.78,0,0,0,.61.32,2.78,2.78,0,0,0,.78.1,2.33,2.33,0,0,0,1.17-.27,2.69,2.69,0,0,0,.77-.63Z" />
					<path fill="currentColor" d="M180.5,34h.94v4.53l2.63-2.38h1.15l-2,1.88,2.24,3.75h-1.08l-1.87-3.19-1.05,1v2.24h-.94Z" />
					<path fill="currentColor" d="M185.79,36.1h.94v5.63h-.94Zm0-2.15h1v1.1h-1Z" />
					<path fill="currentColor" d="M187.78,36.1h.94V37a2.51,2.51,0,0,1,.91-.8,2.45,2.45,0,0,1,1.06-.24,1.8,1.8,0,0,1,1.42.53,2.22,2.22,0,0,1,.46,1.53v3.75h-.94V38.17a1.7,1.7,0,0,0-.29-1.11,1.12,1.12,0,0,0-.87-.32,1.88,1.88,0,0,0-.62.11,1.36,1.36,0,0,0-.56.35,1.72,1.72,0,0,0-.42.5,1.55,1.55,0,0,0-.14.7v3.33h-.95Z" />
					<path fill="currentColor" d="M197.65,38.89a2.45,2.45,0,0,1-.13.85,1.64,1.64,0,0,1-.36.61,1.42,1.42,0,0,1-.54.36,1.83,1.83,0,0,1-.68.12,1.7,1.7,0,0,1-.58-.11,1.41,1.41,0,0,1-.51-.35,1.78,1.78,0,0,1-.35-.63,3.45,3.45,0,0,1,0-1.91,1.64,1.64,0,0,1,.35-.64,1.35,1.35,0,0,1,.52-.37,1.91,1.91,0,0,1,1.27,0,1.31,1.31,0,0,1,.53.34,1.78,1.78,0,0,1,.36.62,2.78,2.78,0,0,1,.13.93Zm-4.07,3.36a1.7,1.7,0,0,0,.74,1.32,3.29,3.29,0,0,0,1.74.4,3.17,3.17,0,0,0,1.26-.22,1.79,1.79,0,0,0,.77-.63,2.45,2.45,0,0,0,.39-1,8.35,8.35,0,0,0,.1-1.31V36.1h-.94v.72a2.06,2.06,0,0,0-.84-.71,2.49,2.49,0,0,0-.93-.19,2.44,2.44,0,0,0-1,.21,2.08,2.08,0,0,0-.79.58,2.6,2.6,0,0,0-.51.91,3.57,3.57,0,0,0-.17,1.17,3.9,3.9,0,0,0,.15,1.1,2.52,2.52,0,0,0,.44.9,2,2,0,0,0,.74.61,2.41,2.41,0,0,0,1.07.22,2.21,2.21,0,0,0,1.08-.25,2.16,2.16,0,0,0,.79-.71v.6a2.57,2.57,0,0,1-.34,1.45,1.4,1.4,0,0,1-1.24.49A2,2,0,0,1,195,43a1.11,1.11,0,0,1-.47-.73Z" />
					<path fill="currentColor" d="M117.45,46.23h1v6.89h4l-.15.89h-4.88Z" />
					<path fill="currentColor" d="M126.6,51.82a1.43,1.43,0,0,1-.47,1.13,1.88,1.88,0,0,1-1.3.42,1.17,1.17,0,0,1-.85-.26.93.93,0,0,1-.26-.68,1.12,1.12,0,0,1,.1-.5.8.8,0,0,1,.31-.37,2.11,2.11,0,0,1,.61-.23,4.71,4.71,0,0,1,1-.11l.9,0Zm0,2.19h.92V50.35a3.6,3.6,0,0,0-.11-.91,1.6,1.6,0,0,0-.38-.67,1.73,1.73,0,0,0-.68-.42,3.66,3.66,0,0,0-2.6.2,1.62,1.62,0,0,0-.73,1.13l.86.1a1,1,0,0,1,.41-.61,1.66,1.66,0,0,1,1-.23,1.31,1.31,0,0,1,1,.32,1.41,1.41,0,0,1,.31,1v.22l-1,0a9.28,9.28,0,0,0-1.06.11,3,3,0,0,0-.92.3,1.55,1.55,0,0,0-.9,1.5,1.88,1.88,0,0,0,.14.74,1.52,1.52,0,0,0,.4.53,1.78,1.78,0,0,0,.61.32,2.78,2.78,0,0,0,.78.1,2.3,2.3,0,0,0,1.17-.27,2.69,2.69,0,0,0,.77-.63Z" />
					<path fill="currentColor" d="M128.54,48.37h.94v.87a2.59,2.59,0,0,1,.91-.8,2.45,2.45,0,0,1,1.06-.24,1.79,1.79,0,0,1,1.42.52,2.25,2.25,0,0,1,.47,1.54V54h-1V50.45a1.73,1.73,0,0,0-.28-1.11,1.13,1.13,0,0,0-.88-.32,1.88,1.88,0,0,0-.62.11,1.43,1.43,0,0,0-.56.35,1.83,1.83,0,0,0-.42.5,1.54,1.54,0,0,0-.14.69V54h-1Z" />
					<path fill="currentColor" d="M138.43,51.4a2.6,2.6,0,0,1-.17,1,1.6,1.6,0,0,1-1,.91,2.24,2.24,0,0,1-.57.08,1.6,1.6,0,0,1-.57-.11,1.36,1.36,0,0,1-.5-.36,1.79,1.79,0,0,1-.36-.68,3.44,3.44,0,0,1-.14-1,3.37,3.37,0,0,1,.13-1,1.87,1.87,0,0,1,.35-.69,1.3,1.3,0,0,1,.52-.39,1.75,1.75,0,0,1,.65-.12,1.91,1.91,0,0,1,.54.08,1.46,1.46,0,0,1,.48.27,1.51,1.51,0,0,1,.49.64,2.92,2.92,0,0,1,.15,1.05Zm0,2.61h.93V46.23h-1v2.83a2.05,2.05,0,0,0-.85-.68,2.33,2.33,0,0,0-.9-.18,2.46,2.46,0,0,0-1,.21,2.22,2.22,0,0,0-.79.6,2.76,2.76,0,0,0-.49,1,3.84,3.84,0,0,0-.18,1.26,4.79,4.79,0,0,0,.14,1.16,2.77,2.77,0,0,0,.44.95,2,2,0,0,0,.74.62,2.29,2.29,0,0,0,1.08.23,2.21,2.21,0,0,0,1.08-.25,2.53,2.53,0,0,0,.8-.73Z" />
					<path fill="currentColor" d="M141,52.36a1.1,1.1,0,0,0,.49.83,2.24,2.24,0,0,0,1.09.23,1.56,1.56,0,0,0,1-.25.76.76,0,0,0,.3-.64,1.48,1.48,0,0,0,0-.31.72.72,0,0,0-.15-.27,1.1,1.1,0,0,0-.31-.2,2.73,2.73,0,0,0-.51-.14l-.72-.11a6.34,6.34,0,0,1-.84-.19,2.34,2.34,0,0,1-.61-.31,1.29,1.29,0,0,1-.37-.47,1.74,1.74,0,0,1-.13-.67,1.45,1.45,0,0,1,.17-.7,1.55,1.55,0,0,1,.47-.52,2.45,2.45,0,0,1,.71-.33,3.56,3.56,0,0,1,.89-.11,3.63,3.63,0,0,1,1,.12,1.83,1.83,0,0,1,.67.33,1.47,1.47,0,0,1,.41.49,3.07,3.07,0,0,1,.22.6l-.86.14a2.43,2.43,0,0,0-.18-.4.73.73,0,0,0-.26-.29,1.2,1.2,0,0,0-.4-.18,2.78,2.78,0,0,0-.58-.06,2.81,2.81,0,0,0-.65.07,1.35,1.35,0,0,0-.42.19.68.68,0,0,0-.29.58,1,1,0,0,0,0,.31.54.54,0,0,0,.16.24,1,1,0,0,0,.33.18,2.68,2.68,0,0,0,.54.13l.81.13a2.69,2.69,0,0,1,1.42.57,1.53,1.53,0,0,1,.43,1.18,1.45,1.45,0,0,1-.14.66,1.38,1.38,0,0,1-.42.52,2.24,2.24,0,0,1-.71.35,3.32,3.32,0,0,1-1,.12,4.46,4.46,0,0,1-1-.09,2.52,2.52,0,0,1-.78-.32,1.7,1.7,0,0,1-.54-.56,1.81,1.81,0,0,1-.24-.85Z" />
				</svg>
			) : (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.58 63.28">
					<path d="M12.31,17a1.72,1.72,0,0,0,.57,1.29,2.39,2.39,0,0,0,1.47.4,3,3,0,0,0,.79-.1,1.61,1.61,0,0,0,.52-.27,1,1,0,0,0,.29-.4,1.43,1.43,0,0,0,.09-.5,1.12,1.12,0,0,0-.28-.7,1.84,1.84,0,0,0-1-.47L13.48,16a6.58,6.58,0,0,1-1.3-.35,3,3,0,0,1-.94-.55,2.35,2.35,0,0,1-.58-.84,3.1,3.1,0,0,1-.2-1.17,2.49,2.49,0,0,1,.28-1.2A2.37,2.37,0,0,1,11.5,11a3.35,3.35,0,0,1,1.17-.53,6.28,6.28,0,0,1,1.5-.18,5.1,5.1,0,0,1,1.73.24,3.1,3.1,0,0,1,1.13.64,2.52,2.52,0,0,1,.63.91,4.48,4.48,0,0,1,.28,1.09l-1.89.23a1.84,1.84,0,0,0-.57-1.13,2,2,0,0,0-1.27-.35,3.38,3.38,0,0,0-.84.09,1.48,1.48,0,0,0-.53.23.79.79,0,0,0-.28.32,1,1,0,0,0-.08.39,1,1,0,0,0,.3.8,2.36,2.36,0,0,0,1,.42l1.31.25a4.48,4.48,0,0,1,2.28,1,2.61,2.61,0,0,1,.72,2,2.86,2.86,0,0,1-.24,1.15,2.63,2.63,0,0,1-.69.93A3.49,3.49,0,0,1,16,20.1a5.3,5.3,0,0,1-1.63.22,4.66,4.66,0,0,1-3-.83A3.27,3.27,0,0,1,10.28,17Z" transform="translate(0)" />
					<path d="M19.1,10.53H21V14a2.69,2.69,0,0,1,1-.83,2.53,2.53,0,0,1,1.15-.27,2.29,2.29,0,0,1,1.76.63,2.8,2.8,0,0,1,.58,2v4.61H23.55V15.72a1.37,1.37,0,0,0-.28-1,1,1,0,0,0-.75-.27,1.66,1.66,0,0,0-.62.11,1.69,1.69,0,0,0-.49.31,1.57,1.57,0,0,0-.31.45,1.21,1.21,0,0,0-.13.56v4.2H19.1Z" transform="translate(0)" />
					<path d="M30.89,19.2A3.73,3.73,0,0,1,30,20a2.71,2.71,0,0,1-1.34.29,3.35,3.35,0,0,1-.89-.12,2.1,2.1,0,0,1-.73-.37,1.75,1.75,0,0,1-.5-.65,2.2,2.2,0,0,1-.19-.94A2,2,0,0,1,26.64,17a2.25,2.25,0,0,1,.85-.71,4.23,4.23,0,0,1,1.16-.36A9.83,9.83,0,0,1,30,15.79l.9,0V15.4a1,1,0,0,0-.35-.88,1.4,1.4,0,0,0-.84-.24c-.74,0-1.16.28-1.27.84L26.7,15a2.19,2.19,0,0,1,1-1.59,4,4,0,0,1,2.09-.49,4.19,4.19,0,0,1,1.36.2,2.07,2.07,0,0,1,1.4,1.43,4.15,4.15,0,0,1,.15,1.16v4.41H30.9Zm0-2.24L30,17a5.25,5.25,0,0,0-.95.12,1.92,1.92,0,0,0-.55.24.66.66,0,0,0-.26.33,1.14,1.14,0,0,0-.07.41.69.69,0,0,0,.24.55,1.08,1.08,0,0,0,.67.2,2,2,0,0,0,1.18-.34,1.36,1.36,0,0,0,.57-1.19Z" transform="translate(0)" />
					<path d="M34.06,13.1h1.82v1a2.44,2.44,0,0,1,.84-.82A2.13,2.13,0,0,1,37.77,13l.44,0a1.81,1.81,0,0,1,.31.05l-.23,1.7a3.48,3.48,0,0,0-.79-.09,2.33,2.33,0,0,0-.59.08,1.26,1.26,0,0,0-.51.27,1.38,1.38,0,0,0-.35.48,1.33,1.33,0,0,0-.12.55v4H34.06Z" transform="translate(0)" />
					<path d="M45.34,17.9a2.8,2.8,0,0,1-1,1.79,3.42,3.42,0,0,1-2.2.6,3.33,3.33,0,0,1-2.61-1,3.85,3.85,0,0,1-.87-2.73A4.45,4.45,0,0,1,39,15a3.44,3.44,0,0,1,.71-1.17,3.12,3.12,0,0,1,1.1-.73,3.86,3.86,0,0,1,1.42-.24,3,3,0,0,1,2.42.92,3.67,3.67,0,0,1,.8,2.49v.75H40.57A2.08,2.08,0,0,0,41,18.36a1.47,1.47,0,0,0,1.2.47,1.16,1.16,0,0,0,1.31-.93Zm-1.73-2.09a1.63,1.63,0,0,0-.35-1.12,1.41,1.41,0,0,0-1.1-.4,1.49,1.49,0,0,0-.66.12,1.29,1.29,0,0,0-.47.33,1.5,1.5,0,0,0-.3.48,1.52,1.52,0,0,0-.12.59Z" transform="translate(0)" />
					<path d="M51.39,19.16a2.76,2.76,0,0,1-3.86.6A2.37,2.37,0,0,1,47,19.3a4.57,4.57,0,0,1-.73-2.74,5.36,5.36,0,0,1,.21-1.49,3.38,3.38,0,0,1,.6-1.16,2.71,2.71,0,0,1,1-.76,3.08,3.08,0,0,1,1.27-.27,3.41,3.41,0,0,1,1,.19,2.28,2.28,0,0,1,1,.72V10.53h1.88v9.55H51.39Zm0-2.65a4.28,4.28,0,0,0-.11-1.07,1.45,1.45,0,0,0-.37-.66,1.06,1.06,0,0,0-.53-.31,2.61,2.61,0,0,0-.57-.08,1.48,1.48,0,0,0-.58.13,1.32,1.32,0,0,0-.48.37,2.1,2.1,0,0,0-.33.67,4.39,4.39,0,0,0,0,2,1.91,1.91,0,0,0,.31.68,1.14,1.14,0,0,0,.47.37,1.42,1.42,0,0,0,.57.12,1.57,1.57,0,0,0,1.1-.39,1.62,1.62,0,0,0,.4-.65,3.26,3.26,0,0,0,.13-1Z" transform="translate(0)" />
					<path d="M10.9,25.74h2v3.69H48V25.74h2v9.55H48v-4H12.89v4h-2Z" transform="translate(0)" />
					<path d="M51.45,25.74h1.93v1.68H51.45Zm0,2.57h1.88v7H51.48Z" transform="translate(0)" />
					<path d="M56.11,33.07a1,1,0,0,0,.5.8,2.26,2.26,0,0,0,1,.19,2.12,2.12,0,0,0,1-.17.56.56,0,0,0,.28-.54.67.67,0,0,0-.18-.47,1.09,1.09,0,0,0-.69-.28l-1-.16a4.12,4.12,0,0,1-1.89-.69,1.74,1.74,0,0,1-.62-1.48,1.83,1.83,0,0,1,.22-.91,2,2,0,0,1,.62-.68,3,3,0,0,1,1-.43,4.54,4.54,0,0,1,1.22-.16,5,5,0,0,1,1.39.17,2.72,2.72,0,0,1,.92.45,1.84,1.84,0,0,1,.53.67,3.4,3.4,0,0,1,.26.82l-1.67.22a1.19,1.19,0,0,0-.42-.71,1.65,1.65,0,0,0-1-.23,3.18,3.18,0,0,0-.62.06,1.11,1.11,0,0,0-.39.16.58.58,0,0,0-.2.21.46.46,0,0,0-.06.23.55.55,0,0,0,.21.49,2.08,2.08,0,0,0,.81.26l.88.14a6.62,6.62,0,0,1,1.22.31,2.28,2.28,0,0,1,.76.46,1.42,1.42,0,0,1,.4.65,3.2,3.2,0,0,1,.12.87,1.9,1.9,0,0,1-.75,1.58,3.71,3.71,0,0,1-2.31.6,6,6,0,0,1-1.2-.11,3.1,3.1,0,0,1-1-.4,2.41,2.41,0,0,1-.74-.75,2.54,2.54,0,0,1-.32-1.18Z" transform="translate(0)" />
					<path d="M62.31,29.77h-1.1V28.31h1.1V26.45h1.88v1.86h1.6v1.46h-1.6V33a.94.94,0,0,0,.22.74.91.91,0,0,0,.59.19,2.42,2.42,0,0,0,.38,0,1.78,1.78,0,0,0,.39-.07L66,35.21a6.27,6.27,0,0,1-.75.17,5.48,5.48,0,0,1-.7,0,2.36,2.36,0,0,1-1.71-.55A2.51,2.51,0,0,1,62.32,33Z" transform="translate(0)" />
					<path d="M69.71,35.5a3.46,3.46,0,0,1-2.61-1,3.8,3.8,0,0,1-.92-2.76,4.52,4.52,0,0,1,.25-1.54,3.16,3.16,0,0,1,.71-1.16,3.07,3.07,0,0,1,1.11-.73,4.34,4.34,0,0,1,2.93,0A3,3,0,0,1,73,30.24a5.05,5.05,0,0,1,0,3.1,3.12,3.12,0,0,1-.71,1.17,3,3,0,0,1-1.11.74A4,4,0,0,1,69.71,35.5Zm0-1.5a1.35,1.35,0,0,0,1.2-.57,3,3,0,0,0,.4-1.65,2.91,2.91,0,0,0-.4-1.63,1.55,1.55,0,0,0-2.19-.2l-.2.2a2.83,2.83,0,0,0-.4,1.63,2.89,2.89,0,0,0,.39,1.65,1.36,1.36,0,0,0,1.2.58Z" transform="translate(0)" />
					<path d="M74.26,28.31h1.81v1a2.39,2.39,0,0,1,.85-.82,2.07,2.07,0,0,1,1-.27,3.24,3.24,0,0,1,.44,0l.31,0L78.48,30a4.15,4.15,0,0,0-.79-.08,2.39,2.39,0,0,0-.59.08,1.22,1.22,0,0,0-.5.27,1.29,1.29,0,0,0-.36.48,1.5,1.5,0,0,0-.11.55v4H74.26Z" transform="translate(0)" />
					<path d="M80.22,36.28a3.5,3.5,0,0,0,.79.11.93.93,0,0,0,.6-.19,1.43,1.43,0,0,0,.39-.63l.21-.59-2.9-6.67h2.17l1.63,4.58,1.52-4.58h2.05l-2.82,7.18c-.16.4-.32.75-.46,1a2.7,2.7,0,0,1-.49.75,1.62,1.62,0,0,1-.65.45,2.51,2.51,0,0,1-.94.15,3.94,3.94,0,0,1-.69-.05,3.44,3.44,0,0,1-.72-.17Z" transform="translate(0)" />
					<path d="M34.57,41H38a5.6,5.6,0,0,1,1.82.25,2.91,2.91,0,0,1,1.13.65,2.15,2.15,0,0,1,.59.95,4,4,0,0,1,.17,1.13,4.4,4.4,0,0,1-.17,1.2,2.35,2.35,0,0,1-.59,1,2.89,2.89,0,0,1-1.12.69,5.3,5.3,0,0,1-1.77.25h-1.5v3.41h-2ZM38,45.43a2.81,2.81,0,0,0,.87-.11,1.57,1.57,0,0,0,.54-.29,1,1,0,0,0,.27-.46,2.47,2.47,0,0,0,.08-.6,2.33,2.33,0,0,0-.08-.59,1,1,0,0,0-.29-.43,1.29,1.29,0,0,0-.54-.26,3.55,3.55,0,0,0-.86-.1H36.53v2.84Z" transform="translate(0)" />
					<path d="M42.31,43.51h1.82v1a2.44,2.44,0,0,1,.84-.82,2.13,2.13,0,0,1,1-.28l.45,0,.3.05-.22,1.7a3,3,0,0,0-.79-.08,2.32,2.32,0,0,0-.59.07,1.28,1.28,0,0,0-.51.28,1.4,1.4,0,0,0-.47,1v4H42.31Z" transform="translate(0)" />
					<path d="M50.48,50.71a3.46,3.46,0,0,1-2.61-1A3.8,3.8,0,0,1,47,47a4.52,4.52,0,0,1,.25-1.54A3.13,3.13,0,0,1,49,43.55a4.46,4.46,0,0,1,2.93,0,3.06,3.06,0,0,1,1.82,1.9,5.05,5.05,0,0,1,0,3.1,3.2,3.2,0,0,1-.71,1.17,2.94,2.94,0,0,1-1.11.73A3.85,3.85,0,0,1,50.48,50.71Zm0-1.5a1.36,1.36,0,0,0,1.2-.58,2.92,2.92,0,0,0,.4-1.64,2.75,2.75,0,0,0-.4-1.63,1.55,1.55,0,0,0-2.19-.2l-.2.2a2.83,2.83,0,0,0-.4,1.63,3,3,0,0,0,.39,1.65,1.33,1.33,0,0,0,1.2.57Z" transform="translate(0)" />
					<path d="M53.31,51.34a3.58,3.58,0,0,0,.79.11.92.92,0,0,0,.58-.17.86.86,0,0,0,.22-.72v-7h1.88v7a2.54,2.54,0,0,1-.59,1.87,2.27,2.27,0,0,1-1.71.58,5.34,5.34,0,0,1-.69,0,4.7,4.7,0,0,1-.74-.18ZM54.88,41H56.8v1.68H54.88Z" transform="translate(0)" />
					<path d="M64.57,48.32a2.7,2.7,0,0,1-.94,1.78,4.13,4.13,0,0,1-4.81-.35A3.92,3.92,0,0,1,57.94,47a4.65,4.65,0,0,1,.25-1.57,3.37,3.37,0,0,1,.72-1.17,3,3,0,0,1,1.1-.73,4,4,0,0,1,1.42-.25,3.07,3.07,0,0,1,2.41.93,3.6,3.6,0,0,1,.81,2.48v.75H59.81a2.09,2.09,0,0,0,.43,1.32,1.45,1.45,0,0,0,1.2.47,1.17,1.17,0,0,0,1.31-.93Zm-1.72-2.09a1.64,1.64,0,0,0-.36-1.12,1.4,1.4,0,0,0-1.1-.4,1.47,1.47,0,0,0-.65.12,1.29,1.29,0,0,0-.48.33,1.5,1.5,0,0,0-.3.48,2,2,0,0,0-.12.59Z" transform="translate(0)" />
					<path d="M72.06,48a2.77,2.77,0,0,1-3.13,2.74,3.93,3.93,0,0,1-1.52-.27,2.54,2.54,0,0,1-1-.75,3,3,0,0,1-.61-1.16,5.14,5.14,0,0,1-.2-1.51,4.64,4.64,0,0,1,.23-1.52,3.21,3.21,0,0,1,.68-1.17,3,3,0,0,1,1.08-.76A3.91,3.91,0,0,1,69,43.3a4.33,4.33,0,0,1,1.24.18,2.8,2.8,0,0,1,.89.5,2.35,2.35,0,0,1,.56.77,3.47,3.47,0,0,1,.27,1L70.08,46a1.76,1.76,0,0,0-.31-.86,1,1,0,0,0-.83-.33,1.28,1.28,0,0,0-.71.18,1.3,1.3,0,0,0-.44.49,2.15,2.15,0,0,0-.24.7,4.53,4.53,0,0,0-.06.82,4.68,4.68,0,0,0,.06.84,2.46,2.46,0,0,0,.23.71,1.22,1.22,0,0,0,.44.49,1.26,1.26,0,0,0,.71.18,1.17,1.17,0,0,0,.87-.32,1.49,1.49,0,0,0,.36-.92Z" transform="translate(0)" />
					<path d="M73.56,45h-1.1V43.52h1.1V41.66h1.87v1.86H77V45h-1.6V48.2a1,1,0,0,0,.23.74.86.86,0,0,0,.58.19A3,3,0,0,0,77,49l.27,1.38a4.89,4.89,0,0,1-.76.16,5.49,5.49,0,0,1-.7.05,2.33,2.33,0,0,1-1.7-.55,2.52,2.52,0,0,1-.56-1.84Z" transform="translate(0)" />
					<rect x="55.24" y="15.67" width="17.98" height="1.85" />
					<rect x="78.91" y="46.08" width="17.64" height="1.85" />
					<path d="M105.28,63.26H21.05l-21-21V0H84.23l21,21.05ZM21.72,61.65h81.94V21.72L83.56,1.61H1.61V41.54Z" transform="translate(0)" />
					<path d="M119.1,23,117.37,24l-.42-.71,2.25-1.39h.88v7.52h-1Z" transform="translate(0)" />
					<path d="M123.73,29.44c.35-1.18.74-2.32,1.19-3.42a31.78,31.78,0,0,1,1.51-3.24h-4.06v-.87h5.13v.81A32.49,32.49,0,0,0,126,26a30.39,30.39,0,0,0-1.16,3.45Z" transform="translate(0)" />
					<path d="M131.1,29.64a2.4,2.4,0,0,1-2-1,5,5,0,0,1-.72-3,7.07,7.07,0,0,1,.2-1.77,3.32,3.32,0,0,1,.56-1.24,2.15,2.15,0,0,1,.88-.73,3,3,0,0,1,2.3,0,2.1,2.1,0,0,1,.86.73,3.45,3.45,0,0,1,.54,1.24,8.45,8.45,0,0,1,0,3.54,3.45,3.45,0,0,1-.54,1.24,2.37,2.37,0,0,1-.88.72A2.79,2.79,0,0,1,131.1,29.64Zm0-.85a1.59,1.59,0,0,0,.82-.21,1.63,1.63,0,0,0,.53-.61,3.08,3.08,0,0,0,.29-1,8.78,8.78,0,0,0,0-2.61,3,3,0,0,0-.29-1,1.4,1.4,0,0,0-1.35-.83,1.49,1.49,0,0,0-.83.21,1.42,1.42,0,0,0-.54.62,3.37,3.37,0,0,0-.3,1,9.51,9.51,0,0,0,0,2.61,3.37,3.37,0,0,0,.3,1,1.57,1.57,0,0,0,.54.61,1.6,1.6,0,0,0,.83.21Z" transform="translate(0)" />
					<path d="M137.12,29.64a2.38,2.38,0,0,1-2-1,4.92,4.92,0,0,1-.73-3,7.07,7.07,0,0,1,.2-1.77,3.67,3.67,0,0,1,.56-1.24,2.22,2.22,0,0,1,.88-.73,3,3,0,0,1,2.3,0,2.1,2.1,0,0,1,.86.73,3.45,3.45,0,0,1,.54,1.24,8.45,8.45,0,0,1,0,3.54,3.45,3.45,0,0,1-.54,1.24,2.37,2.37,0,0,1-.88.72A2.94,2.94,0,0,1,137.12,29.64Zm0-.85a1.4,1.4,0,0,0,1.35-.82,3.37,3.37,0,0,0,.3-1,9.51,9.51,0,0,0,0-2.61,3.28,3.28,0,0,0-.3-1,1.38,1.38,0,0,0-1.35-.83,1.51,1.51,0,0,0-.83.21,1.48,1.48,0,0,0-.54.62,3.69,3.69,0,0,0-.29,1,9.51,9.51,0,0,0,0,2.61,3.69,3.69,0,0,0,.29,1,1.66,1.66,0,0,0,.54.61,1.6,1.6,0,0,0,.83.21Z" transform="translate(0)" />
					<path d="M143.23,27.11a3.82,3.82,0,0,0,.15.67,1.53,1.53,0,0,0,.31.52,1.35,1.35,0,0,0,.51.34,2.27,2.27,0,0,0,.77.13,1.48,1.48,0,0,0,1.29-.52,2.37,2.37,0,0,0,.38-1.44V21.66h1v5.22a2.76,2.76,0,0,1-.71,2.07,2.67,2.67,0,0,1-1.94.69,2.94,2.94,0,0,1-1.94-.61,2.8,2.8,0,0,1-.84-1.92Z" transform="translate(0)" />
					<path d="M152.12,28.67a2.84,2.84,0,0,1-.78.64,2.32,2.32,0,0,1-1.16.26,2.57,2.57,0,0,1-.78-.1,1.76,1.76,0,0,1-.61-.31,1.58,1.58,0,0,1-.4-.53,1.74,1.74,0,0,1-.14-.74,1.63,1.63,0,0,1,.24-.94,1.73,1.73,0,0,1,.66-.57,3.29,3.29,0,0,1,.92-.29,7.48,7.48,0,0,1,1.05-.11l1,0v-.22a1.42,1.42,0,0,0-.3-1,1.33,1.33,0,0,0-1-.32,1.7,1.7,0,0,0-1,.22,1.07,1.07,0,0,0-.42.62l-.86-.1a1.63,1.63,0,0,1,.73-1.13,3,3,0,0,1,1.57-.35,3.25,3.25,0,0,1,1,.14,1.77,1.77,0,0,1,.68.42,1.51,1.51,0,0,1,.37.68,3.42,3.42,0,0,1,.12.9v3.66h-.92Zm0-2.05-.91,0a6.27,6.27,0,0,0-1,.11,1.91,1.91,0,0,0-.6.23.8.8,0,0,0-.31.37,1.24,1.24,0,0,0-.1.5,1,1,0,0,0,.26.68,1.17,1.17,0,0,0,.84.26,1.93,1.93,0,0,0,1.31-.42,1.43,1.43,0,0,0,.47-1.13Z" transform="translate(0)" />
					<path d="M154,21.66H155v3a2.63,2.63,0,0,1,.91-.78,2.56,2.56,0,0,1,1.05-.24,1.79,1.79,0,0,1,1.42.53,2.23,2.23,0,0,1,.47,1.53v3.75h-1V25.88a1.65,1.65,0,0,0-.28-1.1,1.07,1.07,0,0,0-.86-.32,1.9,1.9,0,0,0-.63.11,1.43,1.43,0,0,0-.56.35,1.64,1.64,0,0,0-.41.5,1.39,1.39,0,0,0-.15.69v3.34H154Z" transform="translate(0)" />
					<path d="M159.81,23.81h.94v.87a2.31,2.31,0,0,1,.74-.72,1.77,1.77,0,0,1,.9-.25,2.89,2.89,0,0,1,.5,0l-.12.89-.22,0-.24,0a1.7,1.7,0,0,0-.54.09,1.66,1.66,0,0,0-.51.31,1.72,1.72,0,0,0-.37.48,1.42,1.42,0,0,0-.14.64v3.34h-.95Z" transform="translate(0)" />
					<path d="M167.91,27.86a2,2,0,0,1-.73,1.29,2.65,2.65,0,0,1-1.67.47,2.52,2.52,0,0,1-2-.78,3.24,3.24,0,0,1-.69-2.18,4.1,4.1,0,0,1,.2-1.3,2.62,2.62,0,0,1,.55-.94,2.33,2.33,0,0,1,.84-.59,3,3,0,0,1,2.19,0,2,2,0,0,1,.77.57,2.41,2.41,0,0,1,.45.87,3.65,3.65,0,0,1,.14,1.1v.48h-4.18a2.71,2.71,0,0,0,.12.79,1.71,1.71,0,0,0,.32.63,1.46,1.46,0,0,0,.53.42,1.68,1.68,0,0,0,.73.15,1.57,1.57,0,0,0,1-.27,1.31,1.31,0,0,0,.45-.72Zm-.86-1.71a3,3,0,0,0-.09-.73,1.53,1.53,0,0,0-.27-.56,1.35,1.35,0,0,0-.48-.37,1.94,1.94,0,0,0-.71-.13,1.54,1.54,0,0,0-1.17.46,2.25,2.25,0,0,0-.5,1.33Z" transform="translate(0)" />
					<path d="M116.68,42.82l.33.08a1.88,1.88,0,0,0,.41,0,.66.66,0,0,0,.57-.21,1.15,1.15,0,0,0,.16-.68v-6h.95v5.7a4.15,4.15,0,0,1-.07.79,1.56,1.56,0,0,1-.23.62,1.16,1.16,0,0,1-.47.41,1.8,1.8,0,0,1-.76.14,2.39,2.39,0,0,1-.59-.05,3.9,3.9,0,0,1-.46-.12Zm1.44-8.88h1V35h-1Z" transform="translate(0)" />
					<path d="M120.11,36.08h1v3.55a1.84,1.84,0,0,0,.25,1.12,1,1,0,0,0,.84.32,1.67,1.67,0,0,0,1.14-.45,2,2,0,0,0,.42-.51,1.53,1.53,0,0,0,.14-.69V36.08h1v5.63h-.94v-.87a2.66,2.66,0,0,1-.9.8,2.19,2.19,0,0,1-1,.26,1.75,1.75,0,0,1-1.39-.52,2.32,2.32,0,0,1-.43-1.55Zm.92-2.14h1V35h-1Zm1.9,0h1V35h-1Z" transform="translate(0)" />
					<path d="M129.92,40.91a2.54,2.54,0,0,1-.81.72,2.13,2.13,0,0,1-1.08.26,2.32,2.32,0,0,1-1.07-.23,2.15,2.15,0,0,1-.75-.62,2.81,2.81,0,0,1-.43-.95,4.56,4.56,0,0,1-.15-1.16,4.46,4.46,0,0,1,.18-1.26,2.63,2.63,0,0,1,.5-1,2.07,2.07,0,0,1,.78-.6,2.38,2.38,0,0,1,1-.21,2.3,2.3,0,0,1,.9.18,2,2,0,0,1,.85.68V33.94h1v7.78h-.92Zm0-2.18a3.11,3.11,0,0,0-.15-1,1.53,1.53,0,0,0-.49-.64,1.42,1.42,0,0,0-.48-.27,2,2,0,0,0-.54-.08,1.82,1.82,0,0,0-.65.12,1.43,1.43,0,0,0-.52.39,2,2,0,0,0-.35.69,3.48,3.48,0,0,0-.12,1,3.34,3.34,0,0,0,.13,1,1.94,1.94,0,0,0,.36.68,1.49,1.49,0,0,0,.5.36,1.6,1.6,0,0,0,.57.11,2.24,2.24,0,0,0,.57-.08,1.83,1.83,0,0,0,.5-.26,1.71,1.71,0,0,0,.5-.66,2.55,2.55,0,0,0,.17-1Z" transform="translate(0)" />
					<path d="M131.87,33.94h1V35h-1Zm0,2.14h.95v5.64h-.95Z" transform="translate(0)" />
					<path d="M134.5,40.07a1.09,1.09,0,0,0,.5.82,2.07,2.07,0,0,0,1.09.24,1.64,1.64,0,0,0,1-.25.8.8,0,0,0,.31-.65,1.65,1.65,0,0,0,0-.31.61.61,0,0,0-.15-.26,1.21,1.21,0,0,0-.3-.2,3,3,0,0,0-.51-.14l-.72-.11a4.68,4.68,0,0,1-.84-.2,2,2,0,0,1-.61-.31,1.26,1.26,0,0,1-.38-.47,1.53,1.53,0,0,1-.12-.67,1.34,1.34,0,0,1,.17-.7,1.43,1.43,0,0,1,.47-.51,2.25,2.25,0,0,1,.7-.33,3.35,3.35,0,0,1,.89-.12,4.38,4.38,0,0,1,1,.12,2.1,2.1,0,0,1,.67.33,1.46,1.46,0,0,1,.4.49,3.07,3.07,0,0,1,.22.6l-.86.14a2.87,2.87,0,0,0-.18-.4.89.89,0,0,0-.26-.29,1.18,1.18,0,0,0-.4-.17,2.86,2.86,0,0,0-.58-.06,3,3,0,0,0-.66.07,1.18,1.18,0,0,0-.41.19.67.67,0,0,0-.23.27.81.81,0,0,0-.06.31,1.1,1.1,0,0,0,0,.31.56.56,0,0,0,.16.24,1.06,1.06,0,0,0,.33.18,3.16,3.16,0,0,0,.53.13l.82.13a2.73,2.73,0,0,1,1.42.57,1.73,1.73,0,0,1,.29,1.83,1.46,1.46,0,0,1-.42.53,2.21,2.21,0,0,1-.71.34,3.33,3.33,0,0,1-1,.13,4.32,4.32,0,0,1-1-.1,2.4,2.4,0,0,1-.78-.31,1.64,1.64,0,0,1-.55-.56,1.81,1.81,0,0,1-.24-.85Z" transform="translate(0)" />
					<path d="M143.86,39.81a2.49,2.49,0,0,1-.74,1.53,2.41,2.41,0,0,1-1.69.55,2.78,2.78,0,0,1-1.14-.21,2.22,2.22,0,0,1-.83-.59,2.51,2.51,0,0,1-.5-.94,4.3,4.3,0,0,1-.17-1.23,4,4,0,0,1,.19-1.24,2.66,2.66,0,0,1,.53-.94,2.42,2.42,0,0,1,.85-.61,2.78,2.78,0,0,1,1.13-.22,2.23,2.23,0,0,1,1.57.5,2.26,2.26,0,0,1,.71,1.37l-.94.17a4.13,4.13,0,0,0-.15-.5,1.31,1.31,0,0,0-.26-.4,1.07,1.07,0,0,0-.4-.26,1.35,1.35,0,0,0-.56-.09,1.68,1.68,0,0,0-.76.16,1.47,1.47,0,0,0-.52.47,2,2,0,0,0-.29.7,3.61,3.61,0,0,0-.1.86,4.28,4.28,0,0,0,.09.87,2.33,2.33,0,0,0,.28.7,1.53,1.53,0,0,0,.51.47,1.62,1.62,0,0,0,.78.17,1.37,1.37,0,0,0,1-.34,1.88,1.88,0,0,0,.43-.95Z" transform="translate(0)" />
					<path d="M144.63,33.94h1v3a2.6,2.6,0,0,1,.91-.77,2.41,2.41,0,0,1,1-.24,1.81,1.81,0,0,1,1.42.52,2.25,2.25,0,0,1,.46,1.54v3.74h-.95V38.15a1.7,1.7,0,0,0-.28-1.1,1.12,1.12,0,0,0-.87-.33,1.88,1.88,0,0,0-.62.11,1.71,1.71,0,0,0-.56.35,1.88,1.88,0,0,0-.42.51,1.5,1.5,0,0,0-.14.69v3.33h-1Z" transform="translate(0)" />
					<path d="M155.28,40.14a2,2,0,0,1-.73,1.28,2.63,2.63,0,0,1-1.67.47,2.48,2.48,0,0,1-2-.78,3.16,3.16,0,0,1-.69-2.17,3.84,3.84,0,0,1,.2-1.3,2.66,2.66,0,0,1,.55-1,2.44,2.44,0,0,1,.84-.58,3.06,3.06,0,0,1,2.19,0,2.18,2.18,0,0,1,.77.57,2.41,2.41,0,0,1,.45.87,4.11,4.11,0,0,1,.15,1.1v.48h-4.19a2.66,2.66,0,0,0,.12.79,1.88,1.88,0,0,0,.32.63,1.64,1.64,0,0,0,.53.42,1.69,1.69,0,0,0,.73.16,1.66,1.66,0,0,0,1-.27,1.32,1.32,0,0,0,.44-.73Zm-.87-1.72a2.49,2.49,0,0,0-.08-.72,1.66,1.66,0,0,0-.27-.57,1.29,1.29,0,0,0-.48-.36,1.75,1.75,0,0,0-.71-.13,1.47,1.47,0,0,0-1.17.46,2.14,2.14,0,0,0-.5,1.32Z" transform="translate(0)" />
					<path d="M156.79,40.07a1.09,1.09,0,0,0,.5.82,2.07,2.07,0,0,0,1.09.24,1.64,1.64,0,0,0,1-.25.77.77,0,0,0,.3-.65,1.1,1.1,0,0,0,0-.31.51.51,0,0,0-.14-.26,1.21,1.21,0,0,0-.3-.2,3.23,3.23,0,0,0-.51-.14l-.72-.11a4.68,4.68,0,0,1-.84-.2,2,2,0,0,1-.61-.31,1.09,1.09,0,0,1-.38-.47,1.53,1.53,0,0,1-.12-.67,1.44,1.44,0,0,1,.16-.7,1.64,1.64,0,0,1,.47-.51,2.41,2.41,0,0,1,.71-.33,3.35,3.35,0,0,1,.89-.12,4.38,4.38,0,0,1,1,.12,2.1,2.1,0,0,1,.67.33,1.46,1.46,0,0,1,.4.49,3.07,3.07,0,0,1,.22.6l-.87.14a2,2,0,0,0-.17-.4.89.89,0,0,0-.26-.29,1.18,1.18,0,0,0-.4-.17,2.86,2.86,0,0,0-.58-.06,3,3,0,0,0-.66.07,1.33,1.33,0,0,0-.42.19.73.73,0,0,0-.22.27.82.82,0,0,0-.07.31,1.12,1.12,0,0,0,.05.31.56.56,0,0,0,.16.24,1.06,1.06,0,0,0,.33.18,3.16,3.16,0,0,0,.53.13l.82.13a2.73,2.73,0,0,1,1.42.57,1.54,1.54,0,0,1,.43,1.17,1.63,1.63,0,0,1-.14.66,1.48,1.48,0,0,1-.43.53,2.07,2.07,0,0,1-.7.34,3.39,3.39,0,0,1-1,.13,4.32,4.32,0,0,1-.95-.1,2.5,2.5,0,0,1-.78-.31,1.61,1.61,0,0,1-.54-.56,1.81,1.81,0,0,1-.24-.85Z" transform="translate(0)" />
					<path d="M163.56,33.94h1v6.88h4l-.15.9h-4.88Z" transform="translate(0)" />
					<path d="M173.87,40.14a2,2,0,0,1-.72,1.28,2.63,2.63,0,0,1-1.67.47,2.5,2.5,0,0,1-2-.78,3.15,3.15,0,0,1-.68-2.17,3.84,3.84,0,0,1,.2-1.3,2.52,2.52,0,0,1,.55-1,2.35,2.35,0,0,1,.84-.58,2.61,2.61,0,0,1,1.07-.2,2.88,2.88,0,0,1,1.12.2,2.09,2.09,0,0,1,.77.58,2.37,2.37,0,0,1,.45.86,3.73,3.73,0,0,1,.14,1.1v.48h-4.18a3,3,0,0,0,.11.79,1.81,1.81,0,0,0,.33.63,1.36,1.36,0,0,0,.53.42,1.69,1.69,0,0,0,.73.16,1.63,1.63,0,0,0,1-.27,1.34,1.34,0,0,0,.45-.72ZM173,38.42a2.89,2.89,0,0,0-.08-.72,1.69,1.69,0,0,0-.28-.57,1.22,1.22,0,0,0-.48-.36,1.69,1.69,0,0,0-.71-.13,1.49,1.49,0,0,0-1.17.46,2.2,2.2,0,0,0-.5,1.32Z" transform="translate(0)" />
					<path d="M174.78,33.94h.94v2.93a2.75,2.75,0,0,1,.81-.71,2.23,2.23,0,0,1,1.08-.25,2.39,2.39,0,0,1,1,.22,2.11,2.11,0,0,1,.75.61,2.59,2.59,0,0,1,.45.93,4.79,4.79,0,0,1,.15,1.19,4.46,4.46,0,0,1-.18,1.26,2.63,2.63,0,0,1-.5,1,2.16,2.16,0,0,1-.79.61,2.45,2.45,0,0,1-1,.21,2.38,2.38,0,0,1-.95-.2,2.06,2.06,0,0,1-.83-.69v.72h-.94Zm.93,5.12a3.26,3.26,0,0,0,.13.93,2,2,0,0,0,.34.64,1.22,1.22,0,0,0,.53.36A1.85,1.85,0,0,0,178,41a1.43,1.43,0,0,0,.52-.39,2.22,2.22,0,0,0,.34-.69,3.65,3.65,0,0,0,.12-1,3.34,3.34,0,0,0-.13-1,1.82,1.82,0,0,0-.36-.67,1.14,1.14,0,0,0-.49-.35,1.26,1.26,0,0,0-.57-.11,1.79,1.79,0,0,0-.69.12,1.49,1.49,0,0,0-.55.37,1.86,1.86,0,0,0-.37.62,2.54,2.54,0,0,0-.12.87Z" transform="translate(0)" />
					<path d="M185.71,40.14a2,2,0,0,1-.72,1.28,2.64,2.64,0,0,1-1.68.47,2.48,2.48,0,0,1-2-.78,3.15,3.15,0,0,1-.68-2.17,3.84,3.84,0,0,1,.2-1.3,2.52,2.52,0,0,1,.55-1,2.35,2.35,0,0,1,.84-.58,2.61,2.61,0,0,1,1.07-.2,2.85,2.85,0,0,1,1.12.2,2.09,2.09,0,0,1,.77.58,2.37,2.37,0,0,1,.45.86,3.73,3.73,0,0,1,.14,1.1v.48h-4.18a3,3,0,0,0,.11.79,1.81,1.81,0,0,0,.33.63,1.36,1.36,0,0,0,.53.42,1.69,1.69,0,0,0,.73.16,1.63,1.63,0,0,0,1-.27,1.34,1.34,0,0,0,.45-.72Zm-.86-1.72a2.89,2.89,0,0,0-.08-.72,1.69,1.69,0,0,0-.28-.57,1.15,1.15,0,0,0-.48-.36,1.68,1.68,0,0,0-.7-.13,1.47,1.47,0,0,0-1.17.46,2.14,2.14,0,0,0-.5,1.32Z" transform="translate(0)" />
					<path d="M186.62,36.08h.93v.86a2.63,2.63,0,0,1,.92-.8,2.41,2.41,0,0,1,1.06-.23,1.77,1.77,0,0,1,1.42.52,2.23,2.23,0,0,1,.46,1.53v3.75h-.95V38.15a1.65,1.65,0,0,0-.28-1.1,1.14,1.14,0,0,0-.87-.33,2.25,2.25,0,0,0-.63.11,1.71,1.71,0,0,0-.56.35,1.81,1.81,0,0,0-.41.51,1.38,1.38,0,0,0-.14.69v3.34h-.95Z" transform="translate(0)" />
					<path d="M117.25,46.21h1v1.1h-1Zm0,2.15h1V54h-1Z" transform="translate(0)" />
					<path d="M119.27,48.36h.94v.86a2.59,2.59,0,0,1,.91-.8,2.45,2.45,0,0,1,1.06-.24,1.81,1.81,0,0,1,1.42.52,2.26,2.26,0,0,1,.47,1.54V54h-.95V50.43a1.64,1.64,0,0,0-.29-1.11A1.09,1.09,0,0,0,122,49a1.9,1.9,0,0,0-.63.11,1.56,1.56,0,0,0-.56.35,1.74,1.74,0,0,0-.42.51,1.47,1.47,0,0,0-.14.69V54h-1Z" transform="translate(0)" />
					<path d="M127.17,46.21h2.72a4.36,4.36,0,0,1,1.52.24,2.78,2.78,0,0,1,1.14.71,3.24,3.24,0,0,1,.71,1.2,5.72,5.72,0,0,1,0,3.41,3.07,3.07,0,0,1-2,2,4.94,4.94,0,0,1-1.62.25h-2.47Zm2.52,6.91a2.58,2.58,0,0,0,2.11-.81,3.4,3.4,0,0,0,.66-2.24,5,5,0,0,0-.14-1.27,2.58,2.58,0,0,0-.46-.94,2,2,0,0,0-.8-.59,3.08,3.08,0,0,0-1.16-.2h-1.73v6Z" transform="translate(0)" />
					<path d="M139.25,52.41a2,2,0,0,1-.72,1.29,2.64,2.64,0,0,1-1.68.47,2.52,2.52,0,0,1-2-.78,3.18,3.18,0,0,1-.68-2.18,3.84,3.84,0,0,1,.2-1.3A2.48,2.48,0,0,1,135,49a2.25,2.25,0,0,1,.84-.59,3,3,0,0,1,2.19,0,2.07,2.07,0,0,1,.77.57,2.58,2.58,0,0,1,.45.87,4,4,0,0,1,.14,1.1v.48h-4.18a2.69,2.69,0,0,0,.11.79,2,2,0,0,0,.33.63,1.46,1.46,0,0,0,.53.42,1.65,1.65,0,0,0,.73.15,1.57,1.57,0,0,0,1-.27,1.37,1.37,0,0,0,.45-.72Zm-.86-1.71a3,3,0,0,0-.09-.73,1.53,1.53,0,0,0-.27-.56,1.13,1.13,0,0,0-.48-.36,1.7,1.7,0,0,0-.71-.14,1.55,1.55,0,0,0-1.17.46,2.25,2.25,0,0,0-.5,1.33Z" transform="translate(0)" />
					<path d="M140.12,48.36h.95v3.55a1.87,1.87,0,0,0,.26,1.11,1,1,0,0,0,.83.33,1.69,1.69,0,0,0,1.15-.46,2,2,0,0,0,.41-.5,1.54,1.54,0,0,0,.14-.7V48.36h.95V54h-.94v-.87a2.57,2.57,0,0,1-.9.8,2.07,2.07,0,0,1-1,.25,1.71,1.71,0,0,1-1.39-.51,2.41,2.41,0,0,1-.43-1.56Z" transform="translate(0)" />
					<path d="M146.16,49.11h-.87v-.75h.87V46.82h1v1.54h1.49v.75h-1.49v3.3a1.13,1.13,0,0,0,.18.67.69.69,0,0,0,.57.25,2.67,2.67,0,0,0,.75-.12l.16.73a3.59,3.59,0,0,1-.47.12,3,3,0,0,1-.6,0,1.89,1.89,0,0,1-.78-.14,1.19,1.19,0,0,1-.46-.4,1.74,1.74,0,0,1-.24-.62,5.16,5.16,0,0,1-.06-.79Z" transform="translate(0)" />
					<path d="M149.79,52.35a1.06,1.06,0,0,0,.5.82,2.22,2.22,0,0,0,1.08.24,1.66,1.66,0,0,0,1-.25.79.79,0,0,0,.3-.65,1.1,1.1,0,0,0,0-.31.53.53,0,0,0-.15-.26.79.79,0,0,0-.3-.2,1.75,1.75,0,0,0-.51-.14l-.72-.11a6.39,6.39,0,0,1-.84-.2,2.51,2.51,0,0,1-.61-.31,1.21,1.21,0,0,1-.37-.47,1.7,1.7,0,0,1-.13-.67,1.51,1.51,0,0,1,.17-.7,1.67,1.67,0,0,1,.47-.52,2.41,2.41,0,0,1,.71-.33,3.8,3.8,0,0,1,.89-.11,3.56,3.56,0,0,1,1,.12,1.91,1.91,0,0,1,.67.33,1.6,1.6,0,0,1,.4.49,3,3,0,0,1,.21.6l-.86.14a2,2,0,0,0-.17-.4.92.92,0,0,0-.26-.29,1.27,1.27,0,0,0-.4-.17,1.94,1.94,0,0,0-.59-.06,2,2,0,0,0-.65.07,1.14,1.14,0,0,0-.42.19.62.62,0,0,0-.22.26.7.7,0,0,0-.07.31,1.55,1.55,0,0,0,0,.31.53.53,0,0,0,.17.24,1.06,1.06,0,0,0,.33.18,2,2,0,0,0,.53.13l.82.14a2.64,2.64,0,0,1,1.42.56,1.56,1.56,0,0,1,.43,1.18,1.66,1.66,0,0,1-.14.66,1.54,1.54,0,0,1-.43.52,2.05,2.05,0,0,1-.71.35,3.28,3.28,0,0,1-1,.12,3.79,3.79,0,0,1-.95-.1,2.2,2.2,0,0,1-.78-.31,1.64,1.64,0,0,1-.55-.56,1.93,1.93,0,0,1-.23-.85Z" transform="translate(0)" />
					<path d="M159.14,52.09a2.49,2.49,0,0,1-.74,1.52,2.4,2.4,0,0,1-1.68.56,3.16,3.16,0,0,1-1.14-.21,2.14,2.14,0,0,1-.83-.59,2.67,2.67,0,0,1-.5-.94,4.32,4.32,0,0,1-.17-1.24,4,4,0,0,1,.19-1.23,2.7,2.7,0,0,1,.53-1,2.56,2.56,0,0,1,.85-.61,2.74,2.74,0,0,1,1.13-.21A2.08,2.08,0,0,1,159.06,50h0l-.95.17a2.21,2.21,0,0,0-.15-.5,1.12,1.12,0,0,0-.25-.4,1.2,1.2,0,0,0-.4-.26,1.79,1.79,0,0,0-1.32.08,1.38,1.38,0,0,0-.52.47,2.18,2.18,0,0,0-.3.7,4.15,4.15,0,0,0,0,1.72,2,2,0,0,0,.28.7,1.43,1.43,0,0,0,.51.47,1.52,1.52,0,0,0,.78.18,1.37,1.37,0,0,0,1-.35,1.88,1.88,0,0,0,.43-1Z" transform="translate(0)" />
					<path d="M159.92,46.21h1v3a2.52,2.52,0,0,1,.91-.77,2.35,2.35,0,0,1,1.05-.24,1.84,1.84,0,0,1,1.42.52,2.25,2.25,0,0,1,.46,1.54V54h-1V50.42a1.65,1.65,0,0,0-.28-1.1,1.06,1.06,0,0,0-.86-.32,1.67,1.67,0,0,0-.63.11,1.43,1.43,0,0,0-.56.35,1.64,1.64,0,0,0-.41.5,1.41,1.41,0,0,0-.15.69V54h-.94Z" transform="translate(0)" />
					<path d="M165.71,46.21h.95V54h-.95Z" transform="translate(0)" />
					<path d="M171.21,53.22a2.66,2.66,0,0,1-.77.64,2.35,2.35,0,0,1-1.16.26,2.53,2.53,0,0,1-.78-.1,1.6,1.6,0,0,1-.61-.31,1.46,1.46,0,0,1-.4-.53,1.92,1.92,0,0,1-.15-.74,1.64,1.64,0,0,1,.25-.94,1.7,1.7,0,0,1,.65-.57,3.29,3.29,0,0,1,.92-.29,7.47,7.47,0,0,1,1.06-.11l1,0v-.22a1.47,1.47,0,0,0-.31-1,1.32,1.32,0,0,0-1-.32,1.72,1.72,0,0,0-1,.22,1.06,1.06,0,0,0-.41.62l-.86-.1a1.57,1.57,0,0,1,.73-1.13,3,3,0,0,1,1.57-.35,3.22,3.22,0,0,1,1,.14,1.89,1.89,0,0,1,.69.42,1.67,1.67,0,0,1,.37.68,3,3,0,0,1,.11.9V54h-.91Zm0-2-.9,0a6,6,0,0,0-1,.11,1.84,1.84,0,0,0-.6.24.8.8,0,0,0-.32.36,1.43,1.43,0,0,0-.09.5.91.91,0,0,0,.26.68,1.12,1.12,0,0,0,.84.26,1.94,1.94,0,0,0,1.31-.41,1.45,1.45,0,0,0,.47-1.14Z" transform="translate(0)" />
					<path d="M173.13,48.36h.94v.86a2.53,2.53,0,0,1,.92-.8,2.41,2.41,0,0,1,1-.24,1.81,1.81,0,0,1,1.42.52,2.26,2.26,0,0,1,.47,1.54V54h-1V50.43a1.69,1.69,0,0,0-.28-1.11,1.1,1.1,0,0,0-.88-.32,1.88,1.88,0,0,0-.62.11,1.56,1.56,0,0,0-.56.35,1.74,1.74,0,0,0-.42.51,1.47,1.47,0,0,0-.14.69V54h-1Z" transform="translate(0)" />
					<path d="M183,53.19a2.51,2.51,0,0,1-.8.72,2.13,2.13,0,0,1-1.08.26,2.32,2.32,0,0,1-1.07-.23,2.08,2.08,0,0,1-.75-.63,2.68,2.68,0,0,1-.43-.94,4.58,4.58,0,0,1-.15-1.17A4.39,4.39,0,0,1,178.9,50a2.67,2.67,0,0,1,.5-1,2.18,2.18,0,0,1,.78-.6,2.52,2.52,0,0,1,1-.2,2.38,2.38,0,0,1,.91.18,2,2,0,0,1,.85.68V46.21h.95V54H183ZM183,51a2.92,2.92,0,0,0-.14-1.06,1.44,1.44,0,0,0-.49-.63,1.35,1.35,0,0,0-.48-.27,1.68,1.68,0,0,0-.54-.08,1.78,1.78,0,0,0-.65.12,1.2,1.2,0,0,0-.52.39,1.64,1.64,0,0,0-.35.68,3.91,3.91,0,0,0-.13,1,3.41,3.41,0,0,0,.14,1,1.75,1.75,0,0,0,.36.67,1.22,1.22,0,0,0,.5.36,1.56,1.56,0,0,0,.57.11,1.87,1.87,0,0,0,.57-.08,1.56,1.56,0,0,0,1-.91,2.58,2.58,0,0,0,.17-1Z" transform="translate(0)" />
				</svg>

			)}
		</>
	);
};

export default LogoImg;
