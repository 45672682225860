import React, { useContext, useState, useEffect } from 'react';
import { JsonDataContext } from 'context/jsonData';
import { getPageBySlug } from 'utils';
import { CreditThanks } from 'components/CreditThanks';

// content
import APP_DATA from 'utils/jsonAppData';

// components
import { ListBlock } from 'components/ListBlock';

const Credits = () => {
	const { langApp } = useContext(JsonDataContext);
	const { pagesData } = useContext(JsonDataContext);
	const { appBaseData } = useContext(JsonDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const [listBlockData, setListBlockData] = useState(null);

	const getPageData = () => {
		const currentData = getPageBySlug(pagesData, 'credits');
		const preparedCurrentPageData = currentData[0].page_fields;

		setCurrentPageData(preparedCurrentPageData);
	};

	useEffect(() => {
		if (appBaseData) {
			setListBlockData(appBaseData);
		}
	}, [appBaseData]);

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		<>
			<section className="section">
				<div className="section_in">
					<h2 className="section_title offset_mod">{APP_DATA.credits.supporters.title[langApp]}</h2>
					<div className="section_side width_v5_mod">
						<div className="credit_list">
							{APP_DATA.credits.supporters.list.map((item, index) => {
								const mod = item.content.length === 1 ? 'v2_mod' : '';
								return (
									<div className={`credit_item ${mod || ''}`} key={index}>
										<div className="credit_title">{item.title[langApp]}</div>
										<div className="credit_content">
											{item.content.map((subitem, subIndex) => {
												return (
													<div className="credit_content_item" key={subIndex}>
														<div className="credit_content_title">{subitem.title[langApp] ? subitem.title[langApp] : subitem.title }</div>
														{subitem.logos && (
															<div className={`credit_content_logos ${subitem.logos.length > 1 ? 'multiple_mod' : ''}`}>
																{subitem.logos.map((imgSrc, imgIndex) => {
																	return (
																		<div className="credit_content_logo_wrap" key={imgIndex}>
																			<img className="credit_content_logo" src={imgSrc} alt="#" />
																		</div>
																	);
																})}
															</div>
														)}
														{subitem.text && <div className="credit_content_text">{subitem.text}</div>}
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
						<div className="credit_partner_list_wrap">
							<div className="credit_block_title">{APP_DATA.credits.partners.title[langApp]}</div>
							<ul className="credit_partner_list">
								{APP_DATA.credits.partners.list.map((item, index) => {
									return (
										<li className="credit_partner_list_item" key={index}>{item}</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</section>
			<section className="section">
				<div className="section_in">
					<h2 className="section_title offset_mod">{APP_DATA.credits.thanks.title[langApp]}</h2>
					<div className="section_side width_v5_mod">
						<div className="credit_thanks_list">
							{APP_DATA.credits.thanks.list && APP_DATA.credits.thanks.list.map((item, index) => {
								return <CreditThanks data={item} key={index} />;
							})}
						</div>
					</div>
				</div>
			</section>
			{listBlockData && <ListBlock items={listBlockData.address} title={listBlockData.contacts_title[langApp]} />}
		</>
	);
};

export default Credits;
