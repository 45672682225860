import React, {
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import useTracking from 'hooks/useTracking';

import { PopupObject } from 'components/Popup';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Cookies } from 'components/Cookies';
import ProtectedRoute from 'ProtectedRoute';
import { JsonDataContext } from 'context/jsonData';
import * as PAGES from './pages';
import * as POPUPS from './components/Popup';
import { AnimationContext } from './context/animation';

function App() {
	useTracking('G-D2FDYZQ7PZ');

	const {
		appPopups,
		isSetCookies,
		themeState,
		setThemeState,
		setMobileMenuOpen,
		isLandingPopupVisible,
		pagesData,
	} = useContext(JsonDataContext);
	const { windowWidth } = useContext(AnimationContext);
	const [isTimelinePage, setIsTimelinePage] = useState(false);
	const wrapperEl = useRef(null);
	const location = useLocation();
	const $body = document.querySelector('body');

	useEffect(() => {
		setIsTimelinePage(location.pathname === '/timeline' && !isTimelinePage);
		setThemeState((location.pathname.indexOf('/essay') !== -1) && themeState);
		if (windowWidth < 1024) {
			setMobileMenuOpen(false);
		}

		gsap.fromTo(
			wrapperEl.current,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				ease: 'power3.InOut',
				duration: 0.6,
			},
		);
	}, [location]);

	useEffect(() => {
		if (!themeState) {
			$body.classList.remove('dark_mod');
		} else {
			$body.classList.add('dark_mod');
		}
	}, [themeState]);

	return (
		pagesData && (
			<>
				<Header />
				<div className="wrapper" ref={wrapperEl}>
					<div className={`base ${isTimelinePage ? 'timeline_mod' : ''}`}>
						<Switch location={location}>
							<Route component={PAGES.HomePage} path="/" exact />
							<Route component={PAGES.SetLangAndReset} path="/l/:lang" />
							<Route component={PAGES.EventsPage} path="/events" />
							<Route component={PAGES.EventPage} path="/event/:slug" />
							<Route component={PAGES.ThemePage} path="/themes/:slug" exact />
							<Route component={PAGES.ThemesPage} path="/themes" exact />
							<Route component={PAGES.TimelinePage} path="/timeline/:targetId" />
							<Route component={PAGES.TimelinePage} path="/timeline" />
							<Route component={PAGES.ObjectsListPage} path="/objects-list" />
							<Route component={PAGES.AboutPage} path="/about" />
							<Route component={PAGES.EssayPage} path="/essay/:slug" />
							<Route component={PAGES.TemplatePage} path="/page/:slug" />
							<Route component={PAGES.SearchPage} path="/search" />
							<Route component={PAGES.MapPage} path="/map/:targetId" />
							<Route component={PAGES.MapPage} path="/map" />
							<Route component={PAGES.ObjectDashboardPage} path="/object/:slug" />
							<Route component={PAGES.FutureObjectDashboardPage} path="/future-object/:slug" />
							<Route component={PAGES.Credits} path="/credits" />
							<Route component={PAGES.PrivacyPolicyPage} path="/privacy-policy" />
							<Route component={PAGES.VirtualExhibitPage} path="/virtual-exhibit/:targetId" />
							<Route component={PAGES.VirtualExhibitPage} path="/virtual-exhibit" />
							<Route component={PAGES.HomePage} path="/reset-password/:resetToken" exact />

							<Route component={PAGES.TestPage} path="/test-page" exact />

							<ProtectedRoute component={PAGES.MyCollectionPage} path="/my-collection" />
							<ProtectedRoute component={PAGES.MyAccountPage} path="/my-account" />

							<ProtectedRoute component={PAGES.ObjectDashboardPage} path="/admin/object/:slug" isAdmin />
						</Switch>
					</div>
					<Footer
						isTimelinePage={isTimelinePage}
					/>
					<POPUPS.PopupLogin isOpen={appPopups.logIn.isOpen} />
					<POPUPS.PopupTimelineWelcome />
					<POPUPS.PopupResetPassword />
					<POPUPS.PopupSignup isOpen={appPopups.signUp.isOpen} />
					<POPUPS.PopupWelcome isOpen={appPopups.welcome.isOpen} />
					<POPUPS.PopupCalendar isOpen={appPopups.calendar.isOpen} />
					<PopupObject
						isOpen={appPopups.object.isOpen}
						isTimelinePage={isTimelinePage}
					/>
					<POPUPS.PopupVirtual isOpen={appPopups.virtual.isOpen} />
					<POPUPS.PopupVirtualExhibitInfo isOpen={appPopups.virtualExhibitInfo.isOpen} />
					<Cookies />
					<POPUPS.PopupCookieSettings isOpen={appPopups.cookies.isOpen} />
					<POPUPS.PopupPlease isOpen={appPopups.virtualExhibit.isOpen} />
					<POPUPS.PopupLanding isOpen={isLandingPopupVisible} />
				</div>
			</>
		)

	);
}

export default App;
