import React, {
	useState,
	useEffect,
	useContext,
	useRef,
} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { ReactComponent as IconUp } from 'i/icons/up.svg';
import { ReactComponent as IconArrowRight } from 'i/icons/arrow_right.svg';
import { ReactComponent as IconArrowLeft } from 'i/icons/arrow_left.svg';

import { getObjectDetailsFromObjectNumber, getObjectNumberFromObjectId, getSingleHistoricalObjectById } from 'api/object';

import { JsonDataContext } from 'context/jsonData';
import { EPOCHS } from './constant';

const VirtualExhibitPage = () => {
	const {
		langApp,
		showPopupByKey,
		setObjectPopupData,
		appPopups,
	} = useContext(JsonDataContext);

	const [init3DScene, setInit3DScene] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);

	const [activeObject, setActiveObject] = useState(null);
	const [activeObjectNumber, setActiveObjectNumber] = useState(null);

	const [activeRoom, setActiveRoom_] = useState(0);

	const activeRoomRef = React.useRef(activeRoom);
	const setActiveRoom = data => {
		activeRoomRef.current = data;
		setActiveRoom_(data);
	};

	const epochMenu = useRef(null);

	const routeParams = useParams();
	const [targetHash, setTargetHash] = useState(null);
	const history = useHistory();

	const historialObjectMapping = {
		0: {
			1: 1172,
			2: 1173,
			3: 1174,
			4: 1175,
			5: 1176,
			6: 1177,
			7: 1178,
			8: 1179,
		},
		1: {
			1: 1182,
			2: 1183,
			3: 1184,
			4: 1185,
			5: 1186,
		},
		2: {
			1: 1188,
			2: 1189,
			3: 1190,
			4: 1191,
			5: 1193,
			6: 1194,
		},
		3: {
			1: 1195,
			2: 1196,
			3: 1197,
			4: 1198,
			5: 1199,
			6: 1200,
			7: 1201,
			8: 1202,
			9: 1203,
			10: 1204,
		},
		4: {
			1: 1206,
			2: 1207,
			3: 1208,
			4: 1209,
			5: 1210,
			6: 1211,
			7: 1212,
			8: 1213,
			9: 1214,
			10: 1215,
		},
		5: {
			1: 1218,
			2: 1219,
			3: 1220,
			4: 1221,
			5: 1222,
			6: 1223,
			7: 1224,
		},
		6: {
			1: 1227,
			2: 1228,
			3: 1229,
			4: 1230,
			5: 1231,
			6: 1232,
			7: 1233,
			8: 1234,
			9: 1235,
			10: 1236,
		},
	};

	const roomsLinks = [
		{
			link: 'https://my.zrealitysphere.com/api/hosting/B74BOK69CH4RAXMXR0DB1MW6P9C8JU3P/index.html',
			numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			essayLink: '/essay/roman-age-and-middle-ages',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/AZA46PXGMGTB9RDX1T9INYU5PPJD7YGQ/index.html',
			numbers: [10, 11, 12, 13, 14],
			essayLink: '/essay/early-modern',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/8RQWGWEWMFPG6MD3OA6WIR805JV4ECOJ/index.html',
			numbers: [15, 16, 17, 18, 19],
			essayLink: '/essay/absolutism',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/ARD0Y3YRYEKYOLQ3YH6P41NQ410SQLFA/index.html',
			numbers: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
			essayLink: '/essay/19th-century-to-wwi',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/M9FGVKRODBJO952DES3YFUQH2T5USE0I/index.html',
			numbers: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
			essayLink: '/essay/interwar-period',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/P9JTOMXJNVX3IT8IC2NR7XY2E720VMLD/index.html',
			numbers: [45, 46, 47, 48, 49, 50, 51],
			essayLink: '/essay/the-holocaust',
		},
		{
			link: 'https://my.zrealitysphere.com/api/hosting/H4D2EINGV1WLOUZ65I9DU576IUBRF2ZO/index.html',
			numbers: [52, 53, 54, 55, 56, 57, 58],
			essayLink: '/essay/postwar',
		},
	];

	const revealInLastRoom = 7;

	const handleOpenPopup = (objectId, objectSlug, objectType, status, popupName) => {
		showPopupByKey();
		setObjectPopupData({
			id: objectId,
			slug: objectSlug,
			type: objectType,
			status,
		});
		showPopupByKey(popupName, true);
	};

	const getObjectDetails = async number => {
		const { status, data } = await getObjectDetailsFromObjectNumber(number);
		if (status === 'SUCCESS' && data) {
			setActiveObject({
				id: data.id,
				slug: data.slug,
				title: data.title,
				status: data.status,
				type: data.type,
			});
		}
	};

	const getRoomIndexByObjectNumber = targetObjectNumber => {
		let roomIndex = -1;
		let indexInRoom = -1;
		for (let index = 0; index < roomsLinks.length; index += 1) {
			const thisRoom = roomsLinks[index];
			indexInRoom = thisRoom.numbers.indexOf(targetObjectNumber);
			if (indexInRoom > -1) {
				roomIndex = index;
				break;
			}
		}
		return {
			roomIndex,
			indexInRoom,
		};
	};

	const startInitialization = targetObjectNumber => {
		setActiveObjectNumber(targetObjectNumber);
		const roomIndexDetails = getRoomIndexByObjectNumber(targetObjectNumber);
		if (roomIndexDetails.roomIndex !== -1 && roomIndexDetails.indexInRoom !== -1) {
			setActiveRoom(roomIndexDetails.roomIndex);
			setInit3DScene(true);
		} else {
			alert('Invalid request.');
		}
	};

	const handleArrowNavigation = async direction => {
		setFirstLoad(false);

		const roomIndexDetails = getRoomIndexByObjectNumber(activeObjectNumber);

		if (direction === 'LEFT' && roomIndexDetails.indexInRoom === 0) {
			return false;
		}

		let toBeActiveObjectNumber = parseInt(activeObjectNumber, 10);
		if (direction === 'LEFT') {
			toBeActiveObjectNumber = parseInt(activeObjectNumber, 10) - 1;
		} else if (direction === 'RIGHT') {
			toBeActiveObjectNumber = parseInt(activeObjectNumber, 10) + 1;
		}

		setActiveObjectNumber(toBeActiveObjectNumber);

		return true;
	};

	const showEpochMenu = (e, status = null) => {
		e.preventDefault();
		if (status === null) {
			epochMenu.current.classList.toggle('active_mod');
		} else {
			epochMenu.current.classList.toggle('active_mod', status);
		}
	};

	const listenMessages = async ({ data: { annotationID, type } }) => {
		if (type === 'customAnnotationTriggered') {
			console.log(type, annotationID, activeRoomRef.current);

			let targetObjectNumber = null;
			let targetAction = null;
			let targetType = null;

			if (annotationID.indexOf('IntroText') > -1) {
				targetAction = 'IntroText';
			} else if (annotationID.indexOf('Object') > -1) {
				[, targetAction] = (annotationID.split('_'));
				targetType = 'published';
				targetObjectNumber = parseInt(((annotationID.split('_'))[0]).replace('Object', ''), 10);
				targetObjectNumber -= 1;
				if (activeRoomRef.current === 5 && targetObjectNumber === 0 && parseInt(((annotationID.split('_'))[0].split('.'))[1], 10) === 5) {
					targetObjectNumber = 4;
				}
				if (activeRoomRef.current === 5 && (annotationID === 'Object01.005_info.006' || annotationID === 'Object01.005_detail.006')) {
					targetObjectNumber = 6;
				}
				targetObjectNumber = roomsLinks[activeRoomRef.current].numbers[targetObjectNumber];
				if (targetAction.indexOf('info') > -1) targetAction = 'info';
				if (targetAction.indexOf('detai') > -1) targetAction = 'detail';
			} else if (annotationID.indexOf('Picture') > -1) {
				targetAction = 'info';
				targetType = 'historical';
				targetObjectNumber = parseInt(annotationID.replace('Picture', ''), 10);
				targetObjectNumber = historialObjectMapping[activeRoomRef.current][targetObjectNumber];
			} else if (annotationID.indexOf('EnterRoom') > -1) {
				targetAction = 'EnterRoom';
				targetObjectNumber = parseInt(annotationID.replace('EnterRoom', ''), 10);
				targetObjectNumber -= 1;
			} else if (annotationID.indexOf('LeaveRoom') > -1) {
				targetAction = 'LeaveRoom';
				targetObjectNumber = parseInt(annotationID.replace('LeaveRoom', ''), 10);
				targetObjectNumber -= 1;
			}

			console.log('After processing', targetObjectNumber, targetAction, targetType);

			if (['info', 'detail'].indexOf(targetAction) > -1) {
				if (targetType === 'historical') {
					const { data } = await getSingleHistoricalObjectById(targetObjectNumber);
					if (data && targetAction === 'info') {
						handleOpenPopup(data.id, data.slug, data.type, data.status, 'object');
					}
				} else {
					const { status, data } = await getObjectDetailsFromObjectNumber(targetObjectNumber);
					if (status === 'SUCCESS' && data) {
						if (targetAction === 'info') {
							handleOpenPopup(data.id, data.slug, data.type, data.status, 'object');
						} else if (targetAction === 'detail') {
							handleOpenPopup(data.id, data.slug, data.type, data.status, 'virtual');
						}
					}
				}
			} else if (targetAction === 'IntroText') {
				window.open(roomsLinks[activeRoomRef.current].essayLink);
			} else if (targetAction === 'EnterRoom' || targetAction === 'LeaveRoom') {
				setInit3DScene(false);
				setFirstLoad(true);
				startInitialization(roomsLinks[targetObjectNumber].numbers[0]);
			}
		}
	};

	const handleRoomChange = (e, index) => {
		e.preventDefault();
		setInit3DScene(false);
		setFirstLoad(true);
		startInitialization(roomsLinks[index].numbers[0]);
		showEpochMenu(e, false);
	};

	const getTeleporterId = () => {
		const teleporterId = roomsLinks[activeRoom].numbers.indexOf(activeObjectNumber) + 1;

		if ((teleporterId === 1 && activeRoom === 1)) return 0;
		if ((teleporterId === 1 && activeRoom === 3)) return 0;

		if ((teleporterId === 3 && activeRoom === 1)) return 1;

		return teleporterId;
	};

	const getRevealInLastRoom = () => {
		if (activeRoom === 0) return 0;
		if (activeRoom === 1) return 5;
		if (activeRoom === 2) return 5;
		if (activeRoom === 3) return 13;
		if (activeRoom === 4) return 12;
		if (activeRoom === 5) return 7;
		return revealInLastRoom;
	};

	useEffect(() => {
		if (routeParams && routeParams.targetId && routeParams.targetId !== '') {
			setTargetHash(routeParams.targetId);
			history.replace('/virtual-exhibit');
		} else {
			startInitialization(1);
		}
	}, []);

	useEffect(async () => {
		if (targetHash && targetHash !== '') {
			const { status, data } = await getObjectNumberFromObjectId(targetHash);
			if (status === 'SUCCESS' && data) {
				startInitialization(parseInt(data.objectNumber, 10));
			}
		}
	}, [
		targetHash,
	]);

	useEffect(() => {
		const eventMethods = window.addEventListener ? ['addEventListener', 'removeEventListener'] : ['attachEvent', 'detachEvent'];

		const addEventer = window[eventMethods[0]];
		const removeEventer = window[eventMethods[1]];
		const messageEvent = eventMethods[0] === 'attachEvent' ? 'onmessage' : 'message';

		addEventer(messageEvent, listenMessages, false);

		return () => {
			removeEventer(messageEvent, listenMessages, false);
		};
	}, []);

	useEffect(() => {
		if (activeObjectNumber && activeObjectNumber !== 0) {
			const iFrameEl = document.getElementById('ifrm');
			if (iFrameEl) {
				iFrameEl.contentWindow.postMessage({
					command: 'teleport',
					teleporter: `${getTeleporterId()}`,
				}, '*');
			}

			getObjectDetails(activeObjectNumber);
		}
	}, [
		activeObjectNumber,
	]);

	const roomIndexDetails = getRoomIndexByObjectNumber(activeObjectNumber);

	return (
		<>
			<section className="section bg_mod height_mod" style={{ padding: '0px' }}>
				{init3DScene && (
					<iframe
						title="visualizer-iframe"
						id="ifrm"
						className="visualizer-iframe"
						src={`${roomsLinks[activeRoom].link}?language=${langApp === 'en' ? langApp : 'ger'}&reveal=${getRevealInLastRoom()}${(firstLoad && roomIndexDetails.indexInRoom === 0 && (activeRoom !== 2 && activeRoom !== 5 && activeRoom !== 6)) ? '' : `#tp${getTeleporterId()}`}`}
						style={{
							display: 'block',
							width: '100%',
							height: 'calc(100vh - 15.6rem)',
						}}
					/>
				)}
				{activeObject && (
					<div className="section_in pos_mod">
						<div className="virtual_nav_wrap">
							<div className="virtual_nav">
								<div className="virtual_epochs">
									<a className="virtuals_epochs_btn" href="#" onClick={showEpochMenu}>
										<div className="virtuals_epochs_icon">
											<IconUp className="icon size_mod" />
										</div>
										<div className="virtuals_epochs_btn_title">{EPOCHS[langApp][activeRoom]}</div>
									</a>
								</div>
								<div className="virtual_control_wrap">
									<button type="button" className={`virtual_control prev_mod ${(roomIndexDetails.indexInRoom === 0) ? 'hidden_mod' : ''}`} onClick={() => handleArrowNavigation('LEFT')}>
										<IconArrowLeft
											className="icon virtual_mod"
										/>
									</button>
									<div className="virtual_control_title">{activeObject.title[langApp]}</div>
									<button type="button" className={`virtual_control next_mod ${((roomIndexDetails.indexInRoom === (roomsLinks[activeRoom].numbers.length - 1)) || (roomIndexDetails.indexInRoom === (revealInLastRoom - 1) && roomIndexDetails.roomIndex === (roomsLinks.length - 1))) ? 'hidden_mod' : ''}`} onClick={() => handleArrowNavigation('RIGHT')}>
										<IconArrowRight
											className="icon virtual_mod"
										/>
									</button>
								</div>
								<a className="btn_v10" href="#" onClick={() => handleOpenPopup(activeObject.id, activeObject.slug, activeObject.type, activeObject.status, 'object')}>{langApp === 'en' ? 'object Details' : 'Mehr Erfahren'}</a>
							</div>
							<div className="virtual_nav_question_wrap">
								<a className="virtual_nav_question" href="#" onClick={() => showPopupByKey('VirtualExhibitInfo', true)}>?</a>
							</div>
							<div className="virtual_epochs_menu" ref={epochMenu}>
								{EPOCHS[langApp].map((item, index) => {
									return <Link disabled={index > 6} href="#" className={`virtual_epochs_item ${index === activeRoom ? 'active_mod' : ''}`} onClick={e => handleRoomChange(e, index)} style={(index > 6) ? { pointerEvents: 'none', cursor: 'none' } : {}}>{item}</Link>;
								})}
							</div>
						</div>
					</div>
				)}
			</section>
		</>
	);
};

export default VirtualExhibitPage;
