import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { JsonDataContext } from 'context/jsonData';

// components
import { ReactComponent as IconClose } from 'i/icons/close_2.svg';
// content
import APP_DATA from 'utils/jsonAppData';

const PopupVirtualExhibitInfo = ({ isOpen }) => {
	const {
		langApp,
		showPopupByKey,
	} = useContext(JsonDataContext);

	const handleClosePopup = () => {
		showPopupByKey();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			mountOnEnter
			unmountOnExit
		>
			<div className="popup popupLogin">
				<div className="overlay" />
				<div
					className="popup_in v2_mod"
					style={{
						width: '100rem',
					}}
				>
					<button className="popup_close" type="button" onClick={() => handleClosePopup()}>
						<IconClose className="icon icon-close_2 size_mod" />
					</button>
					<div
						className="popup_content v2_mod"
						style={{
							maxWidth: '90rem',
						}}
					>
						<img src={window.location.origin + APP_DATA.virtualExhibitInfoPopup.image[langApp]} alt="title" style={{ maxWidth: '100%' }} />
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default PopupVirtualExhibitInfo;
