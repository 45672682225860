import React, { useState, useEffect } from 'react';
import { Filters } from 'components/Filters';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, useAlert } from 'components/Alert';
import { Map } from 'components/Map';

import { getMapData } from 'api/data';

const MapPage = () => {
	const [mapDefaultPoints, setMapDefaultPoints] = useState(false);
	const [mapFilteredPoints, setMapFilteredPoints] = useState(false);
	const [alert, showAlert, hideAlert] = useAlert();
	const [mapPoints, setMapPoints] = useState(false);
	const routeParams = useParams();
	const [targetHash, setTargetHash] = useState(null);
	const [mapCenter, setMapCanter] = useState(null);

	const history = useHistory();

	useEffect(() => {
		if (routeParams && routeParams.targetId && routeParams.targetId !== '' && mapPoints) {
			setTargetHash(routeParams.targetId);
			history.replace('/map');
		}
	}, [
		routeParams,
		mapPoints,
	]);

	useEffect(() => {
		if (targetHash && targetHash !== '') {
			const targetObject = document.getElementById(`map-marker-${targetHash}`);
			if (targetObject) {
				targetObject.click();
			}
		}
	}, [
		targetHash,
	]);

	const createMapMarkers = mapPointsData => {
		const pointsArray = [];
		mapPointsData.forEach(({
			ID,
			name,
			latlng,
			type,
			status,
			slug,
		}) => {
			if (typeof latlng !== 'undefined' && latlng.length > 0) {
				if (!isNaN(parseFloat(latlng[1])) && !isNaN(parseFloat(latlng[0]))) {
					const isActiveTemp = parseInt(targetHash, 10) === parseInt(ID, 10);

					pointsArray.push({
						type: 'Feature',
						properties: {
							cluster: true,
							id: ID,
							text: name,
							type,
							status,
							slug,
						},
						geometry: {
							type: 'Point',
							coordinates: [
								parseFloat(latlng[0]),
								parseFloat(latlng[1]),
							],
						},
						isActive: isActiveTemp,
					});

					if (isActiveTemp) {
						setMapCanter({ lat: parseFloat(latlng[0]), lng: parseFloat(latlng[1]) });
					}
				}
			}

			return false;
		});

		setMapPoints(pointsArray);
	};

	const fetchAllMapData = async () => {
		try {
			const res = await getMapData();

			setMapDefaultPoints(res.data);
			setMapFilteredPoints(res.data);
		} catch (err) {
			showAlert(err.message, 'danger');
		}
	};

	useEffect(() => {
		fetchAllMapData();
	}, []);

	useEffect(() => {
		if (mapFilteredPoints) {
			createMapMarkers(mapFilteredPoints);
		}
	}, [mapFilteredPoints, targetHash]);

	return (
		<section className="section no_offset_mod map_mod">
			<div className="section_in">
				<Filters
					isMapPage
					allObjects={mapDefaultPoints}
					allFilteredObjects={mapFilteredPoints}
					setAllFilteredObjects={setMapFilteredPoints}
					v3Mod
				/>
				{alert.visible ? (
					<Alert
						alert={alert}
						hide={hideAlert}
					/>
				) : null}
			</div>
			{mapPoints ? (
				<Map
					mapPoints={mapPoints}
					mapCenter={mapCenter}
				/>
			) : null}
		</section>
	);
};

export default MapPage;
