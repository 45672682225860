import React, {
	useContext,
	useState,
	useEffect,
	useCallback,
	useRef,
} from 'react';
// import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getPageBySlug } from 'utils';
import { JsonDataContext } from 'context/jsonData';

// // components
import ThemesHeroSection from 'components/Sections/ThemesHeroSection';
import { ExploreBlock } from 'components/ExploreBlock';
import { VisitBlock } from 'components/VisitBlock';
import APP_DATA from 'utils/jsonAppData';
import { THEMES_ARRAY } from './page_array';

const ThemePage = () => {
	const {
		langApp,
		pagesData,
	} = useContext(JsonDataContext);

	const [currentPageData, setCurrentPageData] = useState(null);
	const [homePageData, setHomePageData] = useState(null);

	const themesSectionRef = useRef(null);

	const scrollToThemesSection = () => {
		const headerOffset = 0;
		const elementPosition = themesSectionRef.current.getBoundingClientRect().top;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		});
	};

	const getCurrentPageData = useCallback(() => {
		let fetchedPageData = getPageBySlug(pagesData, 'themes');
		fetchedPageData = fetchedPageData[0].page_fields;

		setCurrentPageData(fetchedPageData);
	}, [pagesData]);

	const getHomePageData = useCallback(() => {
		let fetchedHomePageData = getPageBySlug(pagesData, 'home');
		fetchedHomePageData = fetchedHomePageData[0].page_fields;

		setHomePageData(fetchedHomePageData);
	}, [pagesData]);

	useEffect(() => {
		if (pagesData) {
			getCurrentPageData();
			getHomePageData();
		}
	}, [pagesData, getCurrentPageData, getHomePageData]);

	return (
		currentPageData && (
			<>
				{currentPageData.title && (
					<Helmet>
						<title>{currentPageData.title[langApp]}</title>
					</Helmet>
				)}
				<ThemesHeroSection
					title={currentPageData.title[langApp]}
					subtitle={currentPageData.sub_title[langApp]}
					img={currentPageData.featured_image}
					text={currentPageData.sub_title_two[langApp]}
					colLeftText={currentPageData.bottom_left[langApp]}
					colRightText={currentPageData.bottom_right[langApp]}
					ButtonText={THEMES_ARRAY.themesHeroArray.buttonTitle[langApp]}
					onButtonClick={scrollToThemesSection}
				/>
				<section className="section themes_the_themes_section" ref={themesSectionRef}>
					<div className="section_in">
						<div className="section_title decor_mod offset_mod">{THEMES_ARRAY.theThemesSection.title[langApp]}</div>
						<div className="section_side width_v5_mod">
							<div className="section_row right_offset_mod">
								<div className="section_col col_2_mod">
									<div className="section_descr">
										{ currentPageData.left_column[langApp]}
									</div>
									<a className="btn_v4" rel="noreferrer" href={`/themes/${currentPageData.all_themes[0].slug}`}>{THEMES_ARRAY.theThemesSection.buttonTitle[langApp]}</a>
								</div>
								<div className="section_col col_2_mod">
									{currentPageData.all_themes.length && (
										<ul className="themes_post_filter">
											{currentPageData.all_themes.map(theme => (
												<li key={theme.id}>
													<Link to={`/themes/${theme.slug}`}>
														{theme.title[langApp]}
														<svg xmlns="http://www.w3.org/2000/svg" width="55.414" height="22.414" viewBox="0 0 55.414 22.414">
															<g transform="translate(0 0.707)">
																<path d="M7.5,18h54" transform="translate(-7.5 -7.5)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2" />
																<path d="M18,7.5,28.5,18,18,28.5" transform="translate(25.5 -7.5)" fill="none" stroke="#000" strokeWidth="2" />
															</g>
														</svg>
													</Link>
												</li>
											))}
										</ul>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section themes_acknowledgments_section">
					<div className="section_in">
						<h2 className="section_title offset_mod">{THEMES_ARRAY.acknowledgementSection.title[langApp]}</h2>

						<div className="section_side width_v5_mod">
							<div className="credit_partner_list_wrap">
								<div className="credit_block_title">{currentPageData.set_one.label[langApp]}</div>
								<ul className="credit_partner_list">
									{currentPageData.set_one.items.map((item, index) => {
										return (
											<li className="credit_partner_list_item" key={index}>{item.item[langApp]}</li>
										);
									})}
								</ul>
							</div>
						</div>

						<div className="section_side width_v5_mod">
							<div className="credit_partner_list_wrap">
								<div className="credit_block_title">{currentPageData.set_two.label[langApp]}</div>
								<ul className="credit_partner_list">
									{currentPageData.set_two.items.map((item, index) => {
										return (
											<li className="credit_partner_list_item" key={index}>{item.item[langApp]}</li>
										);
									})}
								</ul>
							</div>
						</div>

					</div>
				</section>
				<ExploreBlock
					title={homePageData.explore_our_objects_title[langApp]}
					exploreBlockData={APP_DATA.homePage.exploreOurObjects}
					widthV1Mod
					leftMod
					rightOffsetMod
					col3Mod
				/>
				<VisitBlock
					title={homePageData.visit_exhibit_home_title[langApp]}
					buttonTitle={homePageData.visit_exhibit_home_btn_text[langApp]}
					buttonUrl={homePageData.visit_exhibit_home_btn_link}
					img={APP_DATA.virtualRoomSection.img}
					widthV1Mod
				/>
			</>
		)
	);
};

export default ThemePage;
