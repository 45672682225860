/* eslint-disable */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { JsonDataContext } from 'context/jsonData';

import { getType } from 'utils';

import { ReactComponent as IconNavigation } from 'i/icons/navigation.svg';
import { ReactComponent as IconClock } from 'i/icons/clock.svg';
import { ReactComponent as IconDocument } from 'i/icons/document_2.svg';
import { ReactComponent as IconArrowRight } from 'i/icons/arrow_right.svg';
import { ReactComponent as IconTheme } from 'i/icons/theme.svg';

const MopedInfoItem = ({
	type,
	value,
	linkMod,
	essaysLink,
	firstThemeSlug,
	objectId,
}) => {
	const { langApp } = useContext(JsonDataContext);

	const checkTypeValue = itemValue => {
		if (getType(itemValue) === 'Array') {
			if (getType(itemValue[0]) === 'Object') {
				return itemValue.map(v => v[langApp].replace(/by |von /g,'')).join(', ');
			}
			return itemValue.join(', ');
		} else if (getType(itemValue) === 'Object') {
			return itemValue[langApp];
		} else {
			return itemValue;
		}
	};

	const preparedType = type && type.toLowerCase();

	const typeText = {
		origin: {
			en: 'Origin',
			de: 'Ursprung',
		},
		year: {
			en: 'Year',
			de: 'Jahr',
		},
		essays: {
			en: 'Essays',
			de: 'Essays',
		},
		themes: {
			en: 'Themes',
			de: 'Themen',
		},
	};

	let url;

	if (preparedType === 'origin') {
		url = '/map';
	} else if (preparedType === 'year') {
		url = '/timeline';
	} else if (preparedType === 'essays') {
		url = essaysLink;
	}
	
	if (
		(type === 'essays' && !value.length) ||
		(type === 'mappedThemes' && !value.length)
	) {
		return null;
	}

	return (
		<>
			{type && (
				<div className="moped_info_item">

					<div className="moped_info_icon">
						{preparedType === 'origin' && <IconNavigation className="icon icon-navigation size_mod" />}
						{preparedType === 'year' && <IconClock className="icon icon-clock size_mod" />}
						{preparedType === 'essays' && <IconDocument className="icon icon-document_2 size_mod" />}
						{preparedType === 'mappedthemes' && <IconTheme className="icon icon-theme size_mod" />}
					</div>

					{preparedType === 'origin' && <span className="mopen_info_type">{typeText.origin[langApp]}</span>}
					{preparedType === 'year' && <span className="mopen_info_type">{typeText.year[langApp]}</span>}
					{preparedType === 'essays' && <span className="mopen_info_type">{typeText.essays[langApp]}</span>}
					{preparedType === 'mappedthemes' && <span className="mopen_info_type">{typeText.themes[langApp]}</span>}

					{
						preparedType === 'origin' && (
							<>
								<Link target="_blank" className="moped_info_value" to={`/map/${objectId}`}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point" to={`/map/${objectId}`}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}

					{
						preparedType === 'year' && (
							<>
								<Link target="_blank" className="moped_info_value" to={`/timeline/${objectId}`}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point" to={`/timeline/${objectId}`}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}

					{
						preparedType === 'essays' && (
							<>
								<Link target="_blank" className="moped_info_value" to={essaysLink}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point" to={essaysLink}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}

					{
						preparedType === 'mappedthemes' && (
							<>
								<Link target="_blank" className="moped_info_value" to={`/themes/${value[0].slug}`}>{value.map(v => v.title[langApp]).join(', ')}</Link>
								<Link target="_blank" className="moped_info_icon_point" to={`/themes/${value[0].slug}`}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}

				</div>
			)}
		</>
	);
};

export default MopedInfoItem;
/* eslint-disable */
