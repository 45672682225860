import React, { useContext, useEffect, useState } from 'react';

import { JsonDataContext } from 'context/jsonData';
import { getRecentEvents, getUpcomingEvents } from 'api/event';

// components
import { Banner } from 'components/Banner';
import { ListBlock } from 'components/ListBlock';
import { EventsContentBlock } from 'components/EventsContentBlock';
import { Event } from 'components/Event';
import { Alert, useAlert } from 'components/Alert';
import { Spinner } from 'components/Spinner';
import APP_DATA from 'utils/jsonAppData';
import { getPageBySlug } from 'utils';
import { LoadMoreButton } from 'components/Buttons';

const EventsPage = () => {
	const { pagesData } = useContext(JsonDataContext);
	const { langApp } = useContext(JsonDataContext);
	const [alert, showAlert, hideAlert] = useAlert();
	const [currentPageData, setCurrentPageData] = useState(null);
	const { appBaseData } = useContext(JsonDataContext);
	const [listBlockData, setListBlockData] = useState(null);
	const [upcomingEvents, setUpcomingEvents] = useState({
		events: [],
		page: 0,
		load: 5,
		isDisabled: false,
		isLoading: true,
		isBtnLoading: false,
		error: false,
	});
	const [recentEvents, setRecentEvents] = useState({
		events: [],
		page: 0,
		load: 5,
		isDisabled: false,
		isLoading: true,
		isBtnLoading: false,
		error: false,
	});

	const setAboutPageData = () => {
		const currentData = getPageBySlug(pagesData, 'about');
		const preparedCurrentPageData = currentData[0].page_fields;

		setCurrentPageData(preparedCurrentPageData);
	};

	const fetchUpcomingEvents = async (fromLoadMoreButton = false) => {
		try {
			if (fromLoadMoreButton) {
				setUpcomingEvents({
					...upcomingEvents,
					isBtnLoading: true,
				});
			}

			const res = await getUpcomingEvents(
				upcomingEvents.page + 1,
				upcomingEvents.load,
			);

			setUpcomingEvents({
				...upcomingEvents,
				page: upcomingEvents.page + 1,
				isLoading: false,
				isBtnLoading: false,
				events: [...upcomingEvents.events, ...res.data.list],
				isDisabled: (upcomingEvents.events.length + res.data.list.length) >= res.data.total,
			});
		} catch (err) {
			showAlert(err.message, 'danger');
			setUpcomingEvents({
				...upcomingEvents, error: true, isLoading: false,
			});
		}
	};

	const fetchRecentEvents = async (fromLoadMoreButton = false) => {
		try {
			if (fromLoadMoreButton) {
				setRecentEvents({
					...recentEvents,
					isBtnLoading: true,
				});
			}

			const res = await getRecentEvents(
				recentEvents.page + 1,
				recentEvents.load,
			);

			setRecentEvents({
				...recentEvents,
				page: recentEvents.page + 1,
				isLoading: false,
				isBtnLoading: false,
				events: [...recentEvents.events, ...res.data.list],
				isDisabled: (recentEvents.events.length + res.data.list.length) >= res.data.total,
			});
		} catch (err) {
			showAlert(err.message, 'danger');
			setRecentEvents({
				...recentEvents, error: true, isLoading: false,
			});
		}
	};

	useEffect(() => {
		fetchUpcomingEvents();
		fetchRecentEvents();
	}, []);

	useEffect(() => {
		setAboutPageData();
		if (appBaseData) {
			setListBlockData(appBaseData);
		}
	}, [appBaseData]);

	const NoEvents = () => {
		return (
			<div className="no-more-events">
				<p>{APP_DATA.eventsPage.noEvents.title[langApp]}</p>
			</div>
		);
	};

	return (
		<>
			<section className="section">
				<div className="section_in">
					<div className="section_row">
						<div className="section_col col_3_mod" />
						<div className="section_col col_4_mod">
							<h2 className="section_title decor_mod offset_mod">
								{APP_DATA.eventsPage.upcomingEvents.title[langApp]}
							</h2>
						</div>
					</div>
					<div className="events_list">
						{upcomingEvents.isLoading && <Spinner limitColMod />}
						{!upcomingEvents.isLoading && (
							upcomingEvents.events.length > 0 ? (
								upcomingEvents.events.map(
									({
										ID,
										title,
										excerpt,
										image,
										information_text,
										register_info,
										add_to_calendar,
										register_btn,
										schedule,
										short_schedule,
										slug,
									}, i) => {
										return (
											<Event
												key={ID}
												id={ID}
												title={title[langApp]}
												descr={excerpt && excerpt[langApp]}
												image={image}
												registerInfo={register_info[langApp]}
												registerBtnTitle={
													register_btn.title[langApp]
												}
												registerBtnLink={register_btn.link}
												calendarTitle={
													add_to_calendar.title[langApp]
												}
												calendarDescription={
													add_to_calendar.description[langApp]
												}
												calendarLocation={
													add_to_calendar.location.address
												}
												calendarDate={add_to_calendar.date}
												date={schedule[0]}
												schedule={short_schedule}
												lang={langApp}
												slug={slug}
												isLastItem={i === (upcomingEvents.events.length - 1)}
											/>
										);
									},
								)
							) : <NoEvents />
						)}
					</div>

					<div className="event_load_more">
						{!upcomingEvents.isDisabled && (
							<LoadMoreButton
								onClick={() => fetchUpcomingEvents(true)}
								isLoading={upcomingEvents.isBtnLoading}
							/>
						)}
					</div>

					<div className="past_events">
						<div className="section_row">
							<div className="section_col col_3_mod" />
							<div className="section_col col_4_mod">
								<h2 className="section_title decor_mod offset_mod">
									{APP_DATA.eventsPage.pastEvents.title[langApp]}
								</h2>
							</div>
						</div>

						<div className="events_list">
							{recentEvents.isLoading && <Spinner limitColMod />}
							{!recentEvents.isLoading && (
								recentEvents.events.length > 0 ? (
									recentEvents.events.map(
										({
											ID,
											title,
											excerpt,
											image,
											add_to_calendar,
											schedule,
											short_schedule,
											slug,
										}, i) => {
											return (
												<Event
													key={ID}
													id={ID}
													title={title[langApp]}
													descr={excerpt && excerpt[langApp]}
													image={image}
													calendarTitle={
														add_to_calendar.title[langApp]
													}
													calendarDescription={
														add_to_calendar.description[langApp]
													}
													calendarLocation={
														add_to_calendar.location.address
													}
													calendarDate={add_to_calendar.date}
													date={schedule[0]}
													schedule={short_schedule}
													lang={langApp}
													slug={slug}
													isLastItem={i === (recentEvents.events.length - 1)}
												/>
											);
										},
									)
								) : <NoEvents />
							)}
						</div>
					</div>

					<div className="event_load_more">
						{!recentEvents.isDisabled && (
							<LoadMoreButton
								onClick={() => fetchRecentEvents(true)}
								isLoading={recentEvents.isBtnLoading}
							/>
						)}
					</div>

					{alert.visible ? (
						<Alert alert={alert} hide={hideAlert} limitColMod />
					) : null}
				</div>
			</section>
			{/* TODO : fix banner */}
			<Banner
				bannerArray={APP_DATA.eventsPage.bannerArray}
				indentMod
				bgMod
				widthV2Mod
				rightMod
				wrapWidthV2Mod
				titleColV2Mod
				titleColorMod
				sizeV2Mod
				limitV2Mod
				textColV2Mod
				descrColorMod
				descrOffsetMod
				bottomDescrColorMod
				bottomDescrOffsetMod
			/>
			{currentPageData && (
				<EventsContentBlock
					title={currentPageData.institute_title[langApp]}
					rowTitle={currentPageData.institute_subtitle[langApp]}
					descrCol1={currentPageData.institute_left_text[langApp]}
					descrCol2={currentPageData.institute_right_text[langApp]}
					btnTitle={currentPageData.institute_button.title[langApp]}
					btnUrl={currentPageData.institute_button.link.url}
				/>
			)}
			{listBlockData && (
				<ListBlock
					items={listBlockData.address}
					title={listBlockData.contacts_title[langApp]}
				/>
			)}
		</>
	);
};

export default EventsPage;
