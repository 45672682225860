import React, {
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react';
import classNames from 'classnames';

import { ReactComponent as IconFilter2 } from 'i/icons/filter_1.svg';

import { JsonDataContext } from 'context/jsonData';
import { AnimationContext } from 'context/animation';
import { getType, uncheckAllFilterOption, generateRandomString } from 'utils';
import APP_DATA from 'utils/jsonAppData';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import FilterActionButton from './FilterActionButton';
import FilterSelect from './FilterSelect';
import FilterLabelList from './FilterLabelList';

const Filters = ({
	allObjects,
	allFilteredObjects,
	setAllFilteredObjects,
	setPageFilterApplied,
	isMapPage,
	isObjectsPage,
	v2Mod,
	v3Mod,
}) => {
	const { langApp, filtersData } = useContext(JsonDataContext);
	const { windowWidth } = useContext(AnimationContext);
	const [updatedFiltersData, setUpdatedFiltersData] = useState({});

	const [isFilterLabelsOpen, setFilterLabelsOpen] = useState(false);
	const [isFilterApplied, setFilterApplied] = useState(false);
	const [isFilterDropDownOpen, setFilterDropDownOpen] = useState(true);

	const filterListWrapEl = useRef(null);
	const windowWidthUpdated = useRef(windowWidth);

	const filtersWrapClasses = classNames('filters_wrap', {
		v2_mod: v2Mod,
		v3_mod: v3Mod,
	});

	const toggleFilterLabelsVisibility = () => {
		if (isFilterApplied) {
			setFilterLabelsOpen(!isFilterLabelsOpen);
		}
	};

	const handleDomClick = e => {
		if (filterListWrapEl.current && filterListWrapEl.current.contains(e.target)) {
			return;
		}

		if (windowWidthUpdated.current < TABLET_MEDIA_POINT) {
			setFilterDropDownOpen(false);
		}
	};

	// CLEAR FILTER FUNCTIONALITY
	const clearAllFilters = () => {
		const copiedObjectsList = [...allObjects];
		const updatedFilterData = uncheckAllFilterOption(updatedFiltersData);

		setFilterLabelsOpen(false);
		setAllFilteredObjects(copiedObjectsList);
		setUpdatedFiltersData(updatedFilterData);
		setFilterApplied(false);

		if (setPageFilterApplied) {
			setPageFilterApplied(false);
		}
	};

	const filterDataModification = () => {
		const updatedFilters = {};

		Object.keys(filtersData).map(filter => {
			if (isObjectsPage && filter === 'object_type') {
				return false;
			}

			if (isMapPage && filter === 'object_type') {
				return false;
			}

			const selectType = filter;
			const selectTitle = filtersData[filter].title;
			const selectOptions = getType(filtersData[filter].options) === 'Array' ? filtersData[filter].options : [];
			const updatedSelectFilter = {};

			updatedSelectFilter[selectType] = {
				title: selectTitle,
			};

			const res = selectOptions.map(option => {
				const optionId = option.ID !== undefined ? option.ID : generateRandomString(6);
				return {
					...option,
					ID: optionId,
					type: selectType,
					checked: false,
				};
			});

			updatedSelectFilter[selectType].options = res;
			updatedFilters[selectType] = updatedSelectFilter[selectType];

			return updatedSelectFilter;
		});

		setUpdatedFiltersData(updatedFilters);
	};

	const showFiltersDropDown = () => {
		setFilterDropDownOpen(!isFilterDropDownOpen);
	};

	useEffect(() => {
		if (filtersData) {
			filterDataModification();
		}
	}, [filtersData]);

	useEffect(() => {
		windowWidthUpdated.current = windowWidth;
		if (windowWidth < TABLET_MEDIA_POINT && isFilterDropDownOpen) {
			setFilterDropDownOpen(false);
		}
		if (windowWidth >= TABLET_MEDIA_POINT && !isFilterDropDownOpen) {
			setFilterDropDownOpen(true);
		}
	}, [windowWidth]);

	useEffect(() => {
		document.addEventListener('mousedown', handleDomClick);

		return () => {
			document.removeEventListener('mousedown', handleDomClick);
		};
	}, []);

	if ((2 - 1) === 1) {
		return null;
	}

	return (
		<>
			<div className={filtersWrapClasses}>
				<div className="filters_list_w" ref={filterListWrapEl}>
					<button className="filters_mob_btn" type="button" onClick={showFiltersDropDown}>
						{APP_DATA.filters.buttonShowAllFilterLabels[langApp]}
						<div className="filters_icon">
							<IconFilter2 className="icon icon-filter_1 size_mod" />
						</div>
					</button>
					{isFilterDropDownOpen && (
						<div className="filters_list">
							<FilterActionButton
								title={APP_DATA.filters.buttonShowAllFilterLabels[langApp]}
								onClick={toggleFilterLabelsVisibility}
							/>
							{updatedFiltersData ? (
								Object.keys(updatedFiltersData).map((filter, index) => {
									return (
										<div className="filters_item select_mod" key={index}>
											<FilterSelect
												key={index}
												title={updatedFiltersData[filter].title}
												selectType={filter}
												allObjects={allObjects}
												options={updatedFiltersData[filter].options}
												allFilteredObjects={allFilteredObjects}
												setAllFilteredObjects={setAllFilteredObjects}
												isFilterLabelsOpen={isFilterLabelsOpen}
												setFilterLabelsOpen={setFilterLabelsOpen}
												updatedFiltersData={updatedFiltersData}
												setUpdatedFiltersData={setUpdatedFiltersData}
												setFilterApplied={setFilterApplied}
												setPageFilterApplied={setPageFilterApplied}
											/>
										</div>
									);
								})
							) : null}
							{isFilterApplied ? (
								<FilterActionButton
									title={APP_DATA.filters.buttonClearAllFilters[langApp]}
									onClick={clearAllFilters}
									clearMod
								/>
							) : null}
						</div>
					)}
				</div>
				{isFilterLabelsOpen ? (
					<FilterLabelList
						labelList={updatedFiltersData}
						isFilterLabelsOpen={isFilterLabelsOpen}
						allObjects={allObjects}
						allFilteredObjects={allFilteredObjects}
						setAllFilteredObjects={setAllFilteredObjects}
						setFilterLabelsOpen={setFilterLabelsOpen}
						setUpdatedFiltersData={setUpdatedFiltersData}
						setFilterApplied={setFilterApplied}
						setPageFilterApplied={setPageFilterApplied}
					/>
				) : null}
			</div>
		</>
	);
};

export default Filters;
