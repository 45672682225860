import React from 'react';
import EssayMenuButton from 'components/Essay/EssayMenuButton';

const ThemePageNav = ({
	themes,
	currentThemeSlug,
	buttonLabels,
}) => {
	const themeSlugs = themes.map(theme => theme.slug);
	const currentThemeIndex = themeSlugs.indexOf(currentThemeSlug);

	return (
		<ul className="essay_menu">
			{currentThemeIndex > 0 && (
				<li className="essay_menu_item">
					<EssayMenuButton
						title={buttonLabels.prev}
						url={`/themes/${themeSlugs[currentThemeIndex - 1]}`}
					/>
				</li>
			)}
			{currentThemeIndex < (themeSlugs.length - 1) && (
				<li className="essay_menu_item">
					<EssayMenuButton
						title={buttonLabels.next}
						url={`/themes/${themeSlugs[currentThemeIndex + 1]}`}
					/>
				</li>
			)}
		</ul>
	);
};

export default ThemePageNav;
