/* eslint-disable */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { JsonDataContext } from 'context/jsonData';

import { getType } from 'utils';

import { ReactComponent as IconNavigation } from 'i/icons/navigation.svg';
import { ReactComponent as IconClock } from 'i/icons/clock.svg';
import { ReactComponent as IconDocument } from 'i/icons/document_2.svg';
import { ReactComponent as IconArrowRight } from 'i/icons/arrow_right.svg';

const MopedInfoItem = ({
	type,
	value,
	linkMod,
	essaysLink,
	objectId,
}) => {
	const { langApp } = useContext(JsonDataContext);

	const checkTypeValue = itemValue => {
		if (getType(itemValue) === 'Array') {
			if (getType(itemValue[0]) === 'Object') {
				return itemValue.map(v => v[langApp].replace(/by |von /g,'')).join(', ');
			}
			return itemValue.join(', ');
		} else if (getType(itemValue) === 'Object') {
			return itemValue[langApp];
		} else {
			return itemValue;
		}
	};

	const preparedType = type && type.toLowerCase();

	const typeText = {
		origin: {
			en: 'Origin',
			de: 'Ursprung',
		},
		year: {
			en: 'Year',
			de: 'Jahr',
		},
		essays: {
			en: 'Essays',
			de: 'Essays',
		},
	};

	let url;

	if (preparedType === 'origin') {
		url = '/map';
	} else if (preparedType === 'year') {
		url = '/timeline';
	} else if (preparedType === 'essays') {
		url = essaysLink;
	}
	
	if (type === 'essays' && !value.length) {
		return null;
	}
	
	return (
		<>
			{type && (
				<div className="moped_info_item">
					<div className="moped_info_icon theme-page">
						{preparedType === 'origin' && <IconNavigation className="icon icon-navigation size_mod" />}
						{preparedType === 'year' && <IconClock className="icon icon-clock size_mod" />}
						{preparedType === 'essays' && <IconDocument className="icon icon-document_2 size_mod" />}
					</div>
					{preparedType === 'origin' && <span className="mopen_info_type theme-page">{typeText.origin[langApp]}</span>}
					{preparedType === 'year' && <span className="mopen_info_type theme-page">{typeText.year[langApp]}</span>}
					{preparedType === 'essays' && <span className="mopen_info_type theme-page">{typeText.essays[langApp]}</span>}
					{
						preparedType === 'origin' && (
							<>
								<Link target="_blank" className="moped_info_value theme-page" to={`/map/${objectId}`}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point theme-page" to={`/map/${objectId}`}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}
					{
						preparedType === 'year' && (
							<>
								<Link target="_blank" className="moped_info_value theme-page" to={`/timeline/${objectId}`}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point theme-page" to={`/timeline/${objectId}`}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}
					{
						preparedType === 'essays' && (
							<>
								<Link target="_blank" className="moped_info_value theme-page" to={essaysLink}>{checkTypeValue(value)}</Link>
								<Link target="_blank" className="moped_info_icon_point theme-page" to={essaysLink}>
									<IconArrowRight className="icon icon-arrow_right size_mod" />
								</Link>
							</>
						)
					}
				</div>
			)}
		</>
	);
};

export default MopedInfoItem;
/* eslint-disable */
