import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getMainMenuItems } from 'api/data';
import { AnimationContext } from 'context/animation';
import { JsonDataContext } from '../../context/jsonData';

const HeaderMenuList = () => {
	const { windowWidth } = useContext(AnimationContext);
	const { langApp } = useContext(JsonDataContext);
	const [menuItems, setMenuItems] = useState(null);

	const fetchAllMenuItems = async () => {
		try {
			const res = await getMainMenuItems();
			setMenuItems(res.data);
		} catch (err) {
			console.error(err);
		}
	};

	const slideDown = (elem) => {
		/* eslint no-param-reassign: "off" */
		/* eslint eqeqeq: "off" */
		if (elem.style.height == '0px') {
			elem.style.height = `${elem.scrollHeight}px`;
		} else {
			elem.style.height = '0px';
		}
	};

	const toggleDropDown = (e) => {
		e.preventDefault();
		e.target.parentElement.classList.toggle('mobile-dropdown-active');

		slideDown(e.target.nextSibling);
	};

	useEffect(() => {
		fetchAllMenuItems();
	}, []);

	const menuItemsSorted = [];

	if (menuItems) {
		Object.keys(menuItems).map(item => {
			const subMenuItemsSorted = [];

			if (menuItems[item].sub_menu) {
				Object.keys(menuItems[item].sub_menu).map(subItem => {
					subMenuItemsSorted.push(menuItems[item].sub_menu[subItem]);
					return subItem;
				});
			}

			subMenuItemsSorted.sort((a, b) => {
				return a.menu_order > b.menu_order ? 1 : -1;
			});

			menuItems[item].sub_menu = subMenuItemsSorted;

			menuItemsSorted.push(menuItems[item]);
			return item;
		});
	}

	menuItemsSorted.sort((a, b) => {
		return a.menu_order > b.menu_order ? 1 : -1;
	});

	return (
		menuItems ? (
			<ul className="header_nav_list">
				{menuItemsSorted.map(item => {
					const {
						ID,
						item_title: title,
						item_url: url,
					} = item;
					return (
						<li className="header_nav_item reveal-dropdown" key={ID}>
							<Link className="header_nav_link" to={url}>
								<span className="link_decor" />
								<span className="header_nav_link_title">{title[langApp]}</span>
							</Link>
							{item.sub_menu.length ? (
								<>
									{(windowWidth < 1024) && (
										<button type="button" className="dropdown-mobile-icon" onClick={toggleDropDown}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.011 512.011" style={{ enableBackground: 'new 0 0 512.011 512.011' }} xmlSpace="preserve">
												<path d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0 s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667 C514.096,145.416,514.096,131.933,505.755,123.592z" />
											</svg>
										</button>
									)}

									<div className="dropdown-content" style={{ height: windowWidth < 1024 && '0px' }}>
										{item.sub_menu.map(subItem => {
											const {
												ID: subId,
												item_title: subTitle,
												item_url: subUrl,
											} = subItem;
											return (
												<Link className="header_nav_link" to={subUrl} key={subId}>
													<span className="header_nav_link_title">{subTitle[langApp]}</span>
												</Link>
											);
										})}
									</div>
								</>
							) : null}
						</li>
					);
				})}
			</ul>
		) : null
	);
};

export default HeaderMenuList;
